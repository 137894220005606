import React from 'react';

const Svg = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.7143 0C0.767151 0 0 0.767151 0 1.7143V13.7144C0 14.6599 0.768866 15.4287 1.7143 15.4287H11.2903L9.576 13.7144H1.7143V3.42861H13.7144L13.7128 9.576L15.4287 11.2903V1.7143C15.4287 0.767151 14.6616 0 13.7144 0H1.7143ZM3.42861 6.00007V7.71437H5.14291V6.00007H3.42861ZM6.85722 6.00007V7.71437H12.0001V6.00007H6.85722ZM3.42861 9.42868V11.143H5.14291V9.42868H3.42861ZM6.85722 9.42868V11.143H8.57152V9.42868H6.85722ZM10.2858 10.2858V12.0001L14.6972 16.4115L16.4115 14.6972L12.0001 10.2858H10.2858ZM17.0175 15.3032L15.3032 17.0175L16.1603 17.8746C16.3275 18.0418 16.5992 18.0418 16.7664 17.8746L17.8746 16.7664C18.0418 16.5984 18.0418 16.3275 17.8746 16.1603L17.0175 15.3032Z"
      fill="#E0E0E0"
    />
  </svg>
);

export default Svg;
