import React from 'react';

export const RequestSuccess = ({ close }) => (
  <div className="popup__inner popup__inner--40">
    <form className="popup__form text-center">
      <h3 className="h3 form-h3">Заявка отправлена!</h3>
      <p className="popup__text">
        Статус заявки вы можете отслеживать в личном кабинете. Для отмены заявки необходимо
        связаться <br />с территориальным менеджером.
      </p>
      <div className="form-line-bottom form-line-bottom--center">
        <button
          type="button"
          onClick={close}
          className="i-button i-button--200 i-button--white"
        >
          закрыть
        </button>
      </div>
    </form>
  </div>
);
