import { FormFields } from '../constants';

function isPhoneValid(phone) {
  const phoneRegExp = /^\+7 \(9\d{2}\) \d{3}-\d{2}-\d{2}$/;

  return phoneRegExp.test(phone);
}

function isEmailValid(email) {
  const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return email.search(emailRegExp) !== -1;
}

function isAgreementValid(agreement) {
  return agreement;
}

function isValueValid(value) {
  return value.length > 2;
}

export function isFieldValid(field, value) {
  switch (field) {
    case FormFields.Email:
      return isEmailValid(value);
    case FormFields.Phone:
      return isPhoneValid(value);
    case FormFields.Agreement:
      return isAgreementValid(value);
    case FormFields.Region:
      return value !== undefined;
    case FormFields.LegalForm:
      return value !== undefined && value !== '';
    default:
      return isValueValid(value);
  }
}

export function getInvalidFormFields(state) {
  return Object.entries(state)
    .filter(([field, value]) => !isFieldValid(field, value))
    .map(([field]) => field);
}
