import React from 'react';

const Filter = () => (
  <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 3.58612C13.1046 3.58612 14 2.92227 14 2.10336C14 1.28446 13.1046 0.620605 12 0.620605C11.2597 0.620605 10.6134 0.918779 10.2676 1.36197H0.741379C0.331926 1.36197 0 1.6939 0 2.10335C0 2.5128 0.331926 2.84473 0.741379 2.84473H10.2676C10.6134 3.28793 11.2597 3.58612 12 3.58612Z"
      fill="white"
    />
    <path
      d="M13.2586 6.55176C13.6681 6.55176 14 6.21983 14 5.81038C14 5.40093 13.6681 5.069 13.2586 5.069H5.73242C5.38661 4.62581 4.74027 4.32764 4 4.32764C3.25973 4.32764 2.61339 4.62581 2.26758 5.069H0.741379C0.331926 5.069 0 5.40093 0 5.81038C0 6.21983 0.331926 6.55176 0.741379 6.55176H2.26755C2.61336 6.99496 3.25971 7.29315 4 7.29315C4.74029 7.29315 5.38664 6.99496 5.73245 6.55176H13.2586Z"
      fill="white"
    />
    <path
      d="M13.2586 10.2588C13.6681 10.2588 14 9.92686 14 9.51741C14 9.10796 13.6681 8.77603 13.2586 8.77603H10.7324C10.3866 8.33284 9.74027 8.03467 9 8.03467C8.25973 8.03467 7.61339 8.33284 7.26758 8.77603L0.741379 8.77603C0.331926 8.77603 0 9.10796 0 9.51741C0 9.92686 0.331926 10.2588 0.741379 10.2588H7.26755C7.61336 10.702 8.25971 11.0002 9 11.0002C9.74029 11.0002 10.3866 10.702 10.7324 10.2588H13.2586Z"
      fill="white"
    />
  </svg>
);

export default Filter;
