import React, { useCallback } from 'react';
import NumberInput from '../NumberInput';

export default function FiltersRange({
  value,
  bounds,
  placeholder,
  unit,
  onChange,
}) {
  const constrainValue = useCallback((unconstrainedValue) => (
    unconstrainedValue === null
      ? null
      : Math.max(Math.min(unconstrainedValue, bounds.max), 0)
  ), [bounds]);

  const updateMinValue = useCallback((updatedValue) => {
    onChange({
      min: constrainValue(updatedValue),
      max: value.max,
    });
  }, [onChange, value, constrainValue]);

  const updateMaxValue = useCallback((updatedValue) => {
    onChange({
      min: value.min,
      max: constrainValue(updatedValue),
    });
  }, [onChange, value, constrainValue]);

  return (
    <div className="filter__range">
      <div className="filter__range-row">
        <div className="filter__range-input">
          <NumberInput
            className="i-input"
            type="text"
            placeholder={placeholder.min}
            value={value.min}
            onChange={updateMinValue}
          />
        </div>
        <div className="filter__range-between" />
        <div className="filter__range-input">
          <NumberInput
            className="i-input"
            type="text"
            placeholder={placeholder.max}
            value={value.max}
            onChange={updateMaxValue}
          />
        </div>
      </div>
      {unit && <div className="filter__range-unit">{unit}</div>}
    </div>
  );
}
