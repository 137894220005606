import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Tooltip from '../../../../ui/Layout/Tooltip';

import formatCurrency from '../../../../../shared/utils/formatCurrency';

class MainInfo extends Component {
  state = {};

  render() {
    const {
      toggleModal,
      leaseTerm,
      rate,
      askoId,
      description,
      area,
      spaceAmount,
      regions,
      request,
      name,
    } = this.props;
    return (
      <>
        <h1 className="h1 find__h1">{name}</h1>
        <div className="find-item__topnav">
          {regions
            && regions.map(el => (
              <Link style={{ marginRight: 10 }} key={el.id} to={`/find/group/?location=${el.id}`}>
                {el.name}
              </Link>
            ))}
          <a href="#map" className="find-item__topnav-onmap">
            Показать на карте
          </a>
        </div>
        <div className="find-group__feature">
          <div className="find-group__feature-left">
            <div className="find-group__info">
              <div className="find-group__info-title">Общая площадь</div>
              <div className="find-group__info-val">
                <div className="txt-val">
                  {area} <small>м²</small>
                </div>
              </div>
              <div className="find-group__info-notice">
                Средний метраж {spaceAmount && area ? (area / spaceAmount).toFixed(2) : ''} м²
              </div>
            </div>
            <div className="find-group__info">
              <div className="find-group__info-title">Количество объектов</div>
              <div className="find-group__info-val">
                <div className="txt-val">{spaceAmount}</div>
              </div>
            </div>
            <div className="find-group__info">
              <div className="find-group__info-title">Цена за все объекты</div>
              <div className="find-group__info-val">
                <div className="txt-val">
                  <small>от </small>
                  {formatCurrency(rate)}
                  <small> ₽/мес </small>{' '}
                  <Tooltip>
                    Цены указаны с НДС, являются предварительными и могут быть изменены
                  </Tooltip>
                </div>
              </div>
              <div className="find-group__info-notice">
                Средняя цена {formatCurrency(rate / spaceAmount)} ₽/м²
              </div>
            </div>
            <div className="find-group__info">
              <div className="find-group__info-title">Срок аренды</div>
              <div className="find-group__info-val">
                <div className="txt-val">{leaseTerm} мес</div>
              </div>
              <div className="find-group__info-notice">С возможной пролонгацией</div>
            </div>
          </div>
          <div className="find-group__feature-right">
            <div className="find-group__description">{description}</div>
            <div className="find-group__number">
              Номер группы <span>{askoId}</span>
            </div>
            {request ? (
              <span className="i-button i-button--green i-button--270 card__submit-sent">
                Заявка отправлена
              </span>
            ) : (
              <div
                onKeyPress={e => (e.keyCode === 13 ? this.toggleModal(e) : null)}
                role="tab"
                tabIndex="0"
                onClick={toggleModal}
                className="find-group__submit"
              >
                <span className="i-button i-button--270">Подать заявку</span>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default MainInfo;
