import React, { useEffect, useState } from 'react';
import StickyBox from 'react-sticky-box';
import Helmet from 'react-helmet';

import { connect } from 'react-redux';
import OnMap from './GroupItemOnMap';
import Similar from '../Item/Similar';
import MainInfo from './GroupItemMainInfo';
import Portal from '../../../../shared/utils/Portal';

import Dropdown from '../../../ui/Layout/Dropdown';
import ItemsList from './GroupItemsList';
import Sidebar from './GroupItemSidebar';
import GroupRequest from '../../../modals/Request/Request';
import Login from '../../../modals/Login/Login';

import GroupsAPI from '../../../../services/groupsAPI';

const groupsAPI = new GroupsAPI();
const GroupItem = ({
  match: {
    params: { id },
  },
  loginData,
}) => {
  const [modalOpened, setModalOpened] = useState(false);
  const [currentSpace, setCurrentSpace] = useState(undefined);
  const [ordering, setOrdering] = useState('-base_rate');
  const [suggestionsLoading, setSuggestionsLoading] = useState(true);
  const [mapDataLoading, setMapDataLoading] = useState(true);
  const [data, setData] = useState();
  const [mapData, setMapData] = useState();
  const [suggestions, setSuggestions] = useState();

  useEffect(() => {
    groupsAPI
      .getGroup(id)
      .then(res => {
        if (!res.data.spaces.length) {
          setData(res.data);
        } else {
          setData(res.data);
          setCurrentSpace(res.data.spaces[0].id);
        }
      });

    groupsAPI
      .getGroupsMap(id)
      .then(res => {
        setMapDataLoading(false);
        setMapData(res.data.features);
      });

    groupsAPI
      .getSuggestions(id)
      .then(res => {
        setSuggestionsLoading(false);
        setSuggestions(res.data.results);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSpace = spaceId => setCurrentSpace(spaceId);


  const toggleModal = () => setModalOpened(!modalOpened);

  const onChangeOrdering = value => {
    setOrdering(orderingCode(value));
    setData({ ...data, spaces: data.spaces.reverse() });
  };

  const parseOrdering = order => {
    if (order === 'base_rate') return 'По цене (сначала меньше)';
    if (order === '-base_rate') return 'По цене (сначала больше)';
    return order;
  };

  const orderingCode = order => {
    if (order === 'По цене (сначала меньше)') return 'base_rate';
    if (order === 'По цене (сначала больше)') return '-base_rate';
    return order;
  };

  const getSpace = () => {
    const { spaces } = data;
    if (!spaces) return '';
    return spaces.filter(el => +el.id === +currentSpace)[0];
  };

  const afterRequestHook = () => {
    setData({ ...data, request: true });
  };

  if (!data) {
    return (
      <div className="container">
        <h2 className="h2">Загружаю...</h2>
      </div>
    );
  }

  return (
    <>
      <Helmet title="Группа объектов" />
      <div className="container">
        <MainInfo
          toggleModal={toggleModal}
          leaseTerm={data.lease_term}
          rate={data.base_rate}
          askoId={data.asko_id}
          description={data.desc}
          area={data.total_area}
          spaceAmount={data.space_amount}
          regions={data.regions}
          request={data.request}
          name={data.name}
        />
        {currentSpace && (
          <>
            <hr className="hr hr--light mt-0" />
            <h2 className="h2 mb-30">Перечень адресов, входящих в эту группу</h2>
            <div className="sort">
              <div className="sort__group">
                <div className="sort__title">Сортировка</div>
                {'  '}
                <div className="sort__select sort__select--s1">
                  <Dropdown
                    value={parseOrdering(ordering)}
                    onChange={onChangeOrdering}
                    options={['По цене (сначала меньше)', 'По цене (сначала больше)']}
                  />
                </div>
              </div>
            </div>
            <div className="find-group__row">
              <ItemsList
                spaces={data.spaces}
                setSpace={setSpace}
                currentSpace={currentSpace}
              />
              <div className="find-group__right">
                <StickyBox>
                  <Sidebar currentSpace={getSpace(currentSpace)} />
                </StickyBox>
              </div>
            </div>
          </>
        )}
        <h2 className="h2 h2--mb30">На карте</h2>
      </div>
      {!mapDataLoading && mapData?.length ? (
        <OnMap mapData={mapData} />
      ) : (
        <div style={{ marginBottom: 100 }} className="container">
          Площади отсутствуют
        </div>
      )}
      {!suggestionsLoading && suggestions?.length ? <Similar suggestions={suggestions} /> : null}
      {modalOpened && (
        <Portal>
          {loginData.loginSuccess ? (
            <GroupRequest
              afterRequestHook={afterRequestHook}
              group
              data={data}
              close={toggleModal}
            />
          ) : (
            <Login noRedirect close={toggleModal} />
          )}
        </Portal>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  loginData: state.login,
});

export default connect(mapStateToProps)(GroupItem);
