import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  YMaps, Map, Clusterer, Placemark, GeolocationControl,
} from 'react-yandex-maps';
import cx from 'classnames';

import OrganizationAPI from '../../../services/organizationAPI';
import SpacesAPI from '../../../services/spacesAPI';
import formatCurrency from '../../../shared/utils/formatCurrency';

import Filter from '../../../components/ui/svg/filter';
import mapGeo from '../../../components/ui/svg/map-geo.svg';
import mapZoomIn from '../../../components/ui/svg/map-zoomin.svg';
import mapZoomOut from '../../../components/ui/svg/map-zoomout.svg';
import mapPin from '../../../components/common/Find/Map/pin-map-sprite.svg';
import clusterMap from '../../../components/common/Find/Map/cluster-map.png';

import Sidebar from '../../../components/common/Find/Results/Sidebar';
import Select from '../../../components/ui/Select';
import { getDefaultSearchFiltersData } from '../../../components/common/Find/Filters/constants';
import { createSearchFiltersFromQueryParams } from '../../../components/common/Find/Filters/utils';

class BigMap extends Component {
  organizationAPI = new OrganizationAPI();

  spacesAPI = new SpacesAPI();

  constructor(props) {
    super(props);
    const { centroid, cityId } = props;
    this.state = {
      mapState: {
        center: centroid || [37.573856, 55.751574],
        zoom: 11,
      },
      filtersOpened: false,
      page: 1,
      shop: '',
      baseRateMin: '',
      baseRateMax: '',
      areaMin: '',
      areaMax: '',
      filtersLoading: true,
      filters: {},
      categoryQuery: [],
      typeQuery: [],
      occupancyStatusQuery: [],
      location: cityId || '',
      pageSize: '15',
      ordering: 'base_rate',
      features: [],
      featuresLoading: true,
      filtersMaxArea: '',
      filtersMinArea: '',
      filtersMaxRate: '',
      filtersMinRate: '',
      inBbox: '',
      regions: [],
      regionsLoading: true,
      searchFiltersData: getDefaultSearchFiltersData(),
      searchFilters: createSearchFiltersFromQueryParams(window.location.search),
    };
  }

  componentDidMount() {
    this.organizationAPI.getRegions().then(res => {
      this.setState({ regions: res.data, regionsLoading: false });
    });
    this.spacesAPI.getFilters().then(res => {
      this.setState(
        prev => ({
          ...prev,
          searchFiltersData: {
            ...prev.searchFiltersData,
            availableCategoriesWithoutGroups: res.data.category_without_groups,
            availableCategoriesWithGroups: res.data.category_groups,
          },
        }),
      );
      this.setState(prev => ({ ...prev, filtersLoading: false }));
    });

    const { currentFilters, isFiltersInitial } = this.props;
    const mountSearch = () => {
      const {
        page,
        shop,
        pageSize,
        baseRateMin,
        baseRateMax,
        areaMin,
        areaMax,
        categoryQuery,
        typeQuery,
        occupancyStatusQuery,
        location,
        ordering,
        inBbox,
      } = this.state;
      const data = {
        page,
        shop,
        pageSize,
        baseRateMin,
        baseRateMax,
        areaMin,
        areaMax,
        category: categoryQuery,
        shopType: typeQuery,
        occupancyStatus: occupancyStatusQuery,
        location,
        ordering,
        inBbox,
      };
      this.spacesAPI.getSpacesMap(data).then(res => {
        this.setState({
          features: res.data.data.features,
          filtersMaxArea: res.data.max_area,
          filtersMinArea: res.data.min_area,
          filtersMaxRate: res.data.max_rate,
          filtersMinRate: res.data.min_rate,
          featuresLoading: false,
        });
      });
    };
    if (!isFiltersInitial) {
      this.setState(
        prev => ({ ...prev, ...currentFilters }),
        () => {
          mountSearch();
        },
      );
    } else {
      mountSearch();
    }
  }

  setLocationFromFilter = () => {
    const { currentRegions, cityId } = this.props;
    if (currentRegions.length && currentRegions[0] !== cityId) {
      this.setState(
        {
          location: currentRegions[0],
        },
        () => {
          this.mapElement.setCenter(
            this.parseRegionLocation(
              this.parseRegion(
                currentRegions[0],
              ),
            ),
          );
        },
      );
    }
  };

  onChangeLocation = value => {
    this.setState(
      {
        location: this.regionCode(value),
      },
      () => this.mapElement.setCenter(this.parseRegionLocation(value)),
    );
  };

  searchOnMove = () => {
    const { mapState } = this.state;
    const { _bounds, _zoom } = this.mapElement;
    this.setState(
      {
        inBbox: _bounds,
        mapState: { ...mapState, zoom: _zoom },
      },
      this.search,
    );
  };

  mapZoomIn = () => this.setState(prevState => ({
    mapState: { ...prevState.mapState, zoom: prevState.mapState.zoom + 1 },
  }))

  mapZoomOut = () => this.setState(prevState => ({
    mapState: { ...prevState.mapState, zoom: prevState.mapState.zoom - 1 },
  }))

  search = () => {
    const { dispatch } = this.props;
    const {
      page,
      shop,
      pageSize,
      baseRateMin,
      baseRateMax,
      areaMin,
      areaMax,
      typeQuery,
      occupancyStatusQuery,
      location,
      ordering,
      inBbox,
      searchFilters,
    } = this.state;

    const filters = searchFilters.categories.join(',');

    const currentFilters = {
      page,
      shop,
      pageSize,
      baseRateMin,
      baseRateMax,
      areaMin,
      areaMax,
      typeQuery,
      occupancyStatusQuery,
      location,
      ordering,
      inBbox,
      searchFilters: filters,
    };
    const data = {
      page,
      shop,
      pageSize,
      baseRateMin,
      baseRateMax,
      areaMin,
      areaMax,
      category: filters,
      shopType: typeQuery,
      occupancyStatus: occupancyStatusQuery,
      location,
      ordering,
      inBbox,
    };
    dispatch({ type: 'SET_FILTERS', payload: currentFilters });
    this.spacesAPI.getSpacesMap(data).then(res => {
      this.setState({ features: res.data.data.features, featuresLoading: false });
    });
  };

  toggleFilters = () => {
    this.setState(prev => ({ filtersOpened: !prev.filtersOpened }));
  };

  setSelectedCategoryIds = categoryIds => {
    this.setState(prev => ({
      ...prev,
      searchFilters: {
        ...prev.searchFilters,
        categories: categoryIds,
      },
    }));
  };

  setData = ({ target }) => {
    this.setState(prev => ({ [target.name]: target.value }));
  };

  addType = (id, value) => {
    const { typeQuery } = this.state;
    let newQuery = typeQuery;
    if (value) {
      newQuery.push(id);
    }
    if (!value) {
      newQuery = newQuery.filter(el => +el !== +id);
    }
    this.setState({ typeQuery: newQuery });
  };

  addOccupancyStatus = (id, value) => {
    const { occupancyStatusQuery } = this.state;
    let newQuery = occupancyStatusQuery;
    if (value) {
      newQuery.push(id);
    }
    if (!value) {
      newQuery = newQuery.filter(el => +el !== +id);
    }
    this.setState({ occupancyStatusQuery: newQuery });
  };

  reset = () => this.setState(
    {
      baseRateMin: '',
      baseRateMax: '',
      areaMin: '',
      areaMax: '',
      searchFilters: createSearchFiltersFromQueryParams(window.location.search),
      typeQuery: [],
      occupancyStatusQuery: [],
      location: '',
    },
    this.search,
  );

  parseRegionLocation = region => {
    const { regions } = this.state;
    const filteredArray = regions.filter(el => el.name === region);
    if (filteredArray.length < 1) return [57, 65];
    return filteredArray[0].centroid;
  };

  parseRegion = id => {
    const { regions } = this.state;
    const filteredArray = regions.filter(el => +el.id === +id);
    if (filteredArray.length < 1) return id;
    return filteredArray[0].name;
  };

  regionCode = region => {
    const { regions } = this.state;
    return regions.find(el => el.alias === region).id;
  };

  render() {
    const {
      mapState,
      filtersOpened,
      page,
      baseRateMin,
      baseRateMax,
      areaMin,
      areaMax,
      filtersLoading,
      categoryQuery,
      typeQuery,
      occupancyStatusQuery,
      features,
      featuresLoading,
      filtersMaxArea,
      filtersMinArea,
      filtersMaxRate,
      filtersMinRate,
      regions,
      regionsLoading,
      searchFiltersData,
      searchFilters,
    } = this.state;
    const { currentRegions } = this.props;
    const {
      availableCategoriesWithoutGroups,
      availableCategoriesWithGroups,
    } = searchFiltersData;

    return (
      <>
        {' '}
        <div className={cx('find__map js-mapBox', { 'is-open-filters': filtersOpened })}>
          <div className="find__map-left">
            <div className="find__map-scroll">
              <div className="find__map-sidebar">
                {!filtersLoading && (
                <Sidebar
                  page={page}
                  baseRateMin={baseRateMin}
                  baseRateMax={baseRateMax}
                  areaMin={areaMin}
                  areaMax={areaMax}
                  filtersLoading={filtersLoading}
                  categoryQuery={categoryQuery}
                  typeQuery={typeQuery}
                  occupancyStatusQuery={occupancyStatusQuery}
                  setData={this.setData}
                  setSelectedCategoryIds={this.setSelectedCategoryIds}
                  searchFilters={searchFilters}
                  addType={this.addType}
                  addOccupancyStatus={this.addOccupancyStatus}
                  reset={this.reset}
                  search={this.search}
                  filtersMaxArea={filtersMaxArea}
                  filtersMinArea={filtersMinArea}
                  filtersMaxRate={filtersMaxRate}
                  filtersMinRate={filtersMinRate}
                  availableCategoriesWithGroups={availableCategoriesWithGroups}
                  availableCategoriesWithoutGroups={availableCategoriesWithoutGroups}
                />
                )}
              </div>
            </div>
          </div>

          <div className="map-wrap">
            <div className="map map--big" id="map">
              <YMaps query={{ coordorder: 'longlat' }}>
                <Map
                  instanceRef={ref => ref && (this.mapElement = ref) && ref.behaviors.disable('scrollZoom')
                  }
                  onClick={this.handleClick}
                  width="100%"
                  height="100%"
                  state={mapState}
                  options={{
                    maxZoom: 18,
                    minZoom: 9,
                    suppressMapOpenBlock: true,
                  }}
                  onLoad={this.setLocationFromFilter}
                  onBoundsChange={this.searchOnMove}
                >
                  <GeolocationControl
                    onClick={this.handleClick}
                    options={{ position: { top: '24px', right: '24px' }, maxWidth: '36' }}
                    data={{ image: mapGeo }}
                  />
                  <Clusterer
                    options={{
                      clusterIcons: [
                        {
                          href: clusterMap,
                          size: [58, 58],
                          // Отступ, чтобы центр картинки совпадал с центром кластера.
                          offset: [-29, -29],
                          shape: {
                            type: 'Circle',
                            coordinates: [0, 0],
                            radius: 29,
                          },
                        },
                      ],
                      maxZoom: 16,
                      minClusterSize: 2,
                      gridSize: 32,
                    }}
                  >
                    {!featuresLoading
                      && features.map(feat => {
                        const { geometry, properties } = feat;
                        return (
                          <Placemark
                            key={properties.shop_id}
                            geometry={geometry.coordinates}
                            properties={{
                              balloonContentBody:
                                "<div class='map-balloon'>"
                                + "<div class='map-balloon__title'>"
                                + `<a href='../find?page=1&shop=${
                                  properties.shop_id
                                }' target='_blank' class='i-link-black'>${properties.address}</a>`
                                + '</div>'
                                + "<div class='map-balloon__free'>"
                                + `Свободных площадей: <b>${properties.space_count}</b>`
                                + '</div>'
                                + "<div class='map-balloon__footer'>"
                                + "<div class='map-balloon__footer-item'>"
                                + `<div class='txt-val'><small>от</small> ${
                                  properties.area_from
                                } <small>м²</small> <span class='fromto'>—</span> <small>до</small> ${
                                  properties.area_to
                                } <small>м²</small></div>`
                                + '</div>'
                                + "<div class='map-balloon__footer-item'>"
                                + `<div class='txt-val'><small>от</small> ${formatCurrency(
                                  properties.base_rate_from,
                                )} <small>₽/мес</small></div>`
                                + '</div>'
                                + '</div>'
                                + '</div>',
                            }}
                            options={{
                              iconLayout: 'default#image',
                              iconImageHref: mapPin,
                              iconImageSize: [26, 35],
                              iconImageOffset: [-13, -35],
                              iconImageClipRect: [[0, 15], [26, 50]],
                              hideIconOnBalloonOpen: false,
                              balloonOffset: [0, -5],
                              balloonMaxWidth: 500,
                              balloonMinWidth: 480,
                            }}
                            modules={['geoObject.addon.balloon']}
                          />
                        );
                      })}
                  </Clusterer>
                </Map>
              </YMaps>
            </div>

            <div className="map-find">
              {!regionsLoading && (
                <Select
                  items={regions}
                  onChange={this.onChangeLocation}
                  placeholder={currentRegions.length ? this.parseRegion(currentRegions[0]) : 'Выберите регион или город'}
                />
              )}
            </div>
            <div
              onClick={this.toggleFilters}
              role="button"
              onKeyDown={this.toggleFilters}
              tabIndex="0"
              className="map-filter js-mapFilterBtn"
            >
              <Filter />
            </div>
            <div className="map-controls">
              <div className="map-zoom">
                <div
                  tabIndex="0"
                  role="button"
                  onKeyDown={this.mapZoomIn}
                  onClick={this.mapZoomIn}
                  className="map-zoom__in"
                  id="mapZoomIn"
                >
                  <img src={mapZoomIn} alt="" />
                </div>
                <div
                  tabIndex="0"
                  role="button"
                  onKeyDown={this.mapZoomOut}
                  onClick={this.mapZoomOut}
                  className="map-zoom__out"
                  id="mapZoomOut"
                >
                  <img src={mapZoomOut} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  centroid: state.login.geolocationCurrent.centroid,
  cityId: state.login.geolocationCurrent.id,
  currentRegions: state.find.currentRegions,
  currentFilters: state.find.currentFilters,
  isFiltersInitial: state.find.isFiltersInitial,
});

export default connect(mapStateToProps)(BigMap);
