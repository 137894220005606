import React from 'react';

const Svg = () => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 0C0.895 0 0 0.895 0 2V16C0 17.105 0.895 18 2 18H11C12.105 18 13 17.105 13 16V12H11V16H2V2H11V6H13V2C13 0.895 12.105 0 11 0H2ZM15 5V8H6V10H15V13L19 9L15 5Z"
      fill="#E0E0E0"
    />
  </svg>
);

export default Svg;
