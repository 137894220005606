import { useMemo } from 'react';
import queryString from 'query-string';
import {
  mapFiltersTransferObjectToSearchFilters,
  mapGroupFiltersTransferObjectToSearchFilters, mapGroupSearchFiltersToFiltersTransferObject,
  mapSearchFiltersToFiltersTransferObject,
} from './mappers';

export function createSearchFiltersQueryParams(searchFilters) {
  return queryString.stringify(mapSearchFiltersToFiltersTransferObject(searchFilters), {
    arrayFormat: 'comma',
  });
}

export function createGroupSearchFiltersQueryParams(searchFilters) {
  return queryString.stringify(mapGroupSearchFiltersToFiltersTransferObject(searchFilters), {
    arrayFormat: 'comma',
  });
}

export function createSearchFiltersFromQueryParams(queryParams) {
  return mapFiltersTransferObjectToSearchFilters(queryString.parse(queryParams, {
    arrayFormat: 'comma',
  }));
}

export function createGroupSearchFiltersFromQueryParams(queryParams) {
  return mapGroupFiltersTransferObjectToSearchFilters(queryString.parse(queryParams, {
    arrayFormat: 'comma',
  }));
}

export function useShouldApplyCurrentLocation(queryParams) {
  return useMemo(() => {
    const stringValue = queryString.parse(queryParams).applyCurrentLocation;

    return Boolean(parseInt(`${stringValue}`, 10));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export const RegionType = {
  Shop: 'shop',
  Geolocation: 'geolocation',
};

export class Region {
  id = null;

  type = null;

  name = '';

  constructor(region) {
    if (region.geo_id !== null) {
      this.id = region.geo_id;
      this.type = RegionType.Geolocation;
    } else if (region.shop_id !== null) {
      this.id = region.shop_id;
      this.type = RegionType.Shop;
    } else {
      throw new Error('Некорректный формат региона');
    }

    this.name = region.geo_name;
  }
}
