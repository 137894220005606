import React from 'react';
import { privacyPolicyLink } from '../../../shared/constants';

export function PolicyLink({ children }) {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={privacyPolicyLink}
    >
      {children}
    </a>
  );
}
