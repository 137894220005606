import { useState, useCallback, useEffect } from 'react';
import CaptchaAPI from '../../../services/captchaAPI';

export default function useCustomCaptcha() {
  const [imageURL, setImageURL] = useState(null);
  const [UUID, setUUID] = useState(null);

  const refreshCaptcha = useCallback(async () => {
    const { uuid, imageURL: URL } = await CaptchaAPI.getCaptcha();

    setImageURL(URL);
    setUUID(uuid);
  }, []);

  const verifyCaptcha = useCallback(async (answer) => {
    if (!answer.length) throw new Error('Поле не может быть пустым');

    try {
      return await CaptchaAPI.verifyCaptcha(UUID, answer);
    } catch (error) {
      refreshCaptcha();

      throw error;
    }
  }, [UUID, refreshCaptcha]);

  useEffect(() => {
    refreshCaptcha();
  }, [refreshCaptcha]);

  return [imageURL, verifyCaptcha];
}
