import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import formatCurrency from '../../../../../shared/utils/formatCurrency';

class Sidebar extends Component {
  state = { currentSlide: 1, slidesCount: undefined, isSliding: false };

  componentDidMount() {
    this.setState({ slidesCount: this.props.currentSpace.photos.length });
  }

  nextSlide = () => {
    const { isSliding } = this.state;
    this.setState({ isSliding: true }, () => {
      if (!isSliding) {
        const { currentSlide, slidesCount } = this.state;
        setTimeout(() => {
          this.setState(prev => {
            if (currentSlide === slidesCount) return { currentSlide: 1 };
            return { currentSlide: prev.currentSlide + 1 };
          }, this.setState({ isSliding: false }));
        }, 500);
      }
    });
  };

  prevSlide = () => {
    const { isSliding } = this.state;
    this.setState({ isSliding: true }, () => {
      if (!isSliding) {
        const { currentSlide, slidesCount } = this.state;
        setTimeout(() => {
          this.setState(prev => {
            if (currentSlide === 1) return { currentSlide: slidesCount };
            return { currentSlide: prev.currentSlide - 1 };
          }, this.setState({ isSliding: false }));
        }, 500);
      }
    });
  };

  render() {
    const { currentSlide, slidesCount } = this.state;
    const { currentSpace } = this.props;
    if (!currentSpace) return 'Выберите площадь';
    return (
      <div className="find-group__card" id="sidebar">
        <div className="find-group__card-inner">
          <div className="find-item__body find-item__body--group">
            <div className="find-item__top">
              {!currentSpace.photos.length && (
                <div className="m-slider m-slider--item">
                  <div className="m-slider__main m-slider__main--380">
                    <div className="m-slider__wrap js-mSlider">
                      <div
                        className="m-slider__slide-img"
                        style={{ backgroundImage: 'url(/img/media/no_img.png)' }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {!!currentSpace.photos.length && (
                <>
                  <div className="m-slider m-slider--group">
                    <div className="m-slider__main m-slider__main--368">
                      <div
                        className="js-mNextSlide m-slider__next"
                        onClick={() => {
                          this.slider.slickNext();
                          this.nextSlide();
                        }}
                        tabIndex="0"
                        role="button"
                        onKeyPress={e => {
                          if (e.keyCode === 13) {
                            this.slider.slickNext();
                            this.nextSlide();
                          }
                        }}
                      />
                      <div
                        className="js-mPrevSlide m-slider__prev"
                        onClick={() => {
                          this.slider.slickPrev();
                          this.prevSlide();
                        }}
                        tabIndex="0"
                        role="button"
                        onKeyPress={e => {
                          if (e.keyCode === 13) {
                            this.slider.slickPrev();
                            this.prevSlide();
                          }
                        }}
                      />
                      <div className="m-slider__index">
                        <span className="js-mCurrent">{currentSlide}</span>
                        <span className="m-slider__in">из</span>
                        <span className="js-mSize">{slidesCount}</span>
                      </div>
                      <div className="m-slider__wrap js-mSlider">
                        <Slider
                          draggable={false}
                          ref={c => (this.slider = c)}
                          arrows={false}
                          infinite
                          speed={500}
                        >
                          {currentSpace.photos.map((el, idx) => (
                            <div key={idx} className="m-slider__slide">
                              <div
                                className="m-slider__slide-img"
                                style={{ backgroundImage: `url(${el.image})` }}
                              />
                            </div>
                          ))}
                        </Slider>
                      </div>
                    </div>
                  </div>

                  <div className="m-slider__thumbs">
                    {currentSpace.photos.map((el, idx) => (
                      <div
                        key={idx}
                        onClick={() => {
                          this.slider.slickGoTo(idx);
                          this.setState({ currentSlide: idx + 1 });
                        }}
                        className="m-slider__thumb js-thumbSlider"
                        style={{ backgroundImage: `url(${el.image})` }}
                        tabIndex="0"
                        role="button"
                        onKeyPress={e => {
                          if (e.keyCode === 13) {
                            this.slider.slickGoTo(idx);
                            this.setState({ currentSlide: idx + 1 });
                          }
                        }}
                      />
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="find-item__bottom">
            <div className="card card--group">
              <div className="card__inner">
                <div className="card__features">
                  <div className="card__features-item">
                    <div className="card__features-caption">Площадь</div>
                    <div className="card__features-lines">
                      <div className="card__features-val">
                        {currentSpace.area} <small>м²</small>
                      </div>
                    </div>
                  </div>
                  <div className="card__features-item">
                    <div className="card__features-caption">Аренда в месяц</div>
                    <div className="card__features-lines">
                      <div className="card__features-val">
                        <small>от </small>
                        {formatCurrency(currentSpace.base_rate)}
                        <small> ₽/мес</small>
                      </div>
                      <div className="card__features-notice">
                        {formatCurrency(currentSpace.base_rate / currentSpace.area)} ₽/м²
                      </div>
                    </div>
                  </div>
                  <div className="card__features-item">
                    <div className="card__features-caption">Срок аренды</div>
                    <div className="card__features-lines">
                      <div className="card__features-val">
                        {currentSpace.lease_term} <small>мес</small>
                      </div>
                      <div className="card__features-notice">с пролонгацией</div>
                    </div>
                  </div>
                </div>
                <div className="card__description">{currentSpace.desc}</div>
                <div className="card__more">
                  <div className="card__more-item">
                    Номер группы <span>{currentSpace.asko_id}</span>
                  </div>
                  <div className="card__more-item">
                    Категория площади <span>{currentSpace.category}</span>
                  </div>
                </div>
                <div className="card__submit">
                  <Link to={`/find/${currentSpace.id}`} className="i-link-red">
                    Подробнее
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
