import React from 'react';

const Svg = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.54545 0C0.691591 0 0 0.691591 0 1.54545V15.4545C0 16.3084 0.691591 17 1.54545 17H2.74831C2.48249 16.5433 2.31818 16.0202 2.31818 15.4545V1.54545C2.31818 0.979818 2.48249 0.456682 2.74831 0H1.54545ZM5.40909 0C4.55523 0 3.86364 0.691591 3.86364 1.54545V15.4545C3.86364 16.3084 4.55523 17 5.40909 17H15.4545C16.3084 17 17 16.3084 17 15.4545V5.02273C17 4.81795 16.9189 4.62166 16.7736 4.47638L12.5236 0.226385C12.3783 0.0811122 12.182 0 11.9773 0H5.40909ZM11.5909 1.4715L15.5285 5.40909H12.3636C11.9371 5.40909 11.5909 5.06291 11.5909 4.63636V1.4715ZM7.72727 8.5H13.1364C13.5629 8.5 13.9091 8.84618 13.9091 9.27273C13.9091 9.69927 13.5629 10.0455 13.1364 10.0455H7.72727C7.30073 10.0455 6.95455 9.69927 6.95455 9.27273C6.95455 8.84618 7.30073 8.5 7.72727 8.5ZM7.72727 11.5909H11.5909C12.0175 11.5909 12.3636 11.9371 12.3636 12.3636C12.3636 12.7902 12.0175 13.1364 11.5909 13.1364H7.72727C7.30073 13.1364 6.95455 12.7902 6.95455 12.3636C6.95455 11.9371 7.30073 11.5909 7.72727 11.5909Z"
      fill="#E0E0E0"
    />
  </svg>
);

export default Svg;
