import React from 'react';
import cx from 'classnames';

import formatCurrency from '../../../../../shared/utils/formatCurrency';

const ListItem = ({
  address,
  area,
  baseRate,
  setSpace,
  id,
  isCurrent,
  style,
}) => (
  <span
    tabIndex="0"
    role="button"
    onKeyDown={() => setSpace(id)}
    onClick={() => setSpace(id)}
    className={cx(
      'table__row table__row--body find-group__table-link js-tableLink',
      {
        'is-current': isCurrent,
      },
    )}
    style={style}
  >
    <div className="table__td find-group__address">{address}</div>
    <div className="table__td find-group__area">
      <div className="txt-val">
        {area} <small>м²</small>
      </div>
    </div>
    <div className="table__td find-group__price-metre">
      <div className="txt-val">{formatCurrency(baseRate / area)} ₽</div>
    </div>
    <div className="table__td find-group__price-month">
      <div className="txt-val">
        <small>от </small>
        {formatCurrency(baseRate)} ₽
      </div>
    </div>
  </span>
);

export default ListItem;
