import axios from 'axios';

export default class GroupsAPI {
  _apiBase = `${process.env.REACT_APP_API_BASE}/groups`;

  getGroups = async data => {
    try {
      const res = await axios.get(
        `${this._apiBase}/?page=${data.page}&page_size=${data.pageSize}&base_rate_min=${
          data.baseRateMin
        }&base_rate_max=${data.baseRateMax}&area_min=${data.areaMin}&area_max=${
          data.areaMax
        }&amount_max=${data.amountMax}&amount_min=${data.amountMin}&ordering=${
          data.ordering
        }&location=${data.location}`,
      );
      return res;
    } catch (e) {
      return e;
    }
  };

  getGroupsMap = async id => {
    try {
      const res = await axios.get(`${this._apiBase}/${id}/map/`);
      return res;
    } catch (e) {
      return e;
    }
  };

  getSuggestions = async id => {
    try {
      const res = await axios.get(`${this._apiBase}/${id}/suggestions/`);
      return res;
    } catch (e) {
      return e;
    }
  };

  getGroup = async id => {
    try {
      const res = await axios.get(`${this._apiBase}/${id}/`);
      return res;
    } catch (e) {
      return e;
    }
  };
}
