import React from 'react';
import './CookiePolicyPanel.scss';
import useCookiePolicyState from './useCookiePolicyState';
import PrivatePolicyLink from './PrivatePolicyLink/PrivatePolicyLink';
import CloseButton from './CloseButton/CloseButton';

export default function CookiePolicyPanel() {
  const { isCookiePolicyAccepted, acceptCookiePolicy } = useCookiePolicyState();

  return (!isCookiePolicyAccepted && (
    <div className="cookie-policy-panel">
      <div className="cookie-policy-panel__body">
        Данный сайт использует файлы-cookies, а также другие
        технологии для улучшения работы, совершенствования сервисов,
        определения пользовательских предпочтений и обеспечения безопасности.
        Продолжая работу с сайтом, Вы даете согласие на обработку файлов-cookies в
        соответствии с <PrivatePolicyLink>Политикой в отношении обработки персональных данных</PrivatePolicyLink>.
        При несогласии Вы можете отключить обработку файлов-cookies в настройках
        Вашего браузера или закрыть страницу сайта.
      </div>
      <div className="cookie-policy-panel__close-button-wrapper">
        <CloseButton onClick={acceptCookiePolicy} />
      </div>
    </div>
  ));
}
