import AuthAPI from '../../services/authAPI';
import OrganizationAPI from '../../services/organizationAPI';

const authAPI = new AuthAPI();
const organizationAPI = new OrganizationAPI();
const getErrors = res => Object.values(res).map(el => Object.values(el)) || 'Ошибка';

export const registerRequest = registerData => dispatch => {
  dispatch({ type: 'REQUEST_REGISTER' });
  authAPI
    .register(registerData)
    .then(res => {
      if (res.status >= 200 && res.status <= 399) {
        dispatch({
          type: 'SUCCESS_REGISTER',
          payload: registerData,
        });
      } else {
        dispatch({
          type: 'ERROR_REGISTER',
          payload: 'Ошибка. Проверьте правильность введенных данных.',
        });
      }
    })
    .catch(res => {
      dispatch({
        type: 'ERROR_REGISTER',
        payload: getErrors(res.response.data),
      });
    });
};

export const getDropdownData = () => dispatch => {
  dispatch({
    type: 'REQUEST_DROPDOWN_DATA',
  });
  organizationAPI.getAll().then(res => {
    dispatch({
      type: 'DROPDOWN_DATA_RECEIVED',
      payload: res,
    });
  });
};
