import React from 'react';
import FiltersCheckbox from '../FiltersCheckbox';
import SecondaryFilterWrapper from '../../../../ui/Form/SecondaryFilterWrapper';

const CategoryFilter = ({
  availableCategories,
  selectedItemIds,
  onChange,
  title,
  toggleAll,
}) => {
  const toggleItemId = (itemId) => {
    if (selectedItemIds.includes(itemId)) {
      onChange(selectedItemIds.filter(id => id !== itemId));
    } else {
      onChange([...selectedItemIds, itemId]);
    }
  };
  const availableIds = availableCategories.reduce((acc, { id }) => [...acc, id], []);
  const allChecked = availableIds.every(id => selectedItemIds.includes(id));
  return (
    <>
      <SecondaryFilterWrapper
        allChecked={allChecked}
        toggleAll={toggleAll}
        title={title}
        closed
      >
        {availableCategories.map(item => (
          <div key={item.id} className="filter__option">
            <FiltersCheckbox
              checked={selectedItemIds.includes(item.id)}
              onChange={() => toggleItemId(item.id)}
            >
              {item.name}
            </FiltersCheckbox>
          </div>
        ))}
      </SecondaryFilterWrapper>
    </>
  );
};

export default CategoryFilter;
