import React from 'react';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';

import formatCurrency from '../../../shared/utils/formatCurrency';

const RequestCard = ({
  name,
  objectId,
  status,
  date,
  area,
  rate,
  id,
  leaseTerm,
  declinedComment,
}) => (
  <div className="lk-request">
    <div className="lk-request__left">
      <div className="lk-request__left-top">
        <div className="lk-request__id">Заявка {id}</div>
        <div className="lk-request__date">
          {format(date, 'D MMMM YYYY HH:mm', { locale: ru })}
        </div>
      </div>
      <div className="lk-request__left-bottom">
        {status === 2 && (
          <div className="lk-request__status">
            <div className="lk-request__status-name">На рассмотрении</div>
            <div className="lk-request__status-notice">Ожидаем ответа по заявке</div>
          </div>
        )}
        {status === 1 && (
          <div className="lk-request__status is-cancel">
            <div className="lk-request__status-name">Отклонена</div>
          </div>
        )}
        {status === 0 && (
          <div className="lk-request__status is-approved">
            <div className="lk-request__status-name">Принята</div>
          </div>
        )}
      </div>
    </div>
    <div className="lk-request__right">
      <div className="lk-request__num">№ {objectId}</div>
      <div className="lk-request__address">{name}</div>
      <div className="lk-request__features">
        <div className="lk-request__features-item">
          <div className="lk-request__features-title">Общая площадь</div>
          <div className="lk-request__features-val">
            {area} <small>м²</small>
          </div>
        </div>
        <div className="lk-request__features-item">
          <div className="lk-request__features-title">Аренда в месяц</div>
          <div className="lk-request__features-val">
            <small>от </small>
            {formatCurrency(rate)}
            <small> ₽/мес</small>
          </div>
        </div>
        <div className="lk-request__features-item">
          <div className="lk-request__features-title">Срок аренды</div>
          <div className="lk-request__features-val">{leaseTerm} мес</div>
        </div>
      </div>
      {status === 1 && declinedComment && (
        <div className="lk-request__comment">
          <div className="lk-request__comment-title is-cancel">Причина отклонения</div>
          <div className="lk-request__comment-val">{declinedComment}</div>
        </div>
      )}
      <div className="lk-request__option">
        Для отмены заявки необходимо обратиться <br />к территориальному менеджеру
      </div>
    </div>
  </div>
);

export default RequestCard;
