import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import cx from 'classnames';


import { connect } from 'react-redux';
import LK1 from '../../ui/svg/lk1';
import LK2 from '../../ui/svg/lk2';
import LK3 from '../../ui/svg/lk3';
import LK4 from '../../ui/svg/lk4';
import LK5 from '../../ui/svg/lk5';

const getIcon = {
  cabinet: <LK1 />,
  requests: <LK2 />,
  contracts: <LK3 />,
  data: <LK4 />,
  settings: <LK5 />,
};

const NavigationLink = ({
  title,
  to,
  location,
  icon,
}) => {
  const isCurrent = to === '/cabinet/'
    ? location.pathname === '/cabinet' || location.pathname === '/cabinet/'
    : location.pathname.includes(to);

  return (
    <Link
      to={to}
      className={cx(
        'lk-aside__item',
        { 'is-current': isCurrent },
      )}
    >
      <i>
        {getIcon[icon]}
      </i>
      <span>{title}</span>
    </Link>
  );
};
const mapStateToProps = state => ({
  loginData: state.login,
});
export default withRouter(connect(mapStateToProps)(NavigationLink));
