import React, {
  useEffect, useState,
} from 'react';
import FilterWrapper from '../../../../ui/Layout/FilterWrapper';
import formatCurrency from '../../../../../shared/utils/formatCurrency';
import { scrollTop } from '../../../../../shared/utils/ScrollToTop';
import FiltersRange from '../FiltersRange';
import CategoryFilter from '../FilterCategory';

export default function FiltersAside({
  filtersLoading,
  areaBounds,
  baseRateBounds,
  availableCategoriesWithoutGroups,
  availableCategoriesWithGroups,
  values,
  onReset,
  onApply,
}) {
  const createEnterKeyHandler = (handler) => event => {
    if (event.key !== 'Enter') return;

    handler();
  };

  const [localValues, setLocalValues] = useState(values);

  useEffect(() => {
    setLocalValues(values);
  }, [values]);

  const setAreaRange = range => {
    setLocalValues((prevState) => ({
      ...prevState,
      areaRange: range,
    }));
  };

  const setBaseRateRange = range => {
    setLocalValues((prevState) => ({
      ...prevState,
      baseRateRange: range,
    }));
  };

  const setSelectedCategoryIds = categoryIds => {
    setLocalValues((prevState) => ({
      ...prevState,
      categories: categoryIds,
    }));
  };

  const toggleAllByGroupId = categories => addCategories => {
    const selected = categories
      .reduce((acc, { id }) => [...acc, id], []);

    if (addCategories) {
      const result = localValues.categories.filter(cateogry => !selected.includes(cateogry));
      setSelectedCategoryIds([...result]);
    } else {
      const result = new Set([...selected, ...localValues.categories]);
      setSelectedCategoryIds([...result]);
    }
  };
  const apply = () => {
    onApply(localValues);
  };

  const preventSubmit = event => event.preventDefault();

  return (
    <form onSubmit={preventSubmit} className="filters">
      <FilterWrapper title="Размер площади, м²">
        <FiltersRange
          value={localValues.areaRange}
          bounds={areaBounds}
          placeholder={{
            min: `От ${Math.floor(areaBounds.min)}`,
            max: `До ${Math.ceil(areaBounds.max)}`,
          }}
          unit="м²"
          onChange={setAreaRange}
        />
      </FilterWrapper>

      <FilterWrapper title="Стоимость аренды, ₽">
        <FiltersRange
          value={localValues.baseRateRange}
          bounds={baseRateBounds}
          placeholder={{
            min: `От ${formatCurrency(baseRateBounds.min)}`,
            max: `До ${formatCurrency(baseRateBounds.max)}`,
          }}
          unit="₽"
          onChange={setBaseRateRange}
        />
      </FilterWrapper>

      {!filtersLoading && (
        <React.Fragment>
          <FilterWrapper title="Категория площади">
            {availableCategoriesWithGroups.map(group => (
              group.categories.length > 0
                && (
                  <CategoryFilter
                    key={group.id}
                    availableCategories={group.categories}
                    selectedItemIds={localValues.categories}
                    onChange={setSelectedCategoryIds}
                    toggleAll={toggleAllByGroupId(group.categories)}
                    title={group.name}
                  />
                )
            ))}
            {availableCategoriesWithoutGroups.length > 0 && (
              <CategoryFilter
                availableCategories={availableCategoriesWithoutGroups}
                selectedItemIds={localValues.categories}
                onChange={setSelectedCategoryIds}
                toggleAll={toggleAllByGroupId(availableCategoriesWithoutGroups)}
                title="Без группы"
              />
            )}
          </FilterWrapper>
        </React.Fragment>
      )}

      <div className="filter-reset-apply">
        <div className="filter-apply-btn">
          <button
            className="i-button i-button--small i-button--135"
            type="submit"
            onKeyDown={createEnterKeyHandler(scrollTop(apply, 200))}
            onClick={scrollTop(apply, 200)}
          >
            Применить
          </button>
        </div>
        <span
          className="filter-reset-btn"
          role="button"
          tabIndex="0"
          onKeyDown={createEnterKeyHandler(onReset)}
          onClick={onReset}
        >
          Сбросить
        </span>
      </div>
    </form>
  );
}
