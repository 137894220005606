import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';

import registerReducer from './Register/Register.reducer';
import * as registerActions from './Register/Register.actions';
import * as loginActions from './Login/Login.actions';
import loginReducer from './Login/Login.reducer';
import findReducer from './Find/Find.reducer';

const reducer = combineReducers({
  register: registerReducer,
  login: loginReducer,
  find: findReducer,
});

const logMiddleware = ({ getState }) => next => action => {
  if (process.env.NODE_ENV === 'development') console.log(action.type, getState());
  return next(action);
};

const stringMiddleware = () => next => action => {
  if (typeof action === 'string') {
    return next({
      type: action,
    });
  }

  return next(action);
};

const store = createStore(
  reducer,
  applyMiddleware(thunkMiddleware, stringMiddleware, logMiddleware),
);

export default store;
export {
  registerActions,
  registerReducer,
  loginActions,
  loginReducer,
};
