import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import RequestCard from '../../../components/common/Cabinet/RequestCard';

import DocumentsAPI from '../../../services/documentsAPI';
import EX from '../../../components/ui/svg/exclamation';

const documentsAPI = new DocumentsAPI();

const Requests = () => {
  const [requestData, setRequestData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [requestType, setRequestType] = useState('all');
  const [requestPage, setRequestPage] = useState(1);

  const getData = (page, type) => {
    setLoading(true);
    documentsAPI
      .getRequests(page, type)
      .then(({ data }) => {
        setRequestData(data);
        setLoading(false);
      });
  };

  useEffect(() => {
    getData(requestPage, requestType);
  }, [requestPage, requestType]);

  const setAll = () => setRequestType('all');
  const setSpaces = () => setRequestType('space');
  const setGroup = () => setRequestType('group');

  const changePage = page => {
    setRequestPage(page);
  };

  return (
    <>
      <Helmet title="Заявки" />
      <div className="lk-content lk-requests">
        <h1 className="h2 lk-requests__title">Заявки</h1>
        <div className="lk-request__option">
          <i><EX /></i> По всем возникшим вопросам, связанным с заявками в работе вы можете обратиться к Менеджеру по субаренде ТС5 вашей территории.
          <Link className="lk-request__option-link" to="/terms#contacts-my-region"> Контакты сотрудников вы можете найти перейдя в Условия сотрудничества</Link>
        </div>
        <div className="lk-requests__filter">
          <button
            type="button"
            onClick={setAll}
            className={cx('lk-requests__filter-item', { 'is-current': requestType === 'all' })}
          >
            Все заявки
          </button>
          <button
            type="button"
            onClick={setSpaces}
            className={cx('lk-requests__filter-item', { 'is-current': requestType === 'space' })}
          >
            На свободные площади
          </button>
          <button
            type="button"
            onClick={setGroup}
            className={cx('lk-requests__filter-item', { 'is-current': requestType === 'group' })}
          >
            На группы площадей
          </button>
        </div>

        {loading && <div className="lk-requests__list">Загружаю...</div>}

        {!loading && (
        <div className="lk-requests__list">
          {!requestData.results.length && (
          <p>Заявок не найдено.</p>
          )}
          {requestData.results.map(el => (
            <RequestCard
              key={el.id}
              name={el.name}
              objectId={el.obj_id}
              status={el.state}
              date={el.created_at}
              area={el.area}
              rate={el.base_rate}
              leaseTerm={el.lease_term}
              id={el.asko_id}
            />
          ))}
        </div>
        )}

        {!loading && (requestData.next || requestData.previous) && (
        <div className="lk__pagination">
          <div className="i-pagination">
            <button
              type="button"
              onClick={() => changePage(requestPage - 1)}
              className={cx('i-pagination__item i-pagination__item--prev', {
                'is-disabled': !requestData.previous,
              })}
            />
            <button
              type="button"
              onClick={() => changePage(requestPage + 1)}
              className={cx('i-pagination__item i-pagination__item--next', {
                'is-disabled': !requestData.next,
              })}
            />
          </div>
        </div>
        )}
      </div>
    </>
  );
};

export default Requests;
