import React from 'react';

export default function CardMediaFallback() {
  return (
    <img
      style={{ width: '100%' }}
      className="m-slider__slide-img m-slider__slide-img_cover"
      src="/img/media/no_img.png"
      alt="Изображение-заглушка"
    />
  );
}
