import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import formatCurrency from '../../../../../../shared/utils/formatCurrency';

class Card extends Component {
  state = { currentSlide: 1, slidesCount: undefined, isSliding: false };

  componentDidMount() {
    const { photos } = this.props;
    this.setState({ slidesCount: photos.length });
  }

  nextSlide = () => {
    const { isSliding } = this.state;
    this.setState({ isSliding: true }, () => {
      if (!isSliding) {
        const { currentSlide, slidesCount } = this.state;
        setTimeout(() => {
          this.setState(prev => {
            if (currentSlide === slidesCount) return { currentSlide: 1 };
            return { currentSlide: prev.currentSlide + 1 };
          }, this.setState({ isSliding: false }));
        }, 500);
      }
    });
  };

  prevSlide = () => {
    const { isSliding } = this.state;
    this.setState({ isSliding: true }, () => {
      if (!isSliding) {
        const { currentSlide, slidesCount } = this.state;
        setTimeout(() => {
          this.setState(prev => {
            if (currentSlide === 1) return { currentSlide: slidesCount };
            return { currentSlide: prev.currentSlide - 1 };
          }, this.setState({ isSliding: false }));
        }, 500);
      }
    });
  };

  render() {
    const { currentSlide, slidesCount } = this.state;
    const {
      id, address, baseRate, area, photos,
    } = this.props;
    if (!photos.length) {
      return (
        <div className="find-item__slide">
          <div className="m-slider">
            <div className="m-slider__info">
              <div className="m-slider__info-left">
                <div className="card__features-val">
                  {area} <small>м²</small>
                </div>
              </div>
              <div className="m-slider__info-right">
                <div className="card__features-val">
                  <small>от </small>
                  {formatCurrency(baseRate)}
                  <small> ₽/мес</small>
                </div>
                <div className="card__features-notice">{formatCurrency(baseRate / area)} ₽/м²</div>
              </div>
            </div>
            <div className="m-slider__wrap js-mSlider">
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={`/find/${id}`}
                className="i-link-black find-item__slide-text"
              >
                <div
                  className="m-slider__slide-img"
                  style={{ backgroundImage: 'url(../../../img/media/no_img.png)' }}
                />
              </Link>
            </div>
          </div>
          <Link to={`/find/${id}`} className="i-link-black find-item__slide-text">
            {address}
          </Link>
        </div>
      );
    }
    return (
      <div className="find-item__slide">
        {!!photos.length && (
          <div className="m-slider">
            <div
              className="js-mNextSlide m-slider__next"
              onClick={() => {
                this.slider.slickNext();
                this.nextSlide();
              }}
              tabIndex="0"
              role="button"
              onKeyPress={e => {
                if (e.keyCode === 13) {
                  this.slider.slickNext();
                  this.nextSlide();
                }
              }}
            />
            <div
              className="js-mPrevSlide m-slider__prev"
              onClick={() => {
                this.slider.slickPrev();
                this.prevSlide();
              }}
              tabIndex="0"
              role="button"
              onKeyPress={e => {
                if (e.keyCode === 13) {
                  this.slider.slickPrev();
                  this.prevSlide();
                }
              }}
            />
            <div className="m-slider__index">
              <span className="js-mCurrent">{currentSlide}</span>
              <span className="m-slider__in">из</span>
              <span className="js-mSize">{slidesCount}</span>
            </div>
            <div className="m-slider__info">
              <div className="m-slider__info-left">
                <div className="card__features-val">
                  {area} <small>м²</small>
                </div>
              </div>
              <div className="m-slider__info-right">
                <div className="card__features-val">
                  <small>от </small>
                  {formatCurrency(baseRate)}
                  <small> ₽/мес</small>
                </div>
                <div className="card__features-notice">{formatCurrency(baseRate / area)} ₽/м²</div>
              </div>
            </div>
            <div className="m-slider__wrap js-mSlider">
              <Slider
                draggable={false}
                ref={c => (this.slider = c)}
                arrows={false}
                infinite
                speed={500}
              >
                {photos.map((el, idx) => (
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={`/find/${id}`}
                    className="i-link-black find-item__slide-text"
                  >
                    <div key={idx} className="m-slider__slide">
                      <div
                        className="m-slider__slide-img"
                        style={{ backgroundImage: `url(${el})` }}
                      />
                    </div>
                  </Link>
                ))}
              </Slider>
            </div>
          </div>
        )}
        <Link
          target="_blank"
          rel="noopener noreferrer"
          to={`/find/${id}`}
          className="i-link-black find-item__slide-text"
        >
          {address}
        </Link>
      </div>
    );
  }
}

export default Card;
