import React from 'react';
import cx from 'classnames';
import InputMask from 'react-input-mask';
import { MEMBER_TYPE } from '../../../../shared/constants/Profile';
import FormField from '../../../ui/Form/FormField';
import Checkbox from '../../../ui/Form/Checkbox';
import NameInput from '../../../ui/Form/NameInput';
import DatePicker from '../../../ui/Form/DatePicker';
import Input from '../../../ui/Form/Input';
import Textarea from '../../../ui/Form/Textarea';

const MembersItem = ({
  member, index, profileActions, form, invalidField,
}) => {
  const isMemberFormVisible = (memberType) => {
    switch (memberType) {
      case MEMBER_TYPE.TRUSTED_PERSON:
        return form.has_trust_person;
      case MEMBER_TYPE.CHIEF_ACCOUNTANT:
        return form.has_chief_accountant;
      default:
        return true;
    }
  };

  return (
    <>
      {member.type === MEMBER_TYPE.TRUSTED_PERSON && (
        <>
          <h4 className="form-h4">Доверенное лицо</h4>
          <FormField>
            <Checkbox
              toggle={() => { profileActions.toggleHasTrustPerson(!form.hasTrustPerson); }}
              checked={!form.hasTrustPerson}
            >
              Доверительного лица нет
            </Checkbox>
          </FormField>
        </>
      )}
      {member.type === MEMBER_TYPE.CHIEF_ACCOUNTANT && (
        <>
          <h4 className="form-h4">Главный бухгалтер</h4>
          <FormField>
            <Checkbox
              toggle={() => { profileActions.toggleHasChiefAccount(!form.hasChiefAccountant); }}
              checked={!form.hasChiefAccountant}
            >
              Главного бухгалтера нет
            </Checkbox>
          </FormField>
        </>
      )}
      {member.type === MEMBER_TYPE.GENERAL_MANAGER && (
        <h4 className="form-h4">Генеральный директор или руководитель</h4>
      )}

      {isMemberFormVisible(member.type) && (
        <div id="dovInfo">
          <FormField>
            <NameInput
              id={member.type}
              className={cx({
                'vf-error': invalidField && invalidField.lastName,
              })}
              value={member.lastName}
              onChange={profileActions.setMemberLastName}
              placeholder="Фамилия"
            />
          </FormField>
          <FormField>
            <NameInput
              id={member.type}
              className={cx({
                'vf-error': invalidField && invalidField.firstName,
              })}
              value={member.firstName}
              onChange={profileActions.setMemberFirstName}
              placeholder="Имя"
            />
          </FormField>
          <FormField>
            <NameInput
              id={member.type}
              className={cx({
                'vf-error': invalidField && invalidField.middleName,
              })}
              value={member.middleName}
              onChange={profileActions.setMemberMiddleName}
              placeholder="Отчество"
            />
          </FormField>
          <FormField label="Дата рождения">
            <DatePicker
              error={invalidField && invalidField.birthDate}
              currentDate={member.birthDate}
              handleChange={(value) => profileActions.setMemberBirthDate(value, member.type)}
            />
          </FormField>
          <div className={cx('form-line')}>
            <span className="form-label">Паспортные данные</span>

            <div className={cx('form-line__row')}>
              <div className="form-field">
                <InputMask
                  className={cx(['i-input', {
                    'vf-error': invalidField && invalidField.passport.series,
                  }])}
                  value={member.passport.series}
                  id={member.type}
                  onChange={profileActions.setMemberPassportSeries}
                  mask="9999"
                  maskChar={null}
                  placeholder="Серия"
                />
              </div>
              <div>
                <InputMask
                  className={cx('i-input', {
                    'vf-error': invalidField && invalidField.passport.number,
                  })}
                  value={member.passport.number}
                  id={member.type}
                  onChange={profileActions.setMemberPassportNumber}
                  mask="999999"
                  maskChar={null}
                  placeholder="Номер"
                />
              </div>
            </div>
          </div>

          <FormField>
            <Input
              className={cx({
                'vf-error': invalidField
                  && invalidField.passport.issuer,
              })}
              value={member.passport.issuer}
              id={member.type}
              onChange={profileActions.setMemberPassportIssuer}
              type="text"
              placeholder="Кем выдан"
            />
          </FormField>
          <FormField label="Дата выдачи">
            <DatePicker
              error={invalidField && invalidField.passport.issueDate}
              currentDate={member.passport.issueDate}
              handleChange={(value) => { profileActions.setMemberPassportIssueDate(value, member.type); }}
            />
          </FormField>
          <FormField>
            <Textarea
              className={cx({
                'vf-error': invalidField && invalidField.passport.registrationAddress,
              })}
              value={member.passport.registrationAddress}
              id={member.type}
              onChange={profileActions.setMemberPassportRegistrationAddress}
              placeholder="Адрес регистрации"
            />
          </FormField>

          {index !== form.members.length - 1 && <hr className="hr hr--small" />}
        </div>
      )}
    </>
  );
};

export default MembersItem;
