import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import OptionsList from '../Form/OptionsList';

const SelectOptionsWithId = ({ option }) => (
  <option value="">
    {option.name}
  </option>
);

const SelectOptionsWithoutId = ({ option }) => (
  <option value="">
    {option}
  </option>
);

const Dropdown = ({
  onChange,
  options,
  placeholder,
  value,
  disabled,
  optionsWithId,
  error,
}) => {
  const [opened, setOpened] = useState(false);
  const wrapperRef = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggle = () => {
    setOpened(!opened);
  };

  const setValue = val => {
    setOpened(false);
    onChange(val);
  };

  const handleClickOutside = event => {
    if (wrapperRef && !wrapperRef.current.contains(event.target)) {
      setOpened(false);
    }
  };

  return (
    <div
      ref={wrapperRef}
      className="i-select"
    >
      <div
        className={cx('SumoSelect', { open: opened, disabled })}
        tabIndex="0"
        role="button"
        aria-expanded="true"
      >
        <select
          className="SumoUnder"
          tabIndex="-1"
        >
          {options && options.map(option => (optionsWithId
            ? (
              <SelectOptionsWithId
                key={option.id}
                option={option}
              />
            )
            : (
              <SelectOptionsWithoutId
                key={option}
                option={option}
              />
            )))}
          {/* {optionsWithId ? selectOptionsWithId : selectOptionsWithoutId} */}
        </select>
        <p
          onClick={toggle}
          className={cx('CaptionCont SelectBox', { 'dd-error': error })}
        >
          <span className={cx({ placeholder: !value })}>
            {value || placeholder}
          </span>
          <label>
            <i />
          </label>
        </p>
        {opened && options && !disabled && (
          <div className="optWrapper">
            <OptionsList
              options={options}
              optionsWithId={optionsWithId}
              value={value}
              setValue={setValue}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default Dropdown;
