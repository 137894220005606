import React, { useCallback, useMemo } from 'react';
import * as uuid from 'uuid';
import styles from './index.module.scss';

export default function FiltersCheckbox({
  checked,
  onChange,
  children,
}) {
  const id = useMemo(() => uuid.v4(), []);

  const toggle = useCallback(() => {
    onChange(!checked);
  }, [onChange, checked]);

  return (
    <label className={styles.checkbox} htmlFor={id}>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={toggle}
      />
      <span>{children}</span>
    </label>
  );
}
