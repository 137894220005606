import React from 'react';
import Helmet from 'react-helmet';
import RequestOperationStatus from '../../../components/common/Request/Operation/Status';
import requestOperationTypes from '../../../components/common/Request/Operation/Types';

const RequestConfirm = ({ match }) => {
  const { organizationId, uuid } = match.params;

  return (
    <>
      <Helmet title="Подтверждение заявки" />

      <RequestOperationStatus
        header="Подтверждение заявки"
        requestOperationType={requestOperationTypes.Confirm}
        organizationId={organizationId}
        uuid={uuid}
      />
    </>
  );
};

export default RequestConfirm;
