import React, { useState } from 'react';
import cx from 'classnames';
import TooltipSVG from '../svg/tooltip';

const Tooltip = ({
  right,
  children,
}) => {
  const [visible, setVisible] = useState(false);
  const open = () => setVisible(true);
  const close = () => setVisible(false);

  return (
    <i
      onFocus={open}
      onMouseOver={open}
      onMouseLeave={close}
      className="i-infotool"
    >
      <TooltipSVG />
      {visible
        && (
        <div className={cx('i-svistip', { 'tooltip-right': right })}>
          {children}
        </div>
        )}
    </i>
  );
};

export default Tooltip;
