import React from 'react';
import cx from 'classnames';
import OrganizationAPI from '../../../services/organizationAPI';

const organizationAPI = new OrganizationAPI();

export default ({ dispatch, close }) => {
  const [focus, setFocus] = React.useState(false);
  const [regions, setRegions] = React.useState([]);
  const [currentRegions, setCurrentRegions] = React.useState([]);
  const [searchString, setSearchString] = React.useState('');
  const [opened, setOpened] = React.useState(false);

  React.useEffect(() => {
    const getRegions = async () => {
      const { data } = await organizationAPI.getRegions();
      setRegions(data);
    };
    getRegions();
  }, []);

  React.useEffect(() => {
    const newRegions = regions.filter(el => {
      const searchStringLowercase = searchString.toLowerCase();
      const regionNameLowercase = el.alias.toLowerCase();

      return regionNameLowercase.includes(searchStringLowercase);
    });
    if (!searchString.length) {
      setCurrentRegions([]);
      return;
    }
    setCurrentRegions(newRegions.slice(0, 10));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  React.useEffect(() => {
    if (focus || searchString.length || currentRegions.length) {
      setTimeout(() => setOpened(true), 200);
    } else {
      setTimeout(() => setOpened(false), 200);
    }
    if (!focus) {
      setTimeout(() => setOpened(false), 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focus, searchString]);

  if (regions) {
    return (
      <div className={cx('search', { 'search--focused': focus })}>
        <div className="search__value">
          <input onFocus={() => setFocus(true)} onBlur={() => setFocus(false)} onChange={e => setSearchString(e.target.value)} type="text" className="search__input" placeholder="Найдите свой город" value={searchString} />
        </div>
        {opened && (
        <div className="search__results">
          {currentRegions.map(el => (
            <div
              tabIndex={0}
              role="button"
              key={el.id}
              className="search__result"
              onClick={
              () => {
                const name = el.alias; // TODO : Разбораться с этой строчкой где мы подменяем name на алиас
                dispatch({ type: 'SET_REGION', payload: { ...el, name } });
                localStorage.setItem('currentRegionId', el.id.toString());
                close();
              }
            }
            >{el.alias}
            </div>
          ))}
        </div>
        )}
      </div>
    );
  }
  return null;
};
