import React, { useState, useEffect, useCallback } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import Navigation from '../../components/common/Cabinet/Navigation';
import Index from './Index/index';
import Requests from './Requests';
import Contracts from './Contracts';
import Contract from './Contract';
import Data from './Data';
import Profile from './Profile';
import Settings from './Settings';

const Cabinet = ({
  history,
  loginData,
}) => {
  const [token, setToken] = useState(true);
  const [loginSuccess, setLoginSuccess] = useState(true);

  useEffect(() => {
    // eslint-disable-next-line no-useless-escape
    const Token = document.cookie.replace(/(?:(?:^|.*;\s*)Token\s*\=\s*([^;]*).*$)|^.*$/, '$1');
    if (!Token.length) {
      setToken(false);
      history.replace('/');
    }
  }, [history]);

  const checkAuth = useCallback(() => {
    if (!loginSuccess || !token) history.replace('/');
  }, [history, loginSuccess, token]);

  useEffect(() => {
    const { loginRequested } = loginData;
    if (!loginSuccess && !loginRequested) {
      setLoginSuccess(false);
      checkAuth();
    }
  }, [checkAuth, loginData, loginSuccess]);

  if (!loginData.loginSuccess && loginData.logoutSuccess) {
    return <Redirect to="/" />;
  }

  return (
    <div className="w-content">
      <div className="lk-wrap">
        <div className="lk-left">
          <Navigation />
        </div>
        <div className="lk-right">
          <Switch>
            <Route path="/cabinet/" component={Index} exact />
            <Route path="/cabinet/requests/" component={Requests} exact />
            <Route path="/cabinet/contracts/" component={Contracts} exact />
            <Route path="/cabinet/contracts/:id/" component={Contract} exact />
            <Route path="/cabinet/data/" component={Data} exact />
            <Route path="/cabinet/data/profile/" component={Profile} exact />
            <Route path="/cabinet/settings/" component={Settings} exact />
          </Switch>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  loginData: state.login,
});

export default connect(mapStateToProps)(Cabinet);
