import * as uuid from 'uuid';

export const SIGN_TYPE = {
  RESIDENT: 1,
  NOT_RESIDENT: 2,
};

export const MEMBER_TYPE = {
  GENERAL_MANAGER: 0,
  CHIEF_ACCOUNTANT: 1,
  TRUSTED_PERSON: 2,
};

export const DEFAULT_PASSPORT = {
  series: '',
  number: '',
  issuer: '',
  issueDate: '',
  registrationAddress: '',
};

export function getEmptyMemberWithType(type) {
  return {
    type,
    firstName: '',
    middleName: '',
    lastName: '',
    birthDate: '',
    passport: { ...DEFAULT_PASSPORT },
  };
}

export const DEFAULT_MEMBER = getEmptyMemberWithType(null);

export const DEFAULT_MAIN_INFORMATION = {
  companySign: null,
  englishName: '',
  fullName: '',
  shortName: '',
  phone: '',
  email: '',
  hasTrustPerson: true,
  hasChiefAccountant: true,
};

export const DEFAULT_ORGANIZATION = {
  type: null,
  OGRN: '',
  INN: '',
  KPP: '',
  OKPO: '',
  OKVED: '',
  OKONX: '',
  legalAddress: '',
  physicalAddress: '',
};

export const DEFAULT_REGISTRATION = {
  date: '',
  authorityName: '',
};

export const DEFAULT_BANK = {
  country: '',
  name: '',
  address: '',
  BIK: '',
  checkingAccount: '',
  correspondentAccount: '',
  SWIFT: '',
  IBAN: '',
  ABA: '',
};

export const DEFAULT_LEGAL_BENEFICIARY = {
  fullName: '',
  shortName: '',
  phone: '',
  email: '',
  organization: { ...DEFAULT_ORGANIZATION },
  registration: { ...DEFAULT_REGISTRATION },
};

export const DEFAULT_INDIVIDUAL_BENEFICIARY = {
  firstName: '',
  middleName: '',
  lastName: '',
  birthDate: '',
  passport: { ...DEFAULT_PASSPORT },
};

export function createEmptyLegalBeneficiary() {
  return {
    id: uuid.v4(),
    fullName: '',
    shortName: '',
    phone: '',
    email: '',
    organization: { ...DEFAULT_ORGANIZATION },
    registration: { ...DEFAULT_REGISTRATION },
  };
}

export function createEmptyIndividualBeneficiary() {
  return {
    id: uuid.v4(),
    firstName: '',
    middleName: '',
    lastName: '',
    birthDate: '',
    passport: { ...DEFAULT_PASSPORT },
  };
}

export function getDefaultForm() {
  return {
    ...DEFAULT_MAIN_INFORMATION,
    organization: { ...DEFAULT_ORGANIZATION },
    registration: { ...DEFAULT_REGISTRATION },
    bank: { ...DEFAULT_BANK },
    members: [],
    legalBeneficiaries: [],
    individualBeneficiaries: [],
  };
}

export function getDefaultValidation() {
  return {
    organization: {},
    registration: {},
    bank: {},
    members: [],
    legalBeneficiaries: [],
    individualBeneficiaries: [],
  };
}
