import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import Portal from '../../../shared/utils/Portal';
import Barcode from '../../modals/Barcode';

const ContractCard = ({
  id,
  askoId,
  legacyId,
  startDate,
  expirationDate,
  dayOfMonth,
  rate,
  serviceClass,
  paymentDates,
}) => {
  const [modalIsOpened, setModalIsOpened] = useState(false);

  const toggle = () => {
    setModalIsOpened(!modalIsOpened);
  };

  return (
    <>
      <Link to={`/cabinet/contracts/${id}`} className="table__row table__row--body">
        <div className="table__td">
          <b>{askoId}</b>
        </div>
        <div className="table__td">
          Договор аренды № {legacyId}
          <span className="muted">
            с {format(startDate.slice(0, 10), 'DD.MM.YYYY')} по{' '}
            {format(expirationDate.slice(0, 10), 'DD.MM.YYYY')}
          </span>
        </div>
        <div className="table__td">{serviceClass}</div>
        <div className="table__td">
          <b>{rate} ₽</b> на {format(new Date(), 'DD.MM.YYYY')}
          <span className="muted">День платежа {dayOfMonth}</span>
        </div>
        <object>
          <div role="button" tabIndex="0" onMouseDown={toggle} className="table__td">
            <img src="../../img/barcode.svg" alt="" />
          </div>
        </object>
      </Link>
      {modalIsOpened && (
      <Portal>
        <Barcode close={toggle} id={id} boundDates={paymentDates} />
      </Portal>
      )}
    </>
  );
};

export default ContractCard;
