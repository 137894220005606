import React, { useState } from 'react';
import cx from 'classnames';

const Checkbox = ({
  checked,
  children,
  toggle,
  required,
  small,
  className,
}) => {
  const [innerChecked, setInnerChecked] = useState(checked);
  const innerToggle = () => setInnerChecked(!innerChecked);

  return (
    <div className={cx('i-checkbox', { 'i-checkbox--small': small }, className)}>
      <input
        required={required}
        onChange={innerToggle}
        id="apply"
        type="checkbox"
        checked={checked}
      />
      <label
        htmlFor="apply"
        onKeyDown={toggle || innerToggle}
        onClick={toggle || innerToggle}
      >
        <span>{children}</span>
      </label>
    </div>
  );
};

export default Checkbox;
