import React, {
  useEffect, useReducer, useRef, useState,
} from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import * as loginActions from '../../../store/Login/Login.actions';

import Input from '../../ui/Form/Input';
import FormField from '../../ui/Form/FormField';
import CustomCaptcha from '../../ui/CustomCaptcha/CustomCaptcha';
import { getInvalidFormFields, isFieldValid } from '../../../shared/Question/validation';

const reducer = (state, action) => {
  switch (action.field) {
    case 'email':
      return { ...state, email: action.payload };
    case 'password':
      return { ...state, password: action.payload };
    default:
      throw new Error(`Unknown field: ${action.field}`);
  }
};

const Login = ({
  close,
  loginData,
  noRedirect,
  dispatch,
}) => {
  const [state, dispatchState] = useReducer(reducer, {
    email: '',
    password: '',
  });
  const [invalidFields, setInvalidFields] = useState([]);

  const captchaRef = useRef();
  const wrapperRef = useRef();

  const updateState = ({ target: { name, value } }) => {
    if (value.length > 2) {
      if (!isFieldValid(name, value) && !invalidFields.includes(name)) {
        setInvalidFields([...invalidFields, name]);
      }
      if (isFieldValid(name, value)) {
        setInvalidFields(invalidFields.filter(field => field !== name));
      }
    }
    dispatchState({ field: name, payload: value });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', closeOnEsc);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', closeOnEsc);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeOnEsc = e => {
    if (e.keyCode === 27) {
      close();
    }
  };

  const submit = async () => {
    await captchaRef.current.verify();
  };

  const sendData = (captchaToken) => {
    const currentInvalidFormFields = getInvalidFormFields(state);
    setInvalidFields(currentInvalidFormFields);

    if (invalidFields.length <= 0) {
      dispatch(loginActions.loginRequest(email, password, captchaToken));
    }
  };

  const handleClickOutside = ({ target }) => {
    if (wrapperRef.current && !wrapperRef.current.contains(target)) {
      close();
    }
  };

  const validateFields = () => new Promise((resolve, reject) => {
    if (invalidFields.length === 0) resolve();
    reject();
  });

  const handleSuccessfulCaptchaValidation = (captchaToken) => {
    Promise.resolve()
      .then(() => validateFields())
      .then(() => sendData(captchaToken))
      .catch(() => undefined);
  };

  const getFieldValidStatus = field => !invalidFields.includes(field) && state[field]?.length > 2;
  const isFieldInvalid = field => invalidFields.includes(field);

  const {
    email, password, error,
  } = state;

  return (
    <>
      <div ref={wrapperRef} className="popup--registration">
        <div className="popup__inner">
          <div className="popup__registration-wrap">
            <h2 className="h2 popup__h2">Вход</h2>
            <form className="popup__form">
              <div className="form-group">
                <FormField className={cx({ 'form-field--ok': getFieldValidStatus('email') })}>
                  <Input
                    type="email "
                    placeholder="E-mail"
                    value={email}
                    onChange={updateState}
                    name="email"
                    className={cx({ 'vf-error': isFieldInvalid('email') })}
                  />
                </FormField>
                <FormField className={cx({ 'form-field--ok': getFieldValidStatus('password') })}>
                  <Input
                    type="password"
                    placeholder="Пароль"
                    value={password}
                    className={cx({ 'vf-error': isFieldInvalid('password') })}
                    onChange={updateState}
                    name="password"
                  />
                </FormField>
                <div className="form-group">
                  <CustomCaptcha
                    ref={captchaRef}
                    onSuccess={handleSuccessfulCaptchaValidation}
                  />
                </div>
                <div className="form-enter">
                  <button
                    onClick={submit}
                    type="button"
                    className="i-button i-button--full"
                  >
                    Войти
                  </button>
                  {(loginData.loginError || error) && (
                  <div style={{ color: '#E61801' }} className="form-forget">
                    {loginData.errorMessage}
                    {error}
                  </div>
                  )}
                  <Link onClick={close} to="/reset" className="form-forget">
                    Забыли пароль?
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="popup__registration">
          <div className="popup__registration-wrap">
            <p>
              Зарегистрируйтесь, если у вас
              <br />
              ещё нет личного кабинета
            </p>
            <Link
              onClick={close}
              to="/register"
              className="i-button i-button--full i-button--outline"
            >
              ЗАРЕГИСТРИРОВАТЬСЯ
            </Link>
          </div>
        </div>
        <button onClick={close} type="button" className="popup-close" title="Закрыть">
          <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24">
            <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
          </svg>
        </button>
        {!noRedirect && loginData.loginSuccess && <Redirect to="/cabinet" />}
        {loginData.loginSuccess && setTimeout(close, 10)}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  loginData: state.login,
});
export default connect(mapStateToProps)(Login);
