import React, { useEffect, useState } from 'react';
import FilterWrapper from '../../../../ui/Layout/FilterWrapper';
import FiltersRange from '../FiltersRange';
import formatCurrency from '../../../../../shared/utils/formatCurrency';
import { scrollTop } from '../../../../../shared/utils/ScrollToTop';

export default function GroupFiltersAside({
  groupSizeBounds,
  averageAreaBounds,
  baseRateBounds,
  values,
  onApply,
  onReset,
}) {
  const [localValues, setLocalValues] = useState(values);

  useEffect(() => {
    setLocalValues(values);
  }, [values]);

  const setGroupSizeRange = (range) => {
    setLocalValues(prevState => ({
      ...prevState,
      groupSizeRange: range,
    }));
  };

  const setAverageAreaRange = (range) => {
    setLocalValues(prevState => ({
      ...prevState,
      averageAreaRange: range,
    }));
  };

  const setBaseRateRange = (range) => {
    setLocalValues(prevState => ({
      ...prevState,
      baseRateRange: range,
    }));
  };

  const apply = () => {
    onApply(localValues);
  };

  const createEnterKeyHandler = (handler) => event => {
    if (event.key !== 'Enter') return;

    handler();
  };

  const preventSubmit = event => event.preventDefault();

  return (
    <form onSubmit={preventSubmit} className="filters">
      <FilterWrapper title="Площадей в группе">
        <FiltersRange
          value={localValues.groupSizeRange}
          bounds={groupSizeBounds}
          placeholder={{
            min: `От ${Math.floor(groupSizeBounds.min)}`,
            max: `До ${Math.ceil(groupSizeBounds.max)}`,
          }}
          onChange={setGroupSizeRange}
        />
      </FilterWrapper>

      <FilterWrapper title="Средняя площадь, м²">
        <FiltersRange
          value={localValues.averageAreaRange}
          bounds={averageAreaBounds}
          placeholder={{
            min: `От ${formatCurrency(averageAreaBounds.min)}`,
            max: `До ${formatCurrency(averageAreaBounds.max)}`,
          }}
          unit="м²"
          onChange={setAverageAreaRange}
        />
      </FilterWrapper>

      <FilterWrapper title="Стоимость аренды, ₽">
        <FiltersRange
          value={localValues.baseRateRange}
          bounds={baseRateBounds}
          placeholder={{
            min: `От ${formatCurrency(baseRateBounds.min)}`,
            max: `До ${formatCurrency(baseRateBounds.max)}`,
          }}
          unit="м²"
          onChange={setBaseRateRange}
        />
      </FilterWrapper>

      <div className="filter-reset-apply">
        <div className="filter-apply-btn">
          <button
            className="i-button i-button--small i-button--135"
            type="submit"
            onKeyDown={createEnterKeyHandler(scrollTop(apply, 200))}
            onClick={scrollTop(apply, 200)}
          >
            Применить
          </button>
        </div>
        <span
          className="filter-reset-btn"
          role="button"
          tabIndex="0"
          onKeyDown={createEnterKeyHandler(onReset)}
          onClick={onReset}
        >
          Сбросить
        </span>
      </div>
    </form>
  );
}
