import React from 'react';
import cx from 'classnames';
import DatePicker, { registerLocale } from 'react-datepicker';
import {
  getMonth, getYear, parse,
} from 'date-fns';

import ru from 'date-fns/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import '../DatePicker.scss';
import { localizeDayOfWeek } from '../../../shared/utils/localizeDayOfWeek';

registerLocale('ru', ru);

let year = 1900;
const years = [];
while (year <= 2030) years.push(year += 1);

const months = [
  { name: 'Январь', number: 0 },
  { name: 'Февраль', number: 1 },
  { name: 'Март', number: 2 },
  { name: 'Апрель', number: 3 },
  { name: 'Май', number: 4 },
  { name: 'Июнь', number: 5 },
  { name: 'Июль', number: 6 },
  { name: 'Август', number: 7 },
  { name: 'Сентябрь', number: 8 },
  { name: 'Октябрь', number: 9 },
  { name: 'Ноябрь', number: 10 },
  { name: 'Декабрь', number: 11 },
];

const DatePickerInput = ({
  currentDate,
  handleChange,
  error,
  id,
}) => {
  const getId = value => +months.find(el => el.name === value).number;

  const getDate = date => {
    let newDate = parse(date, 'dd/MM/yyyy', new Date());
    if (Object.prototype.toString.call(newDate) === '[object Date]' && isNaN(newDate.getTime())) {
      newDate = undefined;
    }
    return newDate;
  };

  return (
    <div className="form-field form-field--half form-field--date">
      <DatePicker
        id={id}
        maxDate={new Date([2030, 12, 31])}
        minDate={new Date([1899, 12, 31])}
        className={cx('i-input', { 'vf-error': error })}
        formatWeekDay={(date) => localizeDayOfWeek(date)}
        placeholderText="__.__.____"
        dateFormat="dd/MM/yyyy"
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              <span />
            </button>
            <select
              className="datepicker__select--month"
              value={months.find(el => +el.number === +getMonth(date)).name}
              onChange={e => {
                changeMonth(getId(e.currentTarget.value));
              }}
            >
              {months.map(option => (
                <option key={option.number} id={option.number} value={option.name}>
                  {option.name}
                </option>
              ))}
            </select>

            <select
              className="datepicker__select--year"
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              <span />
            </button>
          </div>
        )}
        selected={getDate(currentDate)}
        onChange={handleChange}
      />
    </div>
  );
};

export default DatePickerInput;
