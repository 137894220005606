import React from 'react';
import useRequestOperationStatus from '../../../../../store/hooks/useRequestOperationStatus';

export default function RequestOperationStatus({
  header,
  requestOperationType,
  organizationId,
  uuid,
}) {
  const message = useRequestOperationStatus(requestOperationType, organizationId, uuid);

  return (
    <div className="w-content">
      <div className="inner terms">
        <div className="container">
          <h1 className="h1 h1--mb30">{header}</h1>
        </div>
        <div style={{ marginBottom: 200 }} className="container">
          <p className="terms__content">{message}</p>
        </div>
      </div>
    </div>
  );
}
