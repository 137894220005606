const INITIAL_STATE = {
  loginError: false,
  errorMessage: '',
  loginRequested: false,
  loginSuccess: false,
  userData: '',
  logoutRequested: false,
  logoutError: false,
  logoutSuccess: false,
  geolocationRequested: false,
  geolocationCurrent: {},
};
// сейчас SUCCESS_LOGIN по факту SUCCESS_GET_TOKEN, так сделано потому, что данные
// о пользователе приходят только в случае авторизации по токену

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'REQUEST_LOGIN':
      return {
        ...state,
        loginRequested: true,
        errorMessage: '',
        loginError: false,
      };
    case 'SUCCESS_LOGIN':
      return {
        ...state,
        loginRequested: false,
      };
    case 'ERROR_LOGIN':
      return {
        ...state,
        loginError: true,
        errorMessage: action.payload,
        loginRequested: false,
        loginSuccess: false,
      };
    case 'REQUEST_LOGOUT':
      return {
        ...state,
        logoutRequested: true,
        errorMessage: '',
        logoutError: false,
      };
    case 'SUCCESS_LOGOUT':
      return {
        ...state,
        loginSuccess: false,
        loginRequested: false,
        logoutRequested: false,
        logoutSuccess: true,
      };
    case 'ERROR_LOGOUT':
      return {
        ...state,
        logoutError: true,
        errorMessage: action.payload,
        logoutRequested: false,
        logoutSuccess: false,
      };
    case 'REQUEST_LOGIN_BY_TOKEN':
      return {
        ...state,
        loginRequested: true,
        errorMessage: '',
        loginError: false,
      };
    case 'SUCCESS_LOGIN_BY_TOKEN':
      return {
        ...state,
        loginSuccess: true,
        loginRequested: false,
        userData: action.payload,
      };
    case 'FAILED_LOGIN_BY_TOKEN':
      return {
        ...state,
        loginRequested: false,
        loginSuccess: false,
      };
    case 'GEOLOCATION_REQUEST':
      return {
        ...state,
        geolocationRequested: true,
      };
    case 'GEOLOCATION_SUCCESS':
      return {
        ...state,
        geolocationRequested: false,
        geolocationCurrent: action.payload,
      };
    case 'GEOLOCATION_CANCELED':
      return {
        ...state,
        geolocationRequested: false,
        geolocationCurrent: action.payload,
      };
    case 'SET_REGION':
      return {
        ...state,
        geolocationCurrent: action.payload,
      };
    default:
      return state;
  }
};
