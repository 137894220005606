import React, { forwardRef } from 'react';

const NumberInput = forwardRef(({
  value,
  onChange,
  ...props
},
ref) => {
  const updateValue = (event) => {
    if (event.target.validity.valid) {
      if (event.target.value === '') {
        onChange(null);
      } else {
        onChange(parseInt(event.target.value, 10));
      }
    }
  };

  return (
    <input
      ref={ref}
      value={value === null ? '' : value}
      pattern="[0-9]*"
      onChange={updateValue}
      {...props}
    />
  );
});

export default NumberInput;
