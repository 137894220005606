import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';

import MyLocation from '../../MyLocation';
import PagesAPI from '../../services/pagesAPI';
import Card from '../../components/common/Offer/Card';

const pagesApi = new PagesAPI();

const Offer = () => {
  const [cardsLoading, setCardsLoading] = useState(true);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    pagesApi.getSpecialOffers().then(res => {
      setCardsLoading(false);
      setCards(res.data.results);
    });
  }, []);

  return (
    <>
      <Helmet title="Сезонные предложения" />
      <div className="w-content">
        <div className="inner offer">
          <div className="container">
            <h1 className="h1">Сезонные предложения</h1>
            <p className="offer__description">
              {` Праздники или другие важные события (например, Новый год, Пасха
                и т. д.
                сопровождаются повышенным спросом на тематические товары и услуги. Чтобы удовлетворить
                спрос покупателей и предоставить им максимально удобный сервис, «Пятёрочка» предлагает
                наиболее выгодные условия для работы в этот период профильным партнёрам.`}
            </p>
            <div className="offer__wrap">
              <div className="offer__list">
                {cardsLoading ? (
                  <div className="container">Загружаю...</div>
                ) : cards?.map(card => (
                  <Card
                    key={card.id}
                    title={card.title}
                    description={card.description}
                    actionPeriod={card.action_period}
                    image={card.image}
                  />
                ))}
              </div>
            </div>

            <hr className="hr hr--big" />
          </div>
        </div>
      </div>
      <MyLocation />
    </>
  );
};

export default Offer;
