import React, { useState } from 'react';
import cx from 'classnames';
import Helmet from 'react-helmet';
import queryString from 'query-string';
import AuthAPI from '../../services/authAPI';
import Input from '../../components/ui/Form/Input';
import FormField from '../../components/ui/Form/FormField';

const authAPI = new AuthAPI();

const ChangePassword = ({ location }) => {
  const parsed = queryString.parse(location.search);

  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [newPassword1Validation, setNewPassword1Validation] = useState(false);
  const [newPassword2Validation, setNewPassword2Validation] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const onChangePassword1 = ({ target }) => {
    // eslint-disable-next-line no-useless-escape
    const password = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])[a-zA-Z\d!@#\$%\^&\*]{8,}$/;
    setNewPassword1(target.value);
    setNewPassword1Validation(target.value.search(password) !== -1);
  };

  const onChangePassword2 = ({ target }) => {
    // eslint-disable-next-line no-useless-escape
    const password = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])[a-zA-Z\d!@#\$%\^&\*]{8,}$/;
    setNewPassword2(target.value);
    setNewPassword2Validation(target.value.search(password) !== -1);
  };

  const sendData = () => {
    if (!newPassword1Validation) {
      setError('Пароли не удовлетворяют требованиям');
      return;
    }

    if (!newPassword2Validation) {
      setError('Пароли не удовлетворяют не совпадают.');
      return;
    }

    const { token, uid } = parsed;

    authAPI
      .passwordChange(newPassword1, newPassword2, uid, token)
      .then(res => setSuccess(Object.values(res.data)))
      .catch(() => setError('Произошла ошибка. Попробуйте позже.'));
  };

  return (
    <>
      <Helmet title="Смена пароля" />
      <div className="w-content">
        <div className="inner terms">
          <div className="container">
            <h1 className="h1 h1--mb30">Смена пароля</h1>
          </div>
          <div style={{ marginBottom: 200 }} className="container">
            <FormField
              className={cx({ 'form-field--ok': newPassword1Validation })}
              tip="Пароль должен содержать не менее 8 символов: не менее 1 цифры, не менее 1 буквы
                    в верхнем регистре, не менее 1 буквы в нижнем регистре, не менее 1 спецсимвола (!@#$%^&*)"
            >
              <Input
                className={cx({
                  'vf-error': newPassword1Validation === false,
                })}
                type="password"
                placeholder="Пароль"
                value={newPassword1}
                onChange={onChangePassword1}
                name="newPassword1"
              />
            </FormField>
            <FormField className={cx({ 'form-field--ok': newPassword2Validation })}>
              <Input
                className={cx({
                  'vf-error': newPassword2Validation === false,
                })}
                type="password"
                placeholder="Подтверждение пароля"
                value={newPassword2}
                onChange={onChangePassword2}
                name="newPassword2"
              />
            </FormField>

            <button
              style={{ marginRight: 15 }}
              type="button"
              onClick={sendData}
              className="i-button i-button--270"
            >
              Изменить пароль
            </button>
            {success || error}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
