import React from 'react';
import './PrivatePolicyLink.scss';
import { privacyPolicyLink } from '../../../../shared/constants';

export default function PrivatePolicyLink({ children }) {
  return (
    <a
      className="private-policy-link"
      href={privacyPolicyLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
}
