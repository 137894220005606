export const OrderingType = {
  baseRateAscending: 'base_rate',
  baseRateDescending: '-base_rate',
};

export function getDefaultSearchFilters() {
  return {
    page: 1,
    shop: [],
    geolocation: [],
    geolocationName: '',
    pageSize: 15,
    baseRateRange: {
      min: null,
      max: null,
    },
    areaRange: {
      min: null,
      max: null,
    },
    categories: [],
    shopTypes: [],
    occupancyStatuses: [],
    regions: [],
    ordering: OrderingType.baseRateAscending,
  };
}

export function getDefaultGroupSearchFilters() {
  return {
    page: 1,
    shop: [],
    geolocation: [],
    geolocationName: '',
    pageSize: 15,
    baseRateRange: {
      min: null,
      max: null,
    },
    averageAreaRange: {
      min: null,
      max: null,
    },
    groupSizeRange: {
      min: null,
      max: null,
    },
    ordering: OrderingType.baseRateAscending,
  };
}

export function getDefaultSearchBounds() {
  return {
    foundItemsAmount: 0,
    totalItemsAmount: 0,
    areaRange: {
      min: 0,
      max: 0,
    },
    baseRateRange: {
      min: 0,
      max: 0,
    },
  };
}

export function getDefaultGroupSearchBounds() {
  return {
    foundItemsAmount: 0,
    totalItemsAmount: 0,
    averageAreaRange: {
      min: 0,
      max: 0,
    },
    baseRateRange: {
      min: 0,
      max: 0,
    },
    groupSizeRange: {
      min: 0,
      max: 0,
    },
  };
}

export function getDefaultSearchFiltersData() {
  return {
    availableRegions: [],
    availableCategories: [],
    availableShopTypes: [],
    availableOccupancyStatuses: [],
  };
}
