import React, { useState } from 'react';
import cx from 'classnames';
import FiltersCheckbox from '../../common/Find/Filters/FiltersCheckbox';

const SecondaryFilterWrapper = ({
  title,
  children,
  closed,
  toggleAll,
  allChecked,
}) => {
  const [innerClosed, setInnerClosed] = useState(closed);
  const [checked, setChecked] = useState(allChecked);

  const innerToggle = () => {
    setInnerClosed(!innerClosed);
  };

  const toggleChecked = () => {
    if (checked && !allChecked) {
      setChecked(false);
      toggleAll(false);
    } else if (!checked && allChecked) {
      setChecked(true);
      toggleAll(true);
    } else {
      setChecked(!checked);
      toggleAll(checked);
    }
  };

  return (
    <div className="secondaryFilter">
      <div
        className={cx('secondaryFilter__head')}
      >
        <FiltersCheckbox
          checked={allChecked}
          onChange={toggleChecked}
        />
        <div
          tabIndex="0"
          role="button"
          onKeyDown={innerToggle}
          className={cx('secondaryFilter__head-title', { 'is-open': !innerClosed })}
          onClick={innerToggle}
        >
          {title}
        </div>
      </div>

      <div style={{ display: innerClosed ? 'none' : 'block' }} className="filter__body js-filterBody">
        <div className="secondaryFilter__body-inner">{children}</div>
      </div>
    </div>
  );
};

export default SecondaryFilterWrapper;
