import React, { Component } from 'react';
import cx from 'classnames';
import { Route, Switch, withRouter } from 'react-router-dom';
import Header from '../../components/common/Find/Results/ResultsHeader';
import BigMap from './Map';
import Results from './Results';
import GroupResults from './GroupResults';
import GroupItem from '../../components/common/Find/GroupItem';
import Item from '../../components/common/Find/Item';

class FindPage extends Component {
  state = {};

  render() {
    const { location } = this.props;
    return (
      <div className="w-content">
        <div className="inner find">
          {(location.pathname === '/find/'
            || location.pathname === '/find'
            || location.pathname.includes('map')
            || location.pathname === '/find/group'
            || location.pathname === '/find/group/') && (
            <div className={cx('container')}>
              <Header />
            </div>
          )}
          <Switch>
            <Route path="/find/" component={Results} exact />
            <Route path="/find/group/" component={GroupResults} exact />
            <Route path="/find/map/" component={BigMap} exact />
            <Route path="/find/:id/" component={Item} exact />
            <Route path="/find/group/:id/" component={GroupItem} exact />
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(FindPage);
