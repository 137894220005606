import React from 'react';

const Svg = () => (
  <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.18419 8.6305C1.26727 9.87926 0 12.0416 0 14.5V16.5H14V14.5C14 12.0416 12.7327 9.87926 10.8158 8.6305C9.77877 9.48607 8.4494 10 7 10C5.5506 10 4.22123 9.48607 3.18419 8.6305Z"
      fill="#E0E0E0"
    />
    <path
      d="M11 4C11 6.20914 9.20914 8 7 8C4.79086 8 3 6.20914 3 4C3 1.79086 4.79086 0 7 0C9.20914 0 11 1.79086 11 4Z"
      fill="#E0E0E0"
    />
  </svg>
);

export default Svg;
