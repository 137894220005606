import React, { useReducer, useRef } from 'react';
import Helmet from 'react-helmet';

import cx from 'classnames';
import AuthAPI from '../../services/authAPI';
import FormField from '../../components/ui/Form/FormField';
import Input from '../../components/ui/Form/Input';
import CustomCaptcha from '../../components/ui/CustomCaptcha/CustomCaptcha';

const authAPI = new AuthAPI();

const reducer = (state, action) => {
  switch (action.type) {
    case 'email':
      return { ...state, email: action.payload };
    case 'emailValidation':
      return { ...state, emailValidation: action.payload };
    case 'captchaToken':
      return { ...state, captchaToken: action.payload };
    case 'success':
      return { ...state, success: action.payload };
    case 'error':
      return { ...state, error: action.payload };
    default:
      throw new Error('No current field on state');
  }
};

const Reset = () => {
  const captchaRef = useRef();
  const [state, dispatchState] = useReducer(reducer, {
    email: '',
    emailValidation: '',
    captchaToken: '',
    success: false,
  });

  const updateState = (type, value) => {
    dispatchState({ type, payload: value });
  };

  const onChange = ({ target }) => {
    const email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    dispatchState({ type: 'email', payload: target.value });
    dispatchState({ type: 'emailValidation', payload: target.value.search(email) !== -1 });
  };

  const submit = async () => {
    await captchaRef.current.verify();
  };

  const sendData = (captchaToken) => {
    const { email } = state;

    authAPI
      .passwordReset(email, captchaToken)
      .then(res => updateState('success', Object.values(res.data)))
      .catch(res => updateState('error', Object.values(res.response.data)));
  };

  const handleSuccessfulCaptchaValidation = (captchaToken) => {
    Promise.resolve()
      .then(() => state.emailValidation === false)
      .then(() => sendData(captchaToken))
      .catch(console.error);
  };

  const {
    success, email, emailValidation, error,
  } = state;
  return (
    <div className="w-content">
      <Helmet title="Восстановление пароля" />
      <div className="inner terms">
        <div className="container">
          <h1 className="h1 h1--mb30">Восстановление пароля</h1>
        </div>
        <div style={{ marginBottom: 200 }} className="container">
          <FormField className={cx({ 'form-field--ok': emailValidation })}>
            <Input
              className={cx({
                'vf-error': emailValidation === false,
              })}
              value={email}
              onChange={onChange}
              name="email"
              placeholder="E-mail"
            />
          </FormField>
          <FormField>
            <CustomCaptcha
              ref={captchaRef}
              onSuccess={handleSuccessfulCaptchaValidation}
            />
          </FormField>

          <button
            style={{ marginRight: 15 }}
            type="button"
            onClick={submit}
            className="i-button i-button--270"
          >
            Сохранить и отправить
          </button>
          {success || error}
        </div>
      </div>
    </div>
  );
};

export default Reset;
