import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import cx from 'classnames';

class Header extends Component {
  state = {};

  render() {
    const { location } = this.props;
    return (
      <>
        <h1 className="h1 find__h1">Субаренда</h1>
        <div
          className={cx('find__nav', {
            'find__nav--onmap': location.pathname.includes('/find/map'),
          })}
        >
          <Link
            to="/find?applyCurrentLocation=1"
            className={cx('find__nav-item', {
              'is-current': !location.pathname.includes('/find/group'),
            })}
          >
            Свободные площади
          </Link>
          <Link
            to="/find/group?applyCurrentLocation=1"
            className={cx('find__nav-item', {
              'is-current': location.pathname.includes('/find/group'),
            })}
          >
            Группы площадей
          </Link>
          {location.pathname === '/find/map' && (
            <div className="find__nav-tolist">
              <Link to="/find" className="i-button i-button--outline">
                Списком
              </Link>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(Header);
