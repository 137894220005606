import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import formatCurrency from '../../../../../shared/utils/formatCurrency';
import CardMedia from './Media';
import CardMediaFallback from './Media/Fallback';

export default function Card({
  id,
  address,
  photos,
  area,
  askoId,
  askoToDate,
  rate,
  leaseTerm,
  description,
  shopType,
  occupancyStatus,
}) {
  return (
    <div className="card">
      <div className="card__row">
        <div className="card__left">
          {photos.length > 0
            ? <CardMedia sources={photos} />
            : <CardMediaFallback />}
        </div>
        <div className="card__right">
          <div className="card__right-inner">
            <div className="card__header">
              <h2 className="card__name">
                <Link target="_blank" rel="noopener noreferrer" to={`/find/${id}`}>
                  {address}
                </Link>
              </h2>
              <div className="card__id">
                <div className="card__id-num">№ {askoId}</div>
                <div className="card__id-name">{shopType}</div>
              </div>
            </div>
            <div className="card__features">
              <div className="card__features-item">
                <div className="card__features-lines">
                  <div className="card__features-val">
                    {area} <small> м²</small>
                  </div>
                </div>
              </div>
              <div className="card__features-item">
                <div className="card__features-lines">
                  <div className="card__features-val">
                    <small>от </small>
                    {formatCurrency(rate)}
                    <small> ₽/мес</small>
                  </div>
                  <div className="card__features-notice">{formatCurrency(rate / area)} ₽/м²</div>
                </div>
              </div>
              <div className="card__features-item">
                <div className="card__features-lines">
                  <div className="card__features-val">
                    {leaseTerm} <small> мес</small>
                  </div>
                  <div className="card__features-notice">с возможной пролонгацией</div>
                </div>
              </div>
            </div>
            <div className="card__description">{description}</div>
            {occupancyStatus !== 'Свободна'
              ? (
                <div className="card__free">
                  Освобождается: {format(askoToDate, 'D MMMM', { locale: ru })}
                </div>
              )
              : ''
            }
          </div>
        </div>
      </div>
    </div>
  );
}
