const INITIAL_STATE = {
  registerRequested: false,
  registerError: false,
  errorMessage: '',
  registerSuccess: false,
  dropdownData: undefined,
  dropdownDataRequested: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'REQUEST_REGISTER':
      return {
        ...state,
        registerRequested: true,
        errorMessage: '',
        registerError: false,
      };
    case 'SUCCESS_REGISTER':
      return { ...state, registerSuccess: true, registerRequested: false };
    case 'ERROR_REGISTER':
      return {
        ...state,
        registerError: true,
        errorMessage: action.payload,
        registerRequested: false,
        registerSuccess: false,
      };
    case 'REQUEST_DROPDOWN_DATA':
      return { ...state, dropdownDataRequested: true };
    case 'DROPDOWN_DATA_RECEIVED':
      return { ...state, dropdownData: action.payload, dropdownDataRequested: false };
    default:
      return state;
  }
};
