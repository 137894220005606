import React, {
  Fragment, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import Helmet from 'react-helmet';
import { HashLink } from 'react-router-hash-link';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import Filters from '../../../components/common/Find/Filters/Filters';
import NoResult from '../../../components/common/Find/Results/NoResult';
import Pagination from '../../../components/common/Find/Results/Pagination';
import {
  createGroupSearchFiltersFromQueryParams,
  createGroupSearchFiltersQueryParams, Region, RegionType, useShouldApplyCurrentLocation,
} from '../../../components/common/Find/Filters/utils';
import {
  mapGroupSearchBoundsTransferObjectToGroupSearchBounds,
  mapGroupSearchFiltersToFiltersTransferObject,
} from '../../../components/common/Find/Filters/mappers';
import SpacesAPI from '../../../services/spacesAPI';
import {
  getDefaultGroupSearchBounds,
  getDefaultGroupSearchFilters,
} from '../../../components/common/Find/Filters/constants';
import GroupFiltersAside from '../../../components/common/Find/Filters/FiltersGroupAside';
import Card from '../../../components/common/Find/GroupResults/GroupResultsCard';
import OrganizationAPI from '../../../services/organizationAPI';

const spacesAPI = new SpacesAPI();
const organizationAPI = new OrganizationAPI();

function GroupResults(props) {
  const { currentRegion } = props;
  const shouldApplyCurrentLocation = useShouldApplyCurrentLocation(window.location.search);
  const [searchFilters, setSearchFilters] = useState(
    createGroupSearchFiltersFromQueryParams(window.location.search),
  );

  const [selectedRegion, setSelectedRegion] = useState(null);
  const [resultsLoading, setResultsLoading] = useState(true);
  const [results, setResults] = useState([]);
  const [searchBounds, setSearchBounds] = useState(getDefaultGroupSearchBounds());
  const [isSearchAllowed, setIsSearchAllowed] = useState(false);
  const isDataLoadedRef = useRef(false);

  const fetchSearchBounds = async () => spacesAPI.getGroupsByFilters(
    mapGroupSearchFiltersToFiltersTransferObject(getDefaultGroupSearchFilters()),
  ).then(data => {
    setSearchBounds(mapGroupSearchBoundsTransferObjectToGroupSearchBounds(data, true));
  });

  const fetchCurrentAddress = async () => {
    if (!shouldApplyCurrentLocation) return;

    if ('id' in currentRegion && 'name' in currentRegion) {
      setSelectedRegion(new Region({
        geo_id: currentRegion.id,
        geo_name: currentRegion.name,
      }));
    } else {
      const geolocation = navigator.geolocation
        ? await new Promise(resolve => {
          navigator.geolocation.getCurrentPosition(position => {
            resolve(position.coords);
          }, () => {
            resolve(null);
          });
        })
        : null;

      const address = await organizationAPI.getCurrentAddress(geolocation);

      setSelectedRegion(new Region(address));
    }
  };

  const fetchSelectedAddresses = async (geolocationIds, shopIds) => {
    if (!geolocationIds.length && !shopIds.length) {
      if (!shouldApplyCurrentLocation) return;

      await fetchCurrentAddress();
    } else {
      const addresses = await organizationAPI.getAddressesByIds({
        geolocationIds,
        shopIds,
      });

      if (addresses.length) {
        setSelectedRegion(new Region(addresses[0]));
      }
    }
  };

  const setOrdering = orderingType => {
    setSearchFilters((prevState) => ({
      ...prevState,
      ordering: orderingType,
      page: 1,
    }));
  };

  const setPageSize = pageSize => {
    setSearchFilters((prevState) => ({
      ...prevState,
      pageSize,
      page: 1,
    }));
  };

  const setPage = page => {
    setSearchFilters((prevState) => ({
      ...prevState,
      page,
    }));
  };

  const search = useCallback(() => {
    setResultsLoading(true);

    spacesAPI.getGroupsByFilters(
      mapGroupSearchFiltersToFiltersTransferObject(searchFilters),
    )
      .then(data => {
        setResults(data.results);
        setResultsLoading(false);
        setSearchBounds(prevState => ({
          ...prevState,
          foundItemsAmount: data.count,
        }));
      });
  }, [searchFilters]);

  const reset = () => {
    setSearchFilters(getDefaultGroupSearchFilters());
    setSelectedRegion(null);
  };

  const applyFiltersAside = filters => {
    setSearchFilters(prevState => ({
      ...prevState,
      ...filters,
      page: 1,
    }));
  };

  const setGeolocationName = geolocationName => {
    setSearchFilters((prevState) => ({
      ...prevState,
      geolocationName,
      page: 1,
    }));
    setSelectedRegion(null);
  };

  const paginationSize = useMemo(() => (
    Math.max(Math.ceil(searchBounds.foundItemsAmount / searchFilters.pageSize), 1)
  ), [searchBounds, searchFilters]);

  useEffect(() => {
    const { geolocation, shop } = searchFilters;

    Promise.all([
      fetchSearchBounds(),
      fetchCurrentAddress(),
      fetchSelectedAddresses(geolocation, shop),
    ]).then(() => {
      isDataLoadedRef.current = true;
      setIsSearchAllowed(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSearchAllowed) {
      window.history.replaceState(
        '',
        '',
        `?${createGroupSearchFiltersQueryParams(searchFilters)}`,
      );

      search();
    }
  }, [searchFilters, isSearchAllowed, search]);

  useEffect(() => {
    setSearchFilters((prevState) => ({
      ...prevState,
      shop: selectedRegion && selectedRegion.type === RegionType.Shop
        ? [selectedRegion.id]
        : null,
      geolocation: selectedRegion && selectedRegion.type === RegionType.Geolocation
        ? [selectedRegion.id]
        : null,
      geolocationName: selectedRegion ? '' : prevState.geolocationName,
      page: isDataLoadedRef.current ? 1 : prevState.page,
    }));
  }, [selectedRegion]);

  const previousCurrentRegionRef = useRef(currentRegion);

  useEffect(() => {
    if (isEmpty(previousCurrentRegionRef.current)) {
      previousCurrentRegionRef.current = currentRegion;
      return;
    }

    if (isEmpty(currentRegion) || !isDataLoadedRef.current) return;

    setSelectedRegion(new Region({
      geo_id: currentRegion.id,
      geo_name: currentRegion.name,
    }));
  }, [currentRegion]);

  return (
    <Fragment>
      <Helmet title="Свободные площади" />

      <div className="container">
        <Filters
          ordering={searchFilters.ordering}
          pageSize={searchFilters.pageSize}
          selectedRegion={selectedRegion}
          geolocationName={searchFilters.geolocationName}
          onChangeOrdering={setOrdering}
          onChangePageSize={setPageSize}
          onChangeSelectedRegion={setSelectedRegion}
          onChangeGeolocationName={setGeolocationName}
          onSearch={search}
          totalItemsAmount={searchBounds.totalItemsAmount}
          foundItemsAmount={searchBounds.foundItemsAmount}
        />
        <div className="find__wrap">
          <div className="find__sidebar">
            <div className="find__sidebar-notice">
              Цены указаны с НДС, являются <br />
              предварительными и могут быть изменены
            </div>
            <GroupFiltersAside
              groupSizeBounds={searchBounds.groupSizeRange}
              averageAreaBounds={searchBounds.averageAreaRange}
              baseRateBounds={searchBounds.baseRateRange}
              values={searchFilters}
              onReset={reset}
              onApply={applyFiltersAside}
            />
          </div>
          <div className="find__right">
            {resultsLoading && 'Загружаю...'}
            {!results.length && !resultsLoading && <NoResult reset={reset} />}
            {!resultsLoading && (
              <React.Fragment>
                {results.map(el => (
                  <Card
                    key={el.id}
                    id={el.id}
                    name={el.name}
                    totalArea={el.total_area}
                    askoId={el.asko_id}
                    rate={el.base_rate}
                    space={el.space_amount}
                    description={el.short_desc}
                    leaseTerm={el.lease_term}
                    regions={el.regions}
                  />
                ))}
                <Pagination
                  currentPage={searchFilters.page}
                  size={paginationSize}
                  onChange={setPage}
                />
              </React.Fragment>
            )}

            <div className="find__city">
              <div className="find__city-inner">
                <p>
                  По предложениям аренды площадей вне торговых точек (магазинов) просьба
                  обращаться к ответственному сотруднику в регионе.
                </p>
                <HashLink to="/terms#contacts-my-region">Смотрите контакты сотрудников</HashLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = state => ({
  currentRegion: state.login.geolocationCurrent,
});

export default connect(mapStateToProps)(GroupResults);
