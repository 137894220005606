import React from 'react';
import InputMask from 'react-input-mask';

import cx from 'classnames';

const NameInput = ({
  value, className, required, name, onChange, disabled, placeholder, id,
}) => (
  <InputMask
    id={id}
    value={value}
    onChange={onChange}
    className={cx('i-input', className)}
    mask="aaaaaaaaaaaaaaaaaaaaaaaaaaaa"
    placeholder={placeholder}
    name={name}
    required={required}
    maskChar=""
    disabled={disabled}
    formatChars={{
      9: '[0-9]',
      // eslint-disable-next-line no-useless-escape
      a: '[А-яа-яA-za-z, \s]',
      '*': '[A-Za-z0-9]',
    }}
  />
);
export default NameInput;
