import React, { Component } from 'react';
import { connect } from 'react-redux';

import Portal from './shared/utils/Portal';
import { Question } from './components/modals/Question';

import PagesAPI from './services/pagesAPI';

class MyLocation extends Component {
  pagesAPI = new PagesAPI();

  state = { modalOpened: false, contacts: [], contactsLoading: true };

  componentDidMount() {
    const { loginData } = this.props;
    if (loginData.geolocationCurrent.id) {
      this.setContacts();
    }
  }

  componentDidUpdate(prevProps) {
    const { loginData } = this.props;
    if (prevProps.loginData.geolocationCurrent.name !== loginData.geolocationCurrent.name) this.setContacts();
  }

  setContacts = () => {
    const { loginData } = this.props;
    this.pagesAPI
      .getContacts(loginData.geolocationCurrent.id)
      .then(res => this.setState({ contacts: res.data.results, contactsLoading: false }));
  };

  togglemodal = () => {
    this.setState(prevState => ({ modalOpened: !prevState.modalOpened }));
  };

  render() {
    const { modalOpened, contacts, contactsLoading } = this.state;
    const { loginData } = this.props;
    return (
      <>
        <div className="my_location">
          <div className="container">
            <h3 className="h3" id="contacts-my-region">
              Контакты на моей территории
              <span className="my_location__city">
                {!loginData.geolocationRequested && loginData.geolocationCurrent.name}
              </span>
            </h3>
            <div className="my_location__row">
              {contactsLoading && 'Загружаю...'}
              {!contactsLoading
                && contacts.map(el => (
                  <div key={el.id} className="my_location__col">
                    <div className="my_location__place">{loginData.geolocationCurrent.name}</div>
                    <span className="my_location__position">{el.position}</span>
                    <div className="my_location__name">{el.name}</div>
                    <a href={`tel:${el.phone}`} className="my_location__phone">
                      {el.phone}
                    </a>
                    <a href={`mailto:${el.email}`} className="my_location__email">
                      {el.email}
                    </a>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="my_callback">
          <div className="my_callback__text">
            <b>Есть вопросы? Задайте их через форму обратной связи</b> Обращение будет направлено в
            региональный офис в{' '}
            {!loginData.geolocationRequested && loginData.geolocationCurrent.name}
          </div>
          <div
            onClick={this.togglemodal}
            onKeyPress={e => e.keyCode === 13 && this.togglemodal(e)}
            role="button"
            tabIndex="0"
            className="my_callback__btn"
          >
            <span className="i-button i-button--outline my_callback__btn-button">
              Форма обратной связи
            </span>
          </div>
        </div>
        {modalOpened && (
          <Portal>
            <Question close={this.togglemodal} />
          </Portal>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  loginData: state.login,
});

export default connect(mapStateToProps)(MyLocation);
