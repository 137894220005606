import React from 'react';
import cx from 'classnames';
import InputMask from 'react-input-mask';
import FormField from '../../../ui/Form/FormField';
import NameInput from '../../../ui/Form/NameInput';
import DatePicker from '../../../ui/Form/DatePicker';
import Input from '../../../ui/Form/Input';
import Textarea from '../../../ui/Form/Textarea';
import { getBlockEnterKeyHandler } from '../../../../shared/utils/getBlockEnterKeyHandler';

const IndividualBeneficiariesItem = ({ beneficiary, profileActions, invalidField }) => (
  <div key={beneficiary.id} className="lk-benef" id="tmpBenF">
    <div className="lk-benef__item">
      <h4 className="form-h4">
        Конечный бенефициар (физическое лицо)
        <span
          className="lk-benef__remove"
          role="button"
          tabIndex="0"
          onClick={() => profileActions.removeIndividualBeneficiary(beneficiary.id)}
          onKeyDown={getBlockEnterKeyHandler(
            () => profileActions.removeIndividualBeneficiary(beneficiary.id),
          )}
        >
          Удалить блок
        </span>
      </h4>
      <div className="form-group">
        <FormField>
          <NameInput
            id={beneficiary.id}
            className={cx({
              'vf-error': invalidField
                  && invalidField.lastName,
            })}
            value={beneficiary.lastName}
            onChange={profileActions.setIndividualBeneficiariesLastName}
            placeholder="Фамилия"
          />
        </FormField>
        <FormField>
          <NameInput
            id={beneficiary.id}
            className={cx({
              'vf-error': invalidField
                  && invalidField.firstName,
            })}
            value={beneficiary.firstName}
            onChange={profileActions.setIndividualBeneficiariesFirstName}
            placeholder="Имя"
          />
        </FormField>
        <FormField>
          <NameInput
            id={beneficiary.id}
            className={cx({
              'vf-error': invalidField
                  && invalidField.middleName,
            })}
            value={beneficiary.middleName}
            onChange={profileActions.setIndividualBeneficiariesMiddleName}
            placeholder="Отчество"
          />
        </FormField>
        <FormField label="Дата рождения">
          <DatePicker
            error={invalidField
                && invalidField.birthDate}
            currentDate={beneficiary.birthDate}
            handleChange={value => profileActions.setIndividualBeneficiariesBirthDate(value, beneficiary.id)}
          />
        </FormField>
        <div className="form-line">
          <span className="form-label">Паспортные данные</span>

          <div className="form-line__row">
            <div className="form-field">
              <InputMask
                className={cx('i-input', {
                  'vf-error': invalidField
                      && invalidField.passport.series,
                })}
                value={beneficiary.passport.series}
                id={beneficiary.id}
                onChange={profileActions.setIndividualBeneficiariesPassportSeries}
                mask="9999"
                maskChar={null}
                placeholder="Серия"
              />
            </div>
            <div
              className="form-field"
            >
              <InputMask
                className={cx('i-input', {
                  'vf-error': invalidField
                      && invalidField.passport.number,
                })}
                value={beneficiary.passport.number}
                id={beneficiary.id}
                onChange={profileActions.setIndividualBeneficiariesPassportNumber}
                mask="999999"
                maskChar={null}
                placeholder="Номер"
              />
            </div>

          </div>
        </div>
        <FormField>
          <Input
            className={cx({
              'vf-error': invalidField
                  && invalidField.issuer,
            })}
            value={beneficiary.passport.issuer}
            id={beneficiary.id}
            onChange={profileActions.setIndividualBeneficiariesPassportIssuer}
            type="text"
            placeholder="Кем выдан"
          />
        </FormField>
        <FormField label="Дата выдачи">
          <DatePicker
            error={invalidField
                && invalidField.issueDate}
            currentDate={beneficiary.passport.issueDate}
            handleChange={value => profileActions.setIndividualBeneficiariesPassportIssueDate(value, beneficiary.id)}
          />
        </FormField>
        <FormField>
          <Textarea
            className={cx({
              'vf-error': invalidField
                  && invalidField.passport.registrationAddress,
            })}
            value={beneficiary.passport.registrationAddress}
            id={beneficiary.id}
            onChange={profileActions.setIndividualBeneficiariesPassportRegistrationAddress}
            placeholder="Адрес регистрации"
          />
        </FormField>
      </div>
    </div>
  </div>

);

export default IndividualBeneficiariesItem;
