import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import MainInfo from './MainInfo';
import OnMap from './OnMap';
import Similar from './Similar';
import Portal from '../../../../shared/utils/Portal';
import ItemRequest from '../../../modals/Request/Request';
import Login from '../../../modals/Login/Login';

import SpacesAPI from '../../../../services/spacesAPI';

class Item extends Component {
  spacesAPI = new SpacesAPI();

  state = {
    modalOpened: false,
    data: undefined,
    spaceLoading: true,
    suggestionsLoading: true,
  };

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.spacesAPI.getSpace(id).then(res => this.setState({ spaceLoading: false, data: res.data }));
    this.spacesAPI
      .getSuggestions(id)
      .then(res => this.setState({ suggestionsLoading: false, suggestions: res.data.results }));
  }

  toggleModal = () => {
    this.setState(prevState => ({ modalOpened: !prevState.modalOpened }));
  };

  afterRequestHook = () => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        request: true,
      },
    });
  };

  render() {
    const {
      modalOpened, suggestionsLoading, spaceLoading, data, suggestions,
    } = this.state;
    const { loginData } = this.props;
    if (spaceLoading || suggestionsLoading) {
      return (
        <div className="container">
          <h2 className="h2">Загружаю...</h2>
        </div>
      );
    }
    return (
      <>
        <Helmet>
          <title>{data ? data.address : 'Просмотр объекта'}</title>
        </Helmet>
        <div className="container">
          <MainInfo data={data} toggleModal={this.toggleModal} />
          <hr className="hr hr--big" />
          <h2 className="h2 h2--mb30">На карте</h2>
        </div>
        <OnMap data={data} />
        <div className="container">
          {!suggestionsLoading && <Similar suggestions={suggestions} />}
        </div>
        {modalOpened && (
          <Portal>
            {loginData.loginSuccess ? (
              <ItemRequest
                afterRequestHook={this.afterRequestHook}
                data={data}
                close={this.toggleModal}
              />
            ) : (
              <Login noRedirect close={this.toggleModal} />
            )}
          </Portal>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  loginData: state.login,
});

export default connect(mapStateToProps)(Item);
