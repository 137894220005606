import React, {
  Fragment, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { HashLink } from 'react-router-hash-link';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  getDefaultSearchBounds,
  getDefaultSearchFilters,
  getDefaultSearchFiltersData,
} from '../../../components/common/Find/Filters/constants';
import Filters from '../../../components/common/Find/Filters/Filters';
import FiltersAside from '../../../components/common/Find/Filters/FiltersAside';
import NoResult from '../../../components/common/Find/Results/NoResult';
import CardList from '../../../components/common/Find/Results/CartList';
import Pagination from '../../../components/common/Find/Results/Pagination';
import SpacesAPI from '../../../services/spacesAPI';
import {
  mapSearchBoundsTransferObjectToSearchBounds,
  mapSearchFiltersToFiltersTransferObject,
} from '../../../components/common/Find/Filters/mappers';
import {
  createSearchFiltersFromQueryParams,
  createSearchFiltersQueryParams, Region, RegionType,
  useShouldApplyCurrentLocation,
} from '../../../components/common/Find/Filters/utils';
import OrganizationAPI from '../../../services/organizationAPI';

const spacesAPI = new SpacesAPI();
const organizationAPI = new OrganizationAPI();

function Results(props) {
  const { currentRegion } = props;
  const shouldApplyCurrentLocation = useShouldApplyCurrentLocation(window.location.search);
  const [searchFilters, setSearchFilters] = useState(
    createSearchFiltersFromQueryParams(window.location.search),
  );
  const [searchFiltersData, setSearchFiltersData] = useState(getDefaultSearchFiltersData());
  const [searchBounds, setSearchBounds] = useState(getDefaultSearchBounds());
  const [results, setResults] = useState([]);
  const [resultsLoading, setResultsLoading] = useState(true);
  const [filtersLoading, setFiltersLoading] = useState(true);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [isSearchAllowed, setIsSearchAllowed] = useState(false);
  const isDataLoadedRef = useRef(false);

  const fetchAvailableFilters = async () => {
    const response = await spacesAPI.getFilters();

    setSearchFiltersData(prevState => ({
      ...prevState,
      availableCategoriesWithoutGroups: response.data.category_without_groups,
      availableCategoriesWithGroups: response.data.category_groups,
      // availableShopTypes: response.data.type,
      // availableOccupancyStatuses: response.data.occupancy_status,
    }));

    setFiltersLoading(false);
  };

  const fetchSearchBounds = async () => spacesAPI.getSpacesByFilters(
    mapSearchFiltersToFiltersTransferObject(getDefaultSearchFilters()),
  ).then(data => {
    setSearchBounds(mapSearchBoundsTransferObjectToSearchBounds(data));
  });

  const fetchCurrentAddress = async () => {
    if (!shouldApplyCurrentLocation) return;

    if ('id' in currentRegion && 'name' in currentRegion) {
      setSelectedRegion(new Region({
        geo_id: currentRegion.id,
        geo_name: currentRegion.name,
      }));
    } else {
      const geolocation = navigator.geolocation
        ? await new Promise(resolve => {
          navigator.geolocation.getCurrentPosition(position => {
            resolve(position.coords);
          }, () => {
            resolve(null);
          });
        })
        : null;

      const address = await organizationAPI.getCurrentAddress(geolocation);

      setSelectedRegion(new Region(address));
    }
  };

  const fetchSelectedAddress = async (geolocationIds, shopIds) => {
    if (!geolocationIds.length && !shopIds.length) {
      if (!shouldApplyCurrentLocation) return;

      await fetchCurrentAddress();
    } else {
      const addresses = await organizationAPI.getAddressesByIds({
        geolocationIds,
        shopIds,
      });

      if (addresses.length) {
        setSelectedRegion(new Region(addresses[0]));
      }
    }
  };

  const search = useCallback(() => {
    setResultsLoading(true);

    spacesAPI.getSpacesByFilters(
      mapSearchFiltersToFiltersTransferObject(searchFilters),
    )
      .then(data => {
        setResults(data.results);
        setResultsLoading(false);
        setSearchBounds(prevState => ({
          ...prevState,
          foundItemsAmount: data.count,
        }));
      });
  }, [searchFilters]);

  const reset = () => {
    setSearchFilters(getDefaultSearchFilters());
    setSelectedRegion(null);
  };

  useEffect(() => {
    const { geolocation, shop } = searchFilters;

    Promise.all([
      fetchSelectedAddress(geolocation, shop),
      fetchAvailableFilters(),
      fetchSearchBounds(),
    ]).then(() => {
      isDataLoadedRef.current = true;
      setIsSearchAllowed(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSearchAllowed) {
      window.history.replaceState(
        '',
        '',
        `?${createSearchFiltersQueryParams(searchFilters)}`,
      );
      search();
    }
  }, [searchFilters, isSearchAllowed, search]);

  useEffect(() => {
    setSearchFilters((prevState) => ({
      ...prevState,
      shop: selectedRegion && selectedRegion.type === RegionType.Shop
        ? [selectedRegion.id]
        : null,
      geolocation: selectedRegion && selectedRegion.type === RegionType.Geolocation
        ? [selectedRegion.id]
        : null,
      geolocationName: selectedRegion ? '' : prevState.geolocationName,
      page: isDataLoadedRef.current ? 1 : prevState.page,
    }));
  }, [selectedRegion]);

  const previousCurrentRegionRef = useRef(currentRegion);

  useEffect(() => {
    if (isEmpty(previousCurrentRegionRef.current)) {
      previousCurrentRegionRef.current = currentRegion;
      return;
    }

    if (isEmpty(currentRegion) || !isDataLoadedRef.current) return;

    setSelectedRegion(new Region({
      geo_id: currentRegion.id,
      geo_name: currentRegion.name,
    }));
  }, [currentRegion]);

  const setOrdering = orderingType => {
    setSearchFilters((prevState) => ({
      ...prevState,
      ordering: orderingType,
      page: 1,
    }));
  };

  const setPageSize = pageSize => {
    setSearchFilters((prevState) => ({
      ...prevState,
      pageSize,
      page: 1,
    }));
  };

  const setPage = page => {
    setSearchFilters((prevState) => ({
      ...prevState,
      page,
    }));
  };

  const setGeolocationName = geolocationName => {
    setSearchFilters((prevState) => ({
      ...prevState,
      geolocationName,
      page: 1,
    }));
    setSelectedRegion(null);
  };

  const applyFiltersAside = filters => {
    setSearchFilters(prevState => ({
      ...prevState,
      ...filters,
      page: 1,
    }));
  };

  const paginationSize = useMemo(() => (
    Math.max(Math.ceil(searchBounds.foundItemsAmount / searchFilters.pageSize), 1)
  ), [searchBounds, searchFilters]);

  return (
    <Fragment>
      <Helmet title="Свободные площади" />

      <div className="container">
        <Filters
          ordering={searchFilters.ordering}
          pageSize={searchFilters.pageSize}
          selectedRegion={selectedRegion}
          geolocationName={searchFilters.geolocationName}
          onChangeOrdering={setOrdering}
          onChangePageSize={setPageSize}
          onChangeSelectedRegion={setSelectedRegion}
          onChangeGeolocationName={setGeolocationName}
          totalItemsAmount={searchBounds.totalItemsAmount}
          foundItemsAmount={searchBounds.foundItemsAmount}
          showMapLink
        />
        <div className="find__wrap">
          <div className="find__sidebar">
            <div className="find__sidebar-notice">
              Цены указаны с НДС, являются <br />
              предварительными и могут быть изменены
            </div>
            <FiltersAside
              filtersLoading={filtersLoading}
              areaBounds={searchBounds.areaRange}
              baseRateBounds={searchBounds.baseRateRange}
              availableCategories={searchFiltersData.availableCategories}
              availableCategoriesWithGroups={searchFiltersData.availableCategoriesWithGroups}
              availableCategoriesWithoutGroups={searchFiltersData.availableCategoriesWithoutGroups}
              values={searchFilters}
              onReset={reset}
              onApply={applyFiltersAside}
            />
          </div>
          <div className="find__right">
            {resultsLoading && 'Загружаю...'}
            {!results.length && !resultsLoading && <NoResult reset={reset} />}
            {!resultsLoading && (
            <React.Fragment>
              <CardList cards={results} />
              <Pagination
                currentPage={searchFilters.page}
                size={paginationSize}
                onChange={setPage}
              />
            </React.Fragment>
            )}

            <div className="find__city">
              <div className="find__city-inner">
                <p>
                  По предложениям аренды площадей вне торговых точек (магазинов) просьба
                  обращаться к ответственному сотруднику в регионе.
                </p>
                <HashLink to="/terms#contacts-my-region">Смотрите контакты сотрудников</HashLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = state => ({
  currentRegion: state.login.geolocationCurrent,
});

export default connect(mapStateToProps)(Results);
