const INITIAL_STATE = {
  currentRegions: [],
  isInitial: true,
  currentFilters: {},
  isFiltersInitial: true,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case 'SET_LOCATIONS':
      return {
        ...state,
        currentRegions: payload,
        isInitial: false,
      };
    case 'SET_FILTERS':
      return {
        ...state,
        currentFilters: payload,
        isFiltersInitial: false,
      };
    default:
      return state;
  }
};
