import React, { useMemo } from 'react';
import cx from 'classnames';

function createPageSequence(currentPage, size, radius) {
  const validatedSize = Math.max(size, 1);
  const validatedCurrentPage = Math.min(currentPage, validatedSize);

  const sequence = new Set();

  for (let page = validatedCurrentPage - radius; page <= validatedCurrentPage + radius; page += 1) {
    sequence.add(Math.min(Math.max(1, page), validatedSize));
  }

  return [...new Set([1, ...sequence, validatedSize])];
}

export default function Pagination({
  currentPage = 1,
  size = 1,
  onChange,
}) {
  const pages = useMemo(() => createPageSequence(currentPage, size, 2), [currentPage, size]);

  return (
    <div className="find__pagination">
      <div className="i-pagination">
        <button
          type="button"
          key="previous"
          className={cx('i-pagination__item i-pagination__item--prev', {
            'is-disabled': currentPage <= 1,
          })}
          onClick={() => onChange(currentPage - 1)}
        />

        {pages.map(page => (
          <button
            type="button"
            key={page}
            className={cx('i-pagination__item', { 'is-current': page === currentPage })}
            onClick={() => onChange(page)}
          >
            {page}
          </button>
        ))}

        <button
          type="button"
          key="next"
          className={cx('i-pagination__item i-pagination__item--next', {
            'is-disabled': currentPage >= size,
          })}
          onClick={() => onChange(currentPage + 1)}
        />
      </div>
    </div>
  );
}
