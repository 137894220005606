import React, { useState } from 'react';

const Radio = ({
  id,
  toggle,
  name,
  checked,
  children,
}) => {
  const [innerChecked, setInnerChecked] = useState(children);

  const innerToggle = () => setInnerChecked(!innerChecked);

  return (
    <div onClick={toggle || innerToggle} className="i-radio">
      <input onChange={innerToggle} id={id} type="radio" name={name} checked={checked} />
      <label htmlFor={id}>
        <span>{children}</span>
      </label>
    </div>
  );
};

export default Radio;
