import axios from 'axios';

function transformBase64ImageToURL(base64) {
  return `data:image;base64,${base64}`;
}

export default class CaptchaAPI {
    static apiBase = `${process.env.REACT_APP_API_BASE}/captcha`;

    static async getCaptcha() {
      const response = await axios.get(this.apiBase);

      return {
        imageURL: transformBase64ImageToURL(response.data.base_64_image),
        uuid: response.data.uuid,
      };
    }

    static async verifyCaptcha(uuid, answer) {
      try {
        const response = await axios.post(`${this.apiBase}/${uuid}/verify`, { answer });

        return response.data.captcha_token;
      } catch (error) {
        if (error.response.status === 400) {
          throw new Error('Капча введена неверно');
        }

        throw new Error('Произошла ошибка');
      }
    }
}
