import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import AuthAPI from '../../../services/authAPI';

import Radio from '../../../components/ui/Form/Radio';

const authAPI = new AuthAPI();

const Settings = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [settingsData, setSettingsData] = useState();
  const [serverMessage, setServerMessage] = useState('');

  useEffect(() => {
    authAPI
      .getUser()
      .then(({ data }) => {
        setSettingsData(data);
        setLoading(false);
      });
  }, []);

  const enableNotifications = () => {
    setSettingsData(prev => ({ ...prev, receive_notifications: true }));
  };

  const disableNotifications = () => {
    setSettingsData(prev => ({ ...prev, receive_notifications: false }));
  };

  const sendData = () => {
    authAPI
      .patchUser(settingsData)
      .then(() => {
        setServerMessage('Успешно');
      })
      .catch(() => {
        setServerMessage('Ошибка');
      });
  };

  return (
    <>
      <Helmet title="Настройка уведомлений" />
      <div className="lk-content lk-settings">
        <h1 className="h2 lk-settings__title">Настройки уведомлений</h1>
        <div className="lk-settings__subtitle">
          На этой странице вы можете настроить уведомления и рассылки
        </div>

        <h3 className="h3 h3--mb20">Уведомление о входе в личный кабинет</h3>
        <p className="lk-settings__text">
          На ваш e-mail приходит уведомление при входе в&nbsp;личный кабинет. Это является частью
          системы безопасности. Вы можете отключить отправку уведомления.
        </p>

        <h3 className="h3 h3--mb20">Включить отправку уведомлений</h3>

        <div className="lk-settings__form">
          {loading ? (
            'Загружаю...'
          ) : (
            <div className="form-group">
              <div className="form-line form-line--mb15">
                <div className="form-field">
                  <Radio
                    toggle={enableNotifications}
                    checked={settingsData.receive_notifications}
                    name="notifications"
                  >
                    Да
                  </Radio>
                </div>
              </div>
              <div className="form-line">
                <div className="form-field">
                  <Radio
                    toggle={disableNotifications}
                    checked={!settingsData.receive_notifications}
                    name="notifications"
                  >
                    Нет
                  </Radio>
                </div>
              </div>
            </div>
          )}
          <div className="form-line-bottom">
            <button
              disabled={serverMessage}
              type="button"
              onClick={sendData}
              className="i-button i-button--200"
            >
              {serverMessage || 'Сохранить'}
            </button>
            <button
              onClick={history.goBack}
              type="button"
              className="i-button i-button--140 i-button--white"
            >
              Отменить
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
