import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import Helmet from 'react-helmet';
import Portal from '../../../shared/utils/Portal';
import Barcode from '../../../components/modals/Barcode';

import DocumentsAPI from '../../../services/documentsAPI';

const documentsAPI = new DocumentsAPI();

const Contract = ({
  match,
}) => {
  const [modalIsOpened, setModalIsOpened] = useState(false);
  const [documentData, setDocumentData] = useState([]);

  useEffect(() => {
    documentsAPI
      .getContract(match.params.id)
      .then(res => {
        const { data } = res;
        setDocumentData(data);
      });
  });

  const toggle = () => {
    setModalIsOpened(!modalIsOpened);
  };

  if (!documentData) {
    return (
      <div className="lk-content lk-contract">
        <h1 className="h2 lk-contract__title">Загружаю...</h1>
      </div>
    );
  }
  return (
    <>
      <Helmet title={`Договор аренды №${documentData.legacy_id || ''}`} />
      <div className="lk-content lk-contract">
        <h1 className="h2 lk-contract__title">Договор аренды №{documentData.legacy_id}</h1>

        <div className="keyval">
          <div className="keyval__item">
            <div className="keyval__item-key">ID договора</div>
            <div className="keyval__item-dots" />
            <div className="lk-contract__item-val">№ {documentData.asko_id}</div>
          </div>
          <div className="keyval__item">
            <div className="keyval__item-key">Сумма обеспечительного платежа</div>
            <div className="keyval__item-dots" />
            <div className="lk-contract__item-val">{documentData.secure_payment || 0} ₽</div>
          </div>
          <div className="keyval__item">
            <div className="keyval__item-key">Вид деятельности</div>
            <div className="keyval__item-dots" />
            <div className="lk-contract__item-val">{documentData.service_class}</div>
          </div>
          <div className="keyval__item">
            <div className="keyval__item-key">Сумма единоразового бонуса</div>
            <div className="keyval__item-dots" />
            <div className="lk-contract__item-val">{documentData.outstanding_amount || 0} ₽</div>
          </div>
          <div className="keyval__item">
            <div className="keyval__item-key">Дата начала действия</div>
            <div className="keyval__item-dots" />
            <div className="lk-contract__item-val">
              {documentData.start_date && format(documentData.start_date.slice(0, 10), 'DD.MM.YYYY')}
            </div>
          </div>
          <div className="keyval__item">
            <div className="keyval__item-key">Сумма арендной платы по договору</div>
            <div className="keyval__item-dots" />
            <div className="lk-contract__item-val">{documentData.rate_per_month || 0} ₽</div>
          </div>
          <div className="keyval__item">
            <div className="keyval__item-key">Дата актуального окончания</div>
            <div className="keyval__item-dots" />
            <div className="lk-contract__item-val">
              {documentData.expiration_date && format(documentData.expiration_date.slice(0, 10), 'DD.MM.YYYY')}
            </div>
          </div>
          <div className="keyval__item">
            <div className="keyval__item-key">День платежа</div>
            <div className="keyval__item-dots" />
            <div className="lk-contract__item-val">{documentData.day_of_month}</div>
          </div>
        </div>

        <div onClick={toggle} className="lk-contract__barcode">
          <div className="lk-contract__barcode-left">
            <div className="lk-contract__summary-title">
              Сумма к оплате на {format(new Date(), 'DD.MM.YYYY')}
            </div>
            <div className="lk-contract__summary">
              {documentData.total_amount_on_date || 0}
              <small>₽</small>
            </div>
          </div>
          <div className="lk-contract__barcode-right">
            <div className="lk-contract__barcode-pic">
              <img src="../../img/barcode.svg" alt="" />
            </div>
            <div className="lk-contract__barcode-link">
              Штрих-код <br />
              для оплаты
            </div>
          </div>
        </div>

        <div className="lk-contract__agents">
          <div className="lk-contract__agents-wrap">
            {documentData.roles
                && documentData.roles.map((el, idx) => (
                  <div key={idx} className="lk-contract__agents-item">
                    <div className="lk-contract__role">{el.role}</div>
                    <div className="lk-contract__name">{el.name}</div>
                    <a href={`tel:${el.phone}`} className="lk-contract__phone">
                      {el.phone}
                    </a>
                    <a href={`mailto:${el.email}`} className="lk-contract__email">
                      {el.email}
                    </a>
                  </div>
                ))}
          </div>
        </div>

        <h3 className="h3">Площади по договору</h3>

        <div className="table lk-contract__table">
          <div className="table__row table__row--head">
            <div className="table__th">Адрес</div>
            <div className="table__th is-right">Площадь</div>
            <div className="table__th is-right">
              Стоимость, <br />в мес.
            </div>
            <div className="table__th is-right">Категории</div>
            <div className="table__th is-right">
              Дата <br />
              приёма
            </div>
            <div className="table__th is-right">
              Дата <br />
              возврата
            </div>
          </div>
          {documentData.spaces
              && documentData.spaces.map((el, idx) => (
                <div key={idx} className="table__row table__row--body">
                  <div className="table__td">{el.address}</div>
                  <div className="table__td is-right">
                    <div className="lk-contract__semi">{el.area} м²</div>
                  </div>
                  <div className="table__td is-right">
                    <div className="lk-contract__semi">{el.rate_per_month ? `${el.rate_per_month} ₽` : ''}</div>
                  </div>
                  <div className="table__td is-right">{el.category}</div>
                  <div className="table__td is-right">{el.start_date}</div>
                  <div className="table__td is-right">{el.end_date}</div>
                </div>
              ))}
        </div>
        <div className="lk-contract__back">
          <Link to="/cabinet/contracts/">Вернуться к списку договоров</Link>
        </div>
      </div>
      {modalIsOpened && (
      <Portal>
        <Barcode close={toggle} id={documentData.id} boundDates={documentData.payment_dates} />
      </Portal>
      )}
    </>
  );
};

export default Contract;
