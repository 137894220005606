export const privacyPolicyLink = 'https://media.5ka.ru/media/hosting/documents/b89ab324-a666-4388-92d6-1ba37404c24b.pdf';

export const FormFields = {
  Phone: 'phone',
  Email: 'email',
  Question: 'question',
  Agreement: 'agreement',
  Region: 'region',
  LegalForm: 'legal_form',
};
