export class ProfileActions {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  setInitialState = (payload) => {
    this.dispatch({ type: 'SET_INITIAL_STATE', payload });
  }

  toggleHasChiefAccount = (payload) => {
    this.dispatch({ type: 'TOGGLE_HAS_CHIEF_ACCOUNT', payload });
  }

  toggleHasTrustPerson = (payload) => {
    this.dispatch({ type: 'TOGGLE_HAS_TRUST_PERSON', payload });
  }


  setCompanySign = (payload) => {
    this.dispatch({ type: 'SET_SIGN', payload });
  }

  setEnglishName = (payload) => {
    this.dispatch({ type: 'SET_ENGLISH_NAME', payload: payload.target.value });
  }

  setFullName = (payload) => {
    this.dispatch({ type: 'SET_FULL_NAME', payload: payload.target.value });
  }

  setShortName = (payload) => {
    this.dispatch({ type: 'SET_SHORT_NAME', payload: payload.target.value });
  }

  setPhone = (payload) => {
    this.dispatch({ type: 'SET_PHONE', payload: payload.target.value });
  }

  setEmail = (payload) => {
    this.dispatch({ type: 'SET_EMAIL', payload: payload.target.value });
  }

  setRegistrationDate = (payload) => {
    this.dispatch({ type: 'SET_REGISTRATION_DATE', payload: payload.target.value });
  }

  setRegistrationAuthorityName = (payload) => {
    this.dispatch({ type: 'SET_REGISTRATION_AUTHORITY_NAME', payload: payload.target.value });
  }

  setOrganizationType = (payload) => {
    this.dispatch({ type: 'SET_ORGANIZATION_TYPE', payload });
  }

  setOrganizationOGRN = (payload) => {
    this.dispatch({ type: 'SET_ORGANIZATION_OGRN', payload: payload.target.value });
  }

  setOrganizationINN = (payload) => {
    this.dispatch({ type: 'SET_ORGANIZATION_INN', payload: payload.target.value });
  }

  setOrganizationKPP = (payload) => {
    this.dispatch({ type: 'SET_ORGANIZATION_KPP', payload: payload.target.value });
  }

  setOrganizationOKPO = (payload) => {
    this.dispatch({ type: 'SET_ORGANIZATION_OKPO', payload: payload.target.value });
  }

  setOrganizationOKVED = (payload) => {
    this.dispatch({ type: 'SET_ORGANIZATION_OKVED', payload: payload.target.value });
  }

  setOrganizationOKONX = (payload) => {
    this.dispatch({ type: 'SET_ORGANIZATION_OKONX', payload: payload.target.value });
  }

  setOrganizationLegalAddress = (payload) => {
    this.dispatch({ type: 'SET_ORGANIZATION_LEGAL_ADDRESS', payload: payload.target.value });
  }

  setOrganizationPhysicalAddress = (payload) => {
    this.dispatch({ type: 'SET_ORGANIZATION_PHYSICAL_ADDRESS', payload: payload.target.value });
  }

  setMemberFirstName = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_MEMBERS_FIRST_NAME', payload: { value, filter: id } });
  }

  setMemberLastName = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_MEMBERS_LAST_NAME', payload: { value, filter: id } });
  }

  setMemberMiddleName = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_MEMBERS_MIDDLE_NAME', payload: { value, filter: id } });
  }

  setMemberBirthDate = (value, filter) => {
    this.dispatch({ type: 'SET_MEMBERS_BIRTH_DATE', payload: { value, filter } });
  }

  setMemberPassportSeries = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_MEMBERS_PASSPORT_SERIES', payload: { value, filter: id } });
  }

  setMemberPassportNumber = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_MEMBERS_PASSPORT_NUMBER', payload: { value, filter: id } });
  }

  setMemberPassportIssuer = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_MEMBERS_PASSPORT_ISSUER', payload: { value, filter: id } });
  }

  setMemberPassportIssueDate = (value, filter) => {
    this.dispatch({ type: 'SET_MEMBERS_PASSPORT_ISSUE_DATE', payload: { value, filter } });
  }

  setMemberPassportRegistrationAddress = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_MEMBERS_PASSPORT_REGISTRATION_ADDRESS', payload: { value, filter: id } });
  }

  setBankCountry = (payload) => {
    this.dispatch({ type: 'SET_BANK_COUNTRY', payload: payload.target.value });
  }

  setBankName = (payload) => {
    this.dispatch({ type: 'SET_BANK_NAME', payload: payload.target.value });
  }

  setBankAddress = (payload) => {
    this.dispatch({ type: 'SET_BANK_ADDRESS', payload: payload.target.value });
  }

  setBankBIK = (payload) => {
    this.dispatch({ type: 'SET_BANK_BIK', payload: payload.target.value });
  }

  setBankCheckingAccount = (payload) => {
    this.dispatch({ type: 'SET_BANK_CHECKING_ACCOUNT', payload: payload.target.value });
  }

  setBankCorrespondentAccount = (payload) => {
    this.dispatch({ type: 'SET_BANK_CORRESPONDENT_ACCOUNT', payload: payload.target.value });
  }

  setBankSWIFT = (payload) => {
    this.dispatch({ type: 'SET_BANK_SWIFT', payload: payload.target.value });
  }

  setBankIBAN = (payload) => {
    this.dispatch({ type: 'SET_BANK_IBAN', payload: payload.target.value });
  }

  setBankABA = (payload) => {
    this.dispatch({ type: 'SET_BANK_ABA', payload: payload.target.value });
  }

  addLegalBeneficiary = (payload) => {
    this.dispatch({ type: 'ADD_LEGAL_BENEFICIARY', payload });
  }

  removeLegalBeneficiary = (payload) => {
    this.dispatch({ type: 'REMOVE_LEGAL_BENEFICIARY', payload });
  }

  setLegalBeneficiariesRegistrationDate = (value, filter) => {
    this.dispatch({ type: 'SET_LEGAL_BENEFICIARIES_REGISTRATION_DATE', payload: { value, filter } });
  }

  setLegalBeneficiariesRegistrationAuthorityName = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_LEGAL_BENEFICIARIES_REGISTRATION_AUTHORITY_NAME', payload: { value, filter: id } });
  }

  setLegalBeneficiariesFullName = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_LEGAL_BENEFICIARIES_FULL_NAME', payload: { value, filter: id } });
  }

  setLegalBeneficiariesShortName = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_LEGAL_BENEFICIARIES_SHORT_NAME', payload: { value, filter: id } });
  }

  setLegalBeneficiariesPhone = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_LEGAL_BENEFICIARIES_PHONE', payload: { value, filter: id } });
  }

  setLegalBeneficiariesEmail = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_LEGAL_BENEFICIARIES_EMAIL', payload: { value, filter: id } });
  }

  setLegalBeneficiariesOrganizationType = (value, filter) => {
    this.dispatch({ type: 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_TYPE', payload: { value, filter } });
  }

  setLegalBeneficiariesOrganizationOGRN = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_OGRN', payload: { value, filter: id } });
  }

  setLegalBeneficiariesOrganizationINN = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_INN', payload: { value, filter: id } });
  }

  setLegalBeneficiariesOrganizationKPP = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_KPP', payload: { value, filter: id } });
  }

  setLegalBeneficiariesOrganizationOKPO = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_OKPO', payload: { value, filter: id } });
  }

  setLegalBeneficiariesOrganizationOKVED = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_OKVED', payload: { value, filter: id } });
  }

  setLegalBeneficiariesOrganizationOKONX = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_OKONX', payload: { value, filter: id } });
  }

  setLegalBeneficiariesOrganizationLegalAddress = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_LEGAL_ADDRESS', payload: { value, filter: id } });
  }

  setLegalBeneficiariesOrganizationPhysicalAddress = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_PHYSICAL_ADDRESS', payload: { value, filter: id } });
  }

  setLegalBeneficiariesOrganizationAuthorityName = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_AUTHORITY_NAME', payload: { value, filter: id } });
  }

  addIndividualBeneficiary = (payload) => {
    this.dispatch({ type: 'ADD_INDIVIDUAL_BENEFICIARY', payload });
  }

  removeIndividualBeneficiary = (payload) => {
    this.dispatch({ type: 'REMOVE_INDIVIDUAL_BENEFICIARY', payload });
  }

  setIndividualBeneficiariesFirstName = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_INDIVIDUAL_BENEFICIARIES_FIRST_NAME', payload: { value, filter: id } });
  }

  setIndividualBeneficiariesMiddleName = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_INDIVIDUAL_BENEFICIARIES_MIDDLE_NAME', payload: { value, filter: id } });
  }

  setIndividualBeneficiariesLastName = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_INDIVIDUAL_BENEFICIARIES_LAST_NAME', payload: { value, filter: id } });
  }

  setIndividualBeneficiariesBirthDate = (value, filter) => {
    this.dispatch({ type: 'SET_INDIVIDUAL_BENEFICIARIES_BIRTH_DATE', payload: { value, filter } });
  }

  setIndividualBeneficiariesPassportSeries = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_INDIVIDUAL_BENEFICIARIES_PASSPORT_SERIES', payload: { value, filter: id } });
  }

  setIndividualBeneficiariesPassportNumber = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_INDIVIDUAL_BENEFICIARIES_PASSPORT_NUMBER', payload: { value, filter: id } });
  }

  setIndividualBeneficiariesPassportIssuer = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_INDIVIDUAL_BENEFICIARIES_PASSPORT_ISSUER', payload: { value, filter: id } });
  }

  setIndividualBeneficiariesPassportIssueDate = (value, filter) => {
    this.dispatch({ type: 'SET_INDIVIDUAL_BENEFICIARIES_PASSPORT_ISSUE_DATE', payload: { value, filter } });
  }

  setIndividualBeneficiariesPassportRegistrationAddress = ({ target: { id, value } }) => {
    this.dispatch({ type: 'SET_INDIVIDUAL_BENEFICIARIES_PASSPORT_REGISTRATION_ADDRESS', payload: { value, filter: id } });
  }
}
