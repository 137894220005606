import React, { useMemo, useState } from 'react';
import ListItem from '../GroupListItem';

const limit = 20;

const ItemsList = ({
  spaces,
  setSpace,
  currentSpace,
}) => {
  const [isLimitApplied, setIsLimitApplied] = useState(true);

  const spacesToShow = useMemo(() => (
    isLimitApplied
      ? spaces.slice(0, limit)
      : spaces
  ), [spaces, isLimitApplied]);

  const areAllSpacesShown = useMemo(() => (
    spaces.length === spacesToShow.length
  ), [spaces, spacesToShow]);

  return (
    <div className="find-group__left">
      <div className="table find-group__table">
        <div className="table__row table__row--head">
          <div className="table__th find-group__address">Адрес</div>
          <div className="table__th find-group__area">Площадь</div>
          <div className="table__th find-group__price-metre">Стоимость, за м²</div>
          <div className="table__th find-group__price-month">Стоимость, за мес</div>
        </div>
        {spacesToShow.map(space => (
          <ListItem
            key={space.id}
            id={space.id}
            address={space.address}
            area={space.area}
            baseRate={space.base_rate}
            setSpace={setSpace}
            isCurrent={+currentSpace === +space.id}
          />
        ))}
      </div>
      {!areAllSpacesShown && (
        <div
          tabIndex="0"
          role="button"
          onKeyDown={() => {
            setIsLimitApplied(false);
          }}
          onClick={() => {
            setIsLimitApplied(false);
          }}
          className="find-group__more"
          id="findGroupsMore"
        >
          <span className="i-button i-button--270">Показать ещё</span>
        </div>
      )}
    </div>
  );
};

export default ItemsList;
