import React from 'react';
import cx from 'classnames';

const OptionsListWithId = ({
  option,
  setValue,
  value,
}) => (
  <li
    role="option"
    aria-selected={value === option.name}
    tabIndex="-1"
    onKeyDown={() => setValue(option.name)}
    onClick={() => setValue(option.name)}
    key={option.id}
    className={cx('opt', { selected: value === option.name })}
    title={option.name}
  >
    {option.name}
  </li>
);

const OptionsListWithoutId = ({
  option,
  idx,
  setValue,
  options,
  value,
}) => (
  <li
    role="option"
    aria-selected={value === option[idx]}
    tabIndex="-1"
    onKeyDown={() => setValue(options[idx])}
    onClick={() => setValue(options[idx])}
    key={idx}
    className={cx('opt', { selected: value === options[idx] })}
    title={option}
  >
    {option}
  </li>
);

const OptionsList = ({
  options,
  optionsWithId,
  setValue,
  value,
}) => (
  <ul className="options">
    <li className="opt disabled selected" />
    {options.map((option, id) => (optionsWithId
      ? (
        <OptionsListWithId
          key={option.id}
          option={option}
          setValue={setValue}
          value={value}
        />
      )
      : (
        <OptionsListWithoutId
          key={option.name}
          option={option}
          options={options}
          setValue={setValue}
          idx={id}
          value={value}
        />
      )))}
  </ul>
);


export default OptionsList;
