import React from 'react';
import InputMask from 'react-input-mask';
import cx from 'classnames';

const Input = ({
  placeholder, className, value, onChange, name, id,
}) => (
  <InputMask
    id={id}
    value={value}
    onChange={onChange}
    type="tel"
    className={cx('i-input', className)}
    mask="+7 (999) 999-99-99"
    placeholder={placeholder}
    name={name}
  />
);

export default Input;
