import React from 'react';
import cx from 'classnames';
import InputMask from 'react-input-mask';
import FormField from '../../../ui/Form/FormField';
import Input from '../../../ui/Form/Input';
import Dropdown from '../../../ui/Layout/Dropdown';
import INNInput from '../../../ui/Form/INNInput';
import KPPInput from '../../../ui/Form/KPPInput';
import Textarea from '../../../ui/Form/Textarea';
import DatePicker from '../../../ui/Form/DatePicker';
import PhoneInput from '../../../ui/Form/PhoneInput';
import { getBlockEnterKeyHandler } from '../../../../shared/utils/getBlockEnterKeyHandler';

const LegalBeneficiariesItem = ({
  beneficiary, profileActions, invalidField, dropdownData,
}) => {
  const getOrganizationNameByTypeId = typeId => {
    try {
      return dropdownData.types.find(type => type.id === typeId).name;
    } catch {
      return null;
    }
  };

  const getOrganizationTypeIdByName = name => {
    try {
      return dropdownData.types.find(type => type.name === name).id;
    } catch {
      return null;
    }
  };
  return (
    <div key={beneficiary.id} className="lk-benef" id="tmpBenY">
      <div className="lk-benef__item">
        <h4 className="form-h4">
          Конечный бенефициар (юридическое лицо)
          <span
            className="lk-benef__remove"
            role="button"
            tabIndex="0"
            onClick={() => profileActions.removeLegalBeneficiary(beneficiary.id)}
            onKeyDown={getBlockEnterKeyHandler(
              () => profileActions.removeLegalBeneficiary(beneficiary.id),
            )}
          >
            Удалить блок
          </span>
        </h4>

        <div className="form-group">
          <FormField>
            <Input
              className={cx({
                'vf-error': invalidField
                  && invalidField.fullName,
              })}
              id={beneficiary.id}
              value={beneficiary.fullName}
              onChange={profileActions.setLegalBeneficiariesFullName}
              type="text"
              placeholder="Полное наименование"
            />
          </FormField>
          <FormField>
            <Input
              className={cx({
                'vf-error': invalidField
                  && invalidField.shortName,
              })}
              id={beneficiary.id}
              value={beneficiary.shortName}
              onChange={profileActions.setLegalBeneficiariesShortName}
              type="text"
              placeholder="Сокращённое наименование"
            />
          </FormField>
          <FormField label="Формы собственности">
            {dropdownData && (
              <Dropdown
                options={dropdownData.types}
                optionsWithId
                placeholder="Выберите из списка"
                error={invalidField
                  && invalidField.organization.type}
                onChange={value => profileActions.setLegalBeneficiariesOrganizationType(getOrganizationTypeIdByName(value), beneficiary.id)}
                id={beneficiary.id}
                value={getOrganizationNameByTypeId(
                  beneficiary.organization.type,
                )}
              />
            )}
          </FormField>

          <FormField>
            <InputMask
              className={cx('i-input', {
                'vf-error': invalidField
                  && invalidField.organization.OGRN,
              })}
              id={beneficiary.id}
              value={beneficiary.organization.OGRN}
              onChange={profileActions.setLegalBeneficiariesOrganizationOGRN}
              mask="999999999999999"
              maskChar={null}
              placeholder="ОГРН/ОГРНИП"
            />
          </FormField>
          <FormField>
            <INNInput
              className={cx({
                'vf-error': invalidField
                  && invalidField.organization.INN,
              })}
              id={beneficiary.id}
              value={beneficiary.organization.INN}
              onChange={profileActions.setLegalBeneficiariesOrganizationINN}
              placeholder="КПП"
            />
          </FormField>
          <FormField>
            <KPPInput
              className={cx({
                'vf-error': invalidField
                  && invalidField.organization.KPP,
              })}
              id={beneficiary.id}
              value={beneficiary.organization.KPP}
              onChange={profileActions.setLegalBeneficiariesOrganizationKPP}
              placeholder="КПП"
            />
          </FormField>
          <FormField>
            <InputMask
              className={cx('i-input', {
                'vf-error': invalidField
                  && invalidField.organization.OKPO,
              })}
              id={beneficiary.id}
              value={beneficiary.organization.OKPO}
              onChange={profileActions.setLegalBeneficiariesOrganizationOKPO}
              placeholder="ОКПО"
              mask="9999999999"
              maskChar={null}
            />
          </FormField>
          <FormField>
            <InputMask
              className={cx('i-input', {
                'vf-error': invalidField
                  && invalidField.organization.OKVED,
              })}
              id={beneficiary.id}
              value={beneficiary.organization.OKVED}
              onChange={profileActions.setLegalBeneficiariesOrganizationOKVED}
              placeholder="ОКВЭД"
              mask="9999999999"
              maskChar={null}
            />
          </FormField>
          <FormField>
            <InputMask
              className={cx('i-input', {
                'vf-error': invalidField
                  && invalidField.organization.OKONX,
              })}
              id={beneficiary.id}
              value={beneficiary.organization.OKONX}
              onChange={profileActions.setLegalBeneficiariesOrganizationOKONX}
              placeholder="ОКОНХ"
              mask="99999"
              maskChar={null}
            />
          </FormField>
          <FormField>
            <Textarea
              className={cx({
                'vf-error': invalidField
                  && invalidField.organization.legalAddress,
              })}
              id={beneficiary.id}
              value={beneficiary.organization.legalAddress}
              onChange={profileActions.setLegalBeneficiariesOrganizationLegalAddress}
              placeholder="Юридический адрес"
            />
          </FormField>
          <FormField>
            <Textarea
              className={cx({
                'vf-error': invalidField
                  && invalidField.organization.physicalAddress,
              })}
              id={beneficiary.id}
              value={beneficiary.organization.physicalAddress}
              onChange={profileActions.setLegalBeneficiariesOrganizationPhysicalAddress}
              placeholder="Фактический адрес"
            />
          </FormField>
          <FormField label="Дата регистрации">
            <DatePicker
              error={invalidField.registration
                && invalidField.registration.date}
              currentDate={beneficiary.registration.date}
              handleChange={value => profileActions.setLegalBeneficiariesRegistrationDate(value, beneficiary.id)}
            />
          </FormField>
          <FormField>
            <Textarea
              className={cx({
                'vf-error': invalidField.registration
                  && invalidField.registration.authorityName,
              })}
              id={beneficiary.id}
              value={beneficiary.registration.authorityName}
              onChange={profileActions.setLegalBeneficiariesRegistrationAuthorityName}
              placeholder="Наименование и номер регистрирующего органа"
            />
          </FormField>
          <FormField>
            <PhoneInput
              className={cx({
                'vf-error': invalidField
                  && invalidField.phone,
              })}
              id={beneficiary.id}
              value={beneficiary.phone}
              onChange={profileActions.setLegalBeneficiariesPhone}
              placeholder="Телефон"
            />
          </FormField>
          <FormField>
            <Input
              className={cx({
                'vf-error': invalidField
                  && invalidField.email,
              })}
              id={beneficiary.id}
              value={beneficiary.email}
              onChange={profileActions.setLegalBeneficiariesEmail}
              placeholder="E-mail"
            />
          </FormField>
        </div>
      </div>
    </div>
  );
};


export default LegalBeneficiariesItem;
