import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import BarcodeGenerator from 'react-barcode';
import { format } from 'date-fns';

import DocumentsAPI from '../../../services/documentsAPI';
import formatCurrency from '../../../shared/utils/formatCurrency';

class Barcode extends Component {
  documentsAPI = new DocumentsAPI();

  state = { loading: true, data: [] };

  componentDidMount() {
    const { match, location } = this.props;
    this.documentsAPI
      .getPayments(match.params.id, location.search)
      .then(res => {
        const { data } = res;
        this.setState({ data, loading: false });
      })
      .catch(() => this.setState({ error: true }));
  }

  render() {
    const { data, loading, error } = this.state;
    if (error) return <p>Произошла ошибка, повторите попытку</p>;
    if (loading) return <p>Загружаю...</p>;
    return (
      <div className="w-content">
        <div className="lk-wrap lk-wrap--full">
          <div className="lk-content lk-barcode">
            {!data.length && (
              <h3 className="features__title">За данный промежуток времени ничего не найдено</h3>
            )}
            {data.map(el => (
              <div className="lk-barcode__item">
                <h2 className="h2 lk-barcode__address">{el.name}</h2>
                <div className="lk-barcode__right">
                  <BarcodeGenerator value={el.barcode} width={2} height="55px" fontOptions="bold" />
                </div>
                <div className="lk-barcode__row">
                  <div className="lk-barcode__col">
                    <div className="lk-barcode__col-key">{el.payment_type}</div>
                    <div className="lk-barcode__col-val">
                      <b>{formatCurrency(el.expected_amount)}</b> ₽
                    </div>
                  </div>
                  <div className="lk-barcode__col">
                    <div className="lk-barcode__col-key">Период оплаты</div>
                    <div className="lk-barcode__col-val">
                      с<b> {format(el.from_date, 'DD.MM.YYYY')} </b>по
                      <b> {format(el.to_date, 'DD.MM.YYYY')} </b>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Barcode);
