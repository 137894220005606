import React, {
  useCallback, useMemo,
} from 'react';
import { Link } from 'react-router-dom';
import Pin from '../../../../ui/svg/pin';
import FiltersRegionSelection from '../FiltersRegionSelection';
import DefaultSelect from '../../../../ui/Select/Default/DefaultSelect';
import { Region } from '../utils';
import AreaTag from '../AreaTag';

export default function Filters({
  ordering,
  pageSize,
  selectedRegion,
  geolocationName,
  onChangeOrdering,
  onChangePageSize,
  onChangeSelectedRegion,
  onChangeGeolocationName,
  totalItemsAmount,
  foundItemsAmount,
  showMapLink = false,
}) {
  const orderingOptions = useMemo(() => ([{
    value: 'base_rate',
    label: 'По цене (сначала меньше)',
  }, {
    value: '-base_rate',
    label: 'По цене (сначала больше)',
  }]), []);

  const pageSizeOptions = useMemo(() => ([{
    value: 15,
    label: '15',
  }, {
    value: 30,
    label: '30',
  }, {
    value: 50,
    label: '50',
  }]), []);

  const selectedOrderingOption = useMemo(() => orderingOptions
    .find(option => option.value === ordering) || null, [orderingOptions, ordering]);

  const selectOrderingOption = useCallback((option) => {
    onChangeOrdering(option.value);
  }, [onChangeOrdering]);

  const selectedPageSizeOption = useMemo(() => pageSizeOptions
    .find(option => option.value === pageSize) || null, [pageSizeOptions, pageSize]);

  const selectPageSizeOption = useCallback((option) => {
    onChangePageSize(option.value);
  }, [onChangePageSize]);

  const searchByRegion = region => {
    const regionEntity = region === null ? null : new Region(region);

    onChangeSelectedRegion(regionEntity);
  };

  const searchByGeolocationName = name => {
    onChangeGeolocationName(name);
  };

  return (
    <>
      <div className="sort">
        <div className="sort__left">
          <div className="sort__group">
            <div className="sort__title">Сортировка</div>
            <div className="sort__select sort__select--s1">
              <DefaultSelect
                items={orderingOptions}
                value={selectedOrderingOption}
                placeholder="Порядок"
                isSearchable={false}
                onChange={selectOrderingOption}
              />
            </div>
          </div>
          <div className="sort__group">
            <div className="sort__title">Предметов на странице</div>
            <div className="sort__select sort__select--s2">
              <DefaultSelect
                items={pageSizeOptions}
                value={selectedPageSizeOption}
                placeholder="Предметов на странице"
                isSearchable={false}
                onChange={selectPageSizeOption}
              />
            </div>
          </div>
        </div>
        {showMapLink && (
          <div className="sort__right">
            <Link to="/find/map" className="i-button i-button--outline">
              <i className="i-button__pin">
                <Pin />
              </i>
              показать на карте
            </Link>
          </div>
        )}
      </div>
      <div className="find__filter">
        <div className="find__area-header">
          <div className="find__result">
            Найдено {foundItemsAmount} из {totalItemsAmount}
          </div>
          {(selectedRegion || geolocationName.length > 0) && (
            <div className="find__area">для территории:
              {selectedRegion && (
                <AreaTag
                  name={selectedRegion.name}
                  onRemove={() => onChangeSelectedRegion(null)}
                />
              )}
              {geolocationName.length > 0 && (
                <AreaTag
                  name={geolocationName}
                  onRemove={() => onChangeGeolocationName('')}
                />
              )}
            </div>
          )}
        </div>

        <FiltersRegionSelection
          onSearchByRegion={searchByRegion}
          onSearchByGeolocationName={searchByGeolocationName}
        />
      </div>
    </>
  );
}
