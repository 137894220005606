import React from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import PartnerForm from '../../../components/common/Cabinet/Profile/PartnerForm/PartnerForm';

const Profile = ({
  dispatch,
  history,
  dropdownData,
}) => (
  <>
    <Helmet titl="Анкета партнёра" />
    <PartnerForm
      dropdownData={dropdownData}
      dispatch={dispatch}
      history={history}
    />
  </>
);

const mapStateToProps = state => ({
  dropdownData: state.register.dropdownData,
});

export default connect(mapStateToProps)(Profile);
