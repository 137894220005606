import React from 'react';

const Pin = () => (
  <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 16C6 16 12 10.6665 12 6C12 2.68652 9.3125 0 6 0C2.6875 0 0 2.68652 0 6C0 10.6665 6 16 6 16ZM6 8C7.10547 8 8 7.10449 8 6C8 4.89551 7.10547 4 6 4C4.89453 4 4 4.89551 4 6C4 7.10449 4.89453 8 6 8Z"
      fill="#E61801"
    />
  </svg>
);

export default Pin;
