import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import './assets/style/main.scss';
import ScrollToTop from './shared/utils/ScrollToTop';
import { loginActions } from './store';

import HomePage from './pages/Home';
import Offer from './pages/Offer';
import Terms from './pages/Terms';
import Cabinet from './pages/Cabinet';
import FindPage from './pages/Find';
import ResetPage from './pages/Reset';
import ChangePassword from './pages/ChangePassword';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Barcode from './components/common/Cabinet/Barcode';
import RegisterPage from './pages/Register';
import Verify from './pages/Verify';
import AuthAPI from './services/authAPI';
import RequestDecline from './pages/Request/Decline';
import RequestConfirm from './pages/Request/Confirm';
import CookiePolicyPanel from './components/ui/CookiePolicy/CookiePolicyPanel';

axios.interceptors.request.use(
  config => {
    const csrftoken = document.cookie.replace(
      // eslint-disable-next-line no-useless-escape
      /(?:(?:^|.*;\s*)csrftoken\s*\=\s*([^;]*).*$)|^.*$/,
      '$1',
    );
    if (csrftoken.length) {
      config.headers['X-CSRFToken'] = csrftoken;
    }
    return config;
  },
  error => Promise.reject(error),
);

class App extends Component {
  authAPI = new AuthAPI();

  state = {};

  componentDidMount() {
    const { dispatch } = this.props;
    // eslint-disable-next-line no-useless-escape
    const Token = document.cookie.replace(/(?:(?:^|.*;\s*)Token\s*\=\s*([^;]*).*$)|^.*$/, '$1');
    if (Token.length) {
      dispatch(loginActions.loginByTokenRequest());
    }
    dispatch(loginActions.getGeolocation());
  }

  render() {
    const { location } = this.props;
    return (
      <>
        <Header location={location} />
        <Switch>
          <ScrollToTop>
            <Route path="/" component={HomePage} exact />
            <Route path="/offer/" component={Offer} exact />
            <Route path="/terms/" component={Terms} exact />
            <Route path="/register/" component={RegisterPage} exact />
            <Route path="/verify/:hash" component={Verify} exact />
            <Route path="/reset/" component={ResetPage} exact />
            <Route path="/pwdchange/" component={ChangePassword} exact />
            <Route path="/cabinet/" component={Cabinet} />
            <Route path="/barcode/:id" component={Barcode} exact />
            <Route path="/find/" component={FindPage} />

            <Route
              path="/request/organizations/:organizationId/decline/:uuid"
              component={RequestDecline}
              exact
            />
            <Route
              path="/request/organizations/:organizationId/confirm/:uuid"
              component={RequestConfirm}
              exact
            />
          </ScrollToTop>
        </Switch>

        <CookiePolicyPanel />

        <Footer />
      </>
    );
  }
}

const mapStateToProps = state => ({
  loginData: state.login,
});

export default withRouter(connect(mapStateToProps)(App));
