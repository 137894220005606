import React from 'react';
import Card from '../ResultsCard';

export default function CardList({ cards }) {
  return (
    <div>
      {cards.map(card => (
        <Card
          key={card.id}
          id={card.id}
          address={card.address}
          photos={card.photos}
          area={card.area}
          askoId={card.asko_id}
          askoToDate={card.asko_to_date}
          rate={card.base_rate}
          leaseTerm={card.lease_term}
          description={card.short_desc}
          shopType={card.shop_format}
          occupancyStatus={card.occupancy_status}
        />
      ))}
    </div>
  );
}
