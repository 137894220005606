import axios from 'axios';
import queryString from 'query-string';

export default class OrganizationAPI {
  _apiBase = `${process.env.REACT_APP_API_BASE}/organization`;

  getTypes = async () => {
    try {
      const res = await axios.get(`${this._apiBase}/types/`);
      return res;
    } catch (error) {
      return new Error(error);
    }
  };

  getActivities = async () => {
    try {
      const res = await axios.get(`${this._apiBase}/activities/`);
      return res;
    } catch (error) {
      return new Error(error);
    }
  };

  getInterests = async () => {
    try {
      const res = await axios.get(`${this._apiBase}/interests/`);
      return res;
    } catch (error) {
      return new Error(error);
    }
  };

  getRegions = async () => {
    try {
      return await axios.get(`${this._apiBase}/regions`);
    } catch {
      return [];
    }
  };

  async getRegionByCoordinates(longitude, latitude) {
    const response = await axios.get(`${this._apiBase}/regions`, {
      params: {
        point: `${longitude},${latitude}`,
      },
    });

    return response.data;
  }

  async getCurrentAddress(geolocation) {
    const params = geolocation === null
      ? {}
      : {
        longitude: geolocation.longitude,
        latitude: geolocation.latitude,
      };

    const response = await axios.get(`${this._apiBase}/regions/nearest`, { params });

    return response.data;
  }

  async getAddressesByIds({ geolocationIds, shopIds }) {
    const response = await axios.get(`${this._apiBase}/regions/filter`, {
      params: {
        geo: geolocationIds,
        shop: shopIds,
      },
      paramsSerializer: params => queryString.stringify(params, {
        arrayFormat: 'comma',
      }),
    });

    return response.data;
  }

  getAlphabetRegions = async () => {
    const res = await axios.get(`${this._apiBase}/alphabet-regions/`);
    try {
      return res;
    } catch {
      return res;
    }
  };

  getAll = async () => {
    try {
      const types = await axios.get(`${this._apiBase}/types/`);
      const activities = await axios.get(`${this._apiBase}/activities/`);
      const interests = await axios.get(`${this._apiBase}/interests/`);
      const regions = await axios.get(`${this._apiBase}/regions/`);
      return {
        types: [...types.data],
        activities: [...activities.data],
        interests: [...interests.data],
        regions: [...regions.data],
      };
    } catch (error) {
      return new Error(error);
    }
  };

  getOrganization = async () => {
    // eslint-disable-next-line no-useless-escape
    const Token = document.cookie.replace(/(?:(?:^|.*;\s*)Token\s*\=\s*([^;]*).*$)|^.*$/, '$1');
    const res = await axios.get(`${this._apiBase}/`, {
      headers: {
        Authorization: `Token ${Token}`,
      },
    });
    try {
      return res;
    } catch {
      return res;
    }
  };

  patchOrganization = async formData => {
    delete formData.partner_type;
    formData.activities = formData.activities.filter(el => el.activity_type !== 0);
    formData.organization_interests = formData.organization_interests.filter(el => el !== 0);
    // eslint-disable-next-line no-useless-escape
    const Token = document.cookie.replace(/(?:(?:^|.*;\s*)Token\s*\=\s*([^;]*).*$)|^.*$/, '$1');
    const res = await axios.patch(
      `${this._apiBase}/`,
      { ...formData },
      {
        headers: {
          Authorization: `Token ${Token}`,
        },
      },
    );
    try {
      return res;
    } catch {
      return res;
    }
  };

  getProfile = async () => {
    // eslint-disable-next-line no-useless-escape
    const Token = document.cookie.replace(/(?:(?:^|.*;\s*)Token\s*\=\s*([^;]*).*$)|^.*$/, '$1');
    const response = await axios.get(`${this._apiBase}/detail/`, {
      headers: {
        Authorization: `Token ${Token}`,
      },
    });

    return response.data;
  };

  patchProfile = async data => {
    // eslint-disable-next-line no-useless-escape
    const Token = document.cookie.replace(/(?:(?:^|.*;\s*)Token\s*\=\s*([^;]*).*$)|^.*$/, '$1');

    try {
      await axios.patch(`${this._apiBase}/detail/`, data, {
        headers: {
          Authorization: `Token ${Token}`,
        },
      });
    } catch (error) {
      throw error.response.data;
    }
  };

  getSigns = async () => {
    const response = await axios.get(`${this._apiBase}/signs/`);

    return response.data;
  };
}
