import React, {
  useMemo, useState, useEffect,
} from 'react';
import { debounce } from 'lodash/function';
import DefaultSelect from '../Default/DefaultSelect';
import SpacesAPI from '../../../../services/spacesAPI';

const spacesApi = new SpacesAPI();

export default function RegionSelect({
  selectedRegion,
  inputValue,
  onChange,
  onChangeInputValue,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [addresses, setAddresses] = useState([]);

  const updateAddresses = debounce(async (searchString) => {
    setIsLoading(true);
    setAddresses([]);

    const foundAddresses = await spacesApi.getAddresses(searchString);

    setAddresses(foundAddresses);
    setIsLoading(false);
  }, 300);

  const items = useMemo(() => {
    const formattedItems = addresses.map(address => ({
      value: address.geo_id || address.shop_id,
      label: address.geo_name,
    }));

    if (
      selectedRegion !== null
      && addresses.find(address => selectedRegion.geo_id === address.geo_id) === undefined
    ) {
      formattedItems.unshift({
        value: selectedRegion.geo_id || selectedRegion.shop_id,
        label: selectedRegion.geo_name,
      });
    }

    return formattedItems;
  }, [selectedRegion, addresses]);

  const value = useMemo(() => {
    if (!selectedRegion) return null;

    return {
      value: selectedRegion.geo_id || selectedRegion.shop_id,
      label: selectedRegion.geo_name,
    };
  }, [selectedRegion]);

  const selectRegion = (item) => {
    const region = addresses.find(address => (
      [address.geo_id, address.shop_id].includes(item.value)
    ));

    if (region !== undefined) {
      onChange(region);
    }
  };

  const clearSelect = () => {
    onChangeInputValue('');
    onChange(null);
  };

  useEffect(() => {
    updateAddresses(inputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  return (
    <DefaultSelect
      items={items}
      value={value}
      searchValue={inputValue}
      placeholder="Выберите регион или город"
      noOptionsMessage={isLoading && 'Загрузка...'}
      isClearable
      isSearchable
      onClear={clearSelect}
      onChange={selectRegion}
      onSearch={onChangeInputValue}
    />
  );
}
