import React from 'react';

export default function AreaTag({
  name,
  onRemove,
}) {
  return (
    <div className="find__area-tag">
      <span className="find__area-city">{name}</span>
      <span
        className="find__area-close"
        role="button"
        tabIndex="0"
        onClick={onRemove}
        onKeyDown={event => event.key === 'Enter' && onRemove()}
      />
    </div>
  );
}
