import omitBy from 'lodash/omitBy';
import { getDefaultGroupSearchFilters, getDefaultSearchFilters, OrderingType } from './constants';

export function omitEmptyValues(object) {
  return omitBy(
    object,
    value => value === null || (Array.isArray(value) && value.length === 0) || value === '',
  );
}

export function mapSearchFiltersToFiltersTransferObject(searchFilters) {
  return omitEmptyValues({
    page: searchFilters.page,
    shop: searchFilters.shop,
    geo: searchFilters.geolocation,
    geo_name: searchFilters.geolocationName,
    page_size: searchFilters.pageSize,
    base_rate_min: searchFilters.baseRateRange.min,
    base_rate_max: searchFilters.baseRateRange.max,
    area_min: searchFilters.areaRange.min,
    area_max: searchFilters.areaRange.max,
    category: searchFilters.categories,
    ordering: searchFilters.ordering,
  });
}

function getNumber(stringValue, fallback) {
  return parseInt(stringValue, 10) || fallback;
}

function getNumberArrayOrEmptyArray(value) {
  if (Array.isArray(value)) {
    return value
      .map(item => getNumber(item, null))
      .filter(item => item !== null);
  }

  return [getNumber(value, null)].filter(item => item !== null);
}

function getValidOrdering(ordering, fallback) {
  return Object.values(OrderingType).includes(ordering) ? ordering : fallback;
}

export function mapFiltersTransferObjectToSearchFilters(transferObject) {
  const {
    page,
    page_size,
    shop,
    geo,
    geo_name,
    base_rate_min,
    base_rate_max,
    area_min,
    area_max,
    category,
    location,
    ordering,
  } = transferObject;

  const defaultSearchFilters = getDefaultSearchFilters();

  return {
    page: getNumber(page, defaultSearchFilters.page),
    shop: getNumberArrayOrEmptyArray(shop),
    geolocation: getNumberArrayOrEmptyArray(geo),
    geolocationName: geo_name || '',
    pageSize: getNumber(page_size, defaultSearchFilters.pageSize),
    baseRateRange: {
      min: getNumber(base_rate_min, defaultSearchFilters.baseRateRange.min),
      max: getNumber(base_rate_max, defaultSearchFilters.baseRateRange.max),
    },
    areaRange: {
      min: getNumber(area_min, defaultSearchFilters.areaRange.min),
      max: getNumber(area_max, defaultSearchFilters.areaRange.max),
    },
    categories: getNumberArrayOrEmptyArray(category),
    regions: getNumberArrayOrEmptyArray(location),
    ordering: getValidOrdering(ordering, defaultSearchFilters.ordering),
  };
}

export function mapGroupFiltersTransferObjectToSearchFilters(transferObject) {
  const {
    page,
    page_size,
    shop,
    geo,
    geo_name,
    base_rate_min,
    base_rate_max,
    area_min,
    area_max,
    amount_min,
    amount_max,
    ordering,
  } = transferObject;

  const defaultSearchFilters = getDefaultGroupSearchFilters();

  return {
    page: getNumber(page, defaultSearchFilters.page),
    shop: getNumberArrayOrEmptyArray(shop),
    geolocation: getNumberArrayOrEmptyArray(geo),
    geolocationName: geo_name || '',
    pageSize: getNumber(page_size, defaultSearchFilters.pageSize),
    baseRateRange: {
      min: getNumber(base_rate_min, defaultSearchFilters.baseRateRange.min),
      max: getNumber(base_rate_max, defaultSearchFilters.baseRateRange.max),
    },
    averageAreaRange: {
      min: getNumber(area_min, defaultSearchFilters.averageAreaRange.min),
      max: getNumber(area_max, defaultSearchFilters.averageAreaRange.max),
    },
    groupSizeRange: {
      min: getNumber(amount_min, defaultSearchFilters.groupSizeRange.min),
      max: getNumber(amount_max, defaultSearchFilters.groupSizeRange.max),
    },
    ordering: getValidOrdering(ordering, defaultSearchFilters.ordering),
  };
}

export function mapGroupSearchFiltersToFiltersTransferObject(searchFilters) {
  return omitBy({
    page: searchFilters.page,
    shop: searchFilters.shop,
    geo: searchFilters.geolocation,
    geo_name: searchFilters.geolocationName,
    page_size: searchFilters.pageSize,
    base_rate_min: searchFilters.baseRateRange.min,
    base_rate_max: searchFilters.baseRateRange.max,
    area_min: searchFilters.averageAreaRange.min,
    area_max: searchFilters.averageAreaRange.max,
    amount_min: searchFilters.groupSizeRange.min,
    amount_max: searchFilters.groupSizeRange.max,
    ordering: searchFilters.ordering,
  }, value => value === null || (Array.isArray(value) && value.length === 0) || value === '');
}

export function mapSearchBoundsTransferObjectToSearchBounds({
  count = 0,
  total_count = 0,
  min_area = 0,
  max_area = 0,
  min_rate = 0,
  max_rate = 0,
}) {
  return {
    foundItemsAmount: count,
    totalItemsAmount: total_count,
    areaRange: {
      min: min_area,
      max: max_area,
    },
    baseRateRange: {
      min: min_rate,
      max: max_rate,
    },
  };
}

export function mapGroupSearchBoundsTransferObjectToGroupSearchBounds({
  count = 0,
  min_rate = 0,
  max_rate = 0,
  min_area = 0,
  max_area = 0,
  min_amount = 0,
  max_amount = 0,
}, initial = false) {
  return {
    foundItemsAmount: count,
    totalItemsAmount: initial ? count : undefined,
    averageAreaRange: {
      min: min_area,
      max: max_area,
    },
    baseRateRange: {
      min: min_rate,
      max: max_rate,
    },
    groupSizeRange: {
      min: min_amount,
      max: max_amount,
    },
  };
}
