import OrganizationAPI from '../../../services/organizationAPI';
import { GeolocationRegion } from './GeolocationRegion';

export class GeolocationDetector {
  organizationAPI = new OrganizationAPI();

  constructor(regions) {
    this.regions = regions;
  }

  async getDetectedRegion() {
    return this.getSavedRegion()
      .catch(this.getRegionByUserCoordinates)
      .catch(this.getDefaultRegion)
      .catch(this.getDefaultRegionUnsafe)
      .catch(this.getFirstProvidedRegion)
      .catch(this.getDefaultHardcodedRegion);
  }

  async getSavedRegion() {
    return new Promise((resolve, reject) => {
      const currentRegionId = localStorage.getItem('currentRegionId');
      const currentRegion = this.regions.find(region => region.id.toString() === currentRegionId);

      if (currentRegion === undefined) {
        reject(new Error('Cannot get saved geolocation'));
      }

      resolve(currentRegion);
    });
  }

  getRegionByUserCoordinates = async () => (
    new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { longitude, latitude } = position.coords;

          this.organizationAPI.getRegionByCoordinates(longitude, latitude)
            .then(region => resolve(region))
            .catch(reject);
        },
        error => reject(error),
      );
    })
  )

  getDefaultRegion = async () => (
    this.organizationAPI.getCurrentAddress(null)
      .then(region => new GeolocationRegion(region.geo_id, region.geo_name))
  )

  getDefaultRegionUnsafe = () => {
    const defaultRegionName = 'Москва';
    const defaultRegion = this.regions.find(region => region.name.includes(defaultRegionName));

    if (defaultRegion === undefined) {
      throw new Error();
    }

    return defaultRegion;
  }

  getFirstProvidedRegion = () => {
    if (this.regions.length === 0) {
      throw new Error();
    }

    return this.regions[0];
  }

  getDefaultHardcodedRegion = () => (
    new GeolocationRegion(240, 'Москва')
  )
}
