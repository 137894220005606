import React, { useRef, useState } from 'react';
import Slider from 'react-slick';

export default function CardMedia({ sources }) {
  const sliderRef = useRef();
  const [currentSlideNumber, setCurrentSlideNumber] = useState(1);

  const goToPreviousSlide = () => {
    sliderRef.current.slickPrev();
  };

  const goToNextSlide = () => {
    sliderRef.current.slickNext();
  };

  const updateCurrentSlideNumber = (index) => {
    setCurrentSlideNumber(index + 1);
  };

  const createEnterKeyHandler = (handler) => event => {
    if (event.key !== 'Enter') return;

    handler();
  };

  return (
    <div className="m-slider">
      <Slider
        ref={sliderRef}
        draggable={false}
        arrows={false}
        speed={500}
        infinite
        afterChange={updateCurrentSlideNumber}
      >
        {sources.map((source, index) => (
          <img
            className="m-slider__slide-img m-slider__slide-img_cover"
            key={index}
            src={source}
            alt={`Фото ${index + 1}`}
          />
        ))}
      </Slider>
      <div
        className="js-mPrevSlide m-slider__prev"
        onClick={goToPreviousSlide}
        tabIndex="0"
        role="button"
        onKeyDown={createEnterKeyHandler(goToPreviousSlide)}
      />
      <div
        className="js-mNextSlide m-slider__next"
        onClick={goToNextSlide}
        tabIndex="0"
        role="button"
        onKeyDown={createEnterKeyHandler(goToNextSlide)}
      />
      <div className="m-slider__index">
        <span className="js-mCurrent">{currentSlideNumber}</span>
        <span className="m-slider__in">из</span>
        <span className="js-mSize">{sources.length}</span>
      </div>
    </div>
  );
}
