import React, {
  useEffect, useReducer, useRef, useState,
} from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';

import _ from 'lodash';
import Input from '../../ui/Form/Input';
import PhoneInput from '../../ui/Form/PhoneInput';
import Textarea from '../../ui/Form/Textarea';
import Checkbox from '../../ui/Form/Checkbox';
import Dropdown from '../../ui/Layout/Dropdown';
import FormField from '../../ui/Form/FormField';

import SpacesAPI from '../../../services/spacesAPI';
import { registerActions } from '../../../store';
import CustomCaptcha from '../../ui/CustomCaptcha/CustomCaptcha';
import { privacyPolicyLink } from '../../../shared/constants';
import { isFieldValid } from '../../../shared/Question/validation';
import { RequestHeader, RequestSuccess } from './index';
import { isErrorCode } from '../../../shared/utils/isErrorCode';
import { RequestDeclined } from './RequestDeclined';

const spacesAPI = new SpacesAPI();

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'state':
      return { ..._.omitBy(payload, _.isNull) };
    case 'email':
      return { ...state, email: payload };
    case 'phone':
      return { ...state, phone: payload };
    case 'first_name':
      return { ...state, first_name: payload };
    case 'last_name':
      return { ...state, last_name: payload };
    case 'middle_name':
      return { ...state, middle_name: payload };
    case 'activity':
      return { ...state, activity: payload };
    case 'comment':
      return { ...state, comment: payload };
    case 'agreement':
      return { ...state, agreement: payload };
    case 'captchaToken':
      return { ...state, captchaToken: payload };
    case 'successfullySent':
      return { ...state, successfullySent: payload };
    case 'error':
      return { ...state, error: payload };
    default:
      throw new Error('Current field no exist');
  }
};

const GroupRequest = ({
  dispatch,
  history,
  dropdownData,
  userData,
  ...props
}) => {
  const [disabled, setDisabled] = useState(false);
  const captchaRef = useRef();
  const wrapperRef = useRef();

  const [state, dispatchState] = useReducer(reducer, {
    agreement: false,
    email: '',
    phone: '',
    first_name: '',
    last_name: '',
    middle_name: '',
    activity: 0,
    comment: '',
    captchaToken: '',
    successfullySent: false,
    error: { status: undefined },
  });

  const [invalidFields, setInvalidFields] = useState([]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    dispatchState({
      type: 'state',
      payload: {
        ...state,
        email: userData.email,
        phone: userData.phone,
        first_name: userData.first_name,
        last_name: userData.last_name,
        middle_name: userData.middle_name,
        captchaToken: '',
      },
    });
    dispatch(registerActions.getDropdownData());
    document.addEventListener('keydown', closeOnEsc);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', closeOnEsc);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeOnEsc = e => {
    if (e.keyCode === 27) {
      props.close();
    }
  };
  const updateState = ({ target: { name, value } }) => {
    if (name === 'middle_name') return dispatchState({ type: name, payload: value });
    if (!isFieldValid(name, value)) {
      setInvalidFields([...invalidFields, name]);
    }
    if (isFieldValid(name, value)) {
      setInvalidFields(invalidFields.filter(field => field !== name));
    }
    dispatchState({ type: name, payload: value });
  };

  const onChangeActivity = (value) => {
    if (invalidFields.includes('activity')) {
      setInvalidFields(invalidFields.filter(field => field !== 'activity'));
    }
    dispatchState({
      type: 'activity',
      payload: activityId(value),
    });
  };

  const toggleAgreement = () => {
    if (invalidFields.includes('agreement')) setInvalidFields(invalidFields.filter(field => field !== 'agreement'));
    dispatchState({ type: 'agreement', payload: !state.agreement });
  };

  const validateAgreement = isAgreement => new Promise((resolve, reject) => {
    if (isAgreement) {
      resolve();
    } else {
      reject();
    }
  });

  const parseActivity = id => {
    const { activities } = dropdownData;
    const filteredArray = activities.filter(el => +el.id === +id);
    if (filteredArray.length < 1) return id;
    return filteredArray[0].name;
  };

  const activityId = value => {
    const { activities } = dropdownData;
    return activities.find(el => el.name === value).id;
  };

  const submit = async (e) => {
    e.preventDefault();
    await captchaRef.current.verify();
  };

  const sendData = (captchaToken) => {
    const { data: { id }, group, afterRequestHook } = props;

    const data = {
      ...state,
      captchaToken,
      phone: state.phone.replace(/\D+/g, ''),
    };

    if (!state.activity) {
      setInvalidFields([...invalidFields, 'activity']);
      return;
    }

    setDisabled(true);

    if (group) {
      spacesAPI.groupRequest(id, data)
        .then(({ response }) => {
          if (isErrorCode(response?.status)) {
            dispatchState({ type: 'error', payload: response.data });
          } else {
            dispatchState({ type: 'successfullySent', payload: true });
            afterRequestHook();
          }
        });
    } else {
      spacesAPI.spaceRequest(id, data)
        .then(({ response }) => {
          if (isErrorCode(response?.status)) {
            dispatchState({ type: 'error', payload: { code: response.status, text: response.statusText } });
          } else {
            dispatchState({ type: 'successfullySent', payload: true });
            afterRequestHook();
          }
        });
    }
  };

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      props.close();
    }
  };

  const showAgreementShouldCheck = () => {
    setInvalidFields(value => [...value, 'agreement']);
    throw new Error('Необходимо согласиться с условиями');
  };

  const validateFormFields = () => new Promise((resolve, reject) => {
    if (invalidFields.length === 0) resolve();
    reject();
  });

  const handleSuccessfulCaptchaValidation = (captchaToken) => {
    validateAgreement(state.agreement)
      .catch(showAgreementShouldCheck)
      .then(validateFormFields)
      .then(() => sendData(captchaToken))
      .catch(() => undefined);
  };

  const getFieldValidStatus = field => !invalidFields.includes(field) && state[field]?.length > 2;
  const isFieldInvalid = field => invalidFields.includes(field);

  const {
    close, dropdownDataRequested, group, data,
  } = props;
  if (state.error.code) return <RequestDeclined close={close} error={state.error} />;
  if (state.successfullySent) return <RequestSuccess close={close} />;
  return (
    <div id="orderset" ref={wrapperRef} className="popup--order">
      <RequestHeader group={group} data={data} />

      <div className="popup__inner popup__inner--40">
        <form className="popup__form" onSubmit={submit}>
          <div className="form-group">
            <h3 className="h3 form-h3">Персональные данные</h3>
            <FormField className={cx({ 'form-field--ok': getFieldValidStatus('last_name') })}>
              <Input
                type="text"
                className={cx('i-input', {
                  'vf-error': isFieldInvalid('last_name'),
                })}
                placeholder="Фамилия"
                onChange={updateState}
                name="last_name"
                value={state.last_name}
              />
            </FormField>
            <FormField className={cx({ 'form-field--ok': getFieldValidStatus('first_name') })}>
              <Input
                type="text"
                className={cx('i-input', {
                  'vf-error': isFieldInvalid('first_name'),
                })}
                placeholder="Имя"
                onChange={updateState}
                name="first_name"
                value={state.first_name}
              />
            </FormField>
            <FormField className={cx({ 'form-field--ok': getFieldValidStatus('middle_name') })}>
              <Input
                type="text"
                className={cx('i-input', {
                  'vf-error': isFieldInvalid('middle_name'),
                })}
                placeholder="Отчество (не обязательно)"
                onChange={updateState}
                name="middle_name"
                value={state.middle_name}
              />
            </FormField>
          </div>
          <div className="form-group">
            <h3 className="h3 form-h3">Вид деятельности</h3>
            <FormField
              className={cx({ 'form-field--ok': getFieldValidStatus('activity') })}
              tip="Выберите основной вид деятельности"
            >
              {!dropdownDataRequested && dropdownData && (
              <Dropdown
                options={dropdownData.activities}
                placeholder="Деятельность 1 (основной вид)"
                value={parseActivity(state.activity)}
                onChange={dropdownValue => onChangeActivity(dropdownValue)}
                error={isFieldInvalid('activity')}
                optionsWithId
              />
              )}
            </FormField>
          </div>
          <div className="form-group">
            <h3 className="h3 form-h3">Контакты для обратной связи</h3>
            <FormField className={cx({ 'form-field--ok': getFieldValidStatus('email') })}>
              <Input
                type="text"
                className={cx('i-input', {
                  'vf-error': isFieldInvalid('email'),
                })}
                placeholder="E-mail"
                onChange={updateState}
                name="email"
                value={state.email}
              />
            </FormField>
            <FormField className={cx({ 'form-field--ok': getFieldValidStatus('phone') })}>
              <PhoneInput
                className={cx('i-input', {
                  'vf-error': isFieldInvalid('phone'),
                })}
                type="text"
                placeholder="Телефон (не обязательно)"
                onChange={updateState}
                name="phone"
                value={state.phone}
              />
            </FormField>
            <FormField>
              <Textarea
                className="i-input popup__question"
                placeholder="Комментарий к заявке (не обязательно)"
                onChange={updateState}
                name="comment"
                value={state.comment}
              />
            </FormField>

            <FormField>
              <CustomCaptcha
                ref={captchaRef}
                onSuccess={handleSuccessfulCaptchaValidation}
              />
            </FormField>

            <FormField>
              <Checkbox
                required
                small
                toggle={toggleAgreement}
                checked={state.agreement}
                className={isFieldInvalid('agreement') && 'error'}
              >
                Cогласие с{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={privacyPolicyLink}
                >
                  Политикой в отношении обработки персональных данных
                </a>
              </Checkbox>
            </FormField>
          </div>
          {state.error && <div className="form-line-bottom">{Object.values(state.error)}</div>}
          <div className="form-line-bottom">
            <button
              disabled={disabled || false}
              onClick={submit}
              type="submit"
              className="i-button i-button--200"
            >
              подать заявку
            </button>
            <button
              type="button"
              onClick={close}
              className="i-button i-button--200 i-button--white"
            >
              Отменить
            </button>
          </div>
        </form>
      </div>
      <button onClick={close} type="button" className="popup-close" title="Закрыть">
        <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24">
          <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
        </svg>
      </button>
    </div>
  );
};

const mapStateToProps = state => ({
  dropdownData: state.register.dropdownData,
  dropdownDataRequested: state.register.dropdownDataRequested,
  userData: state.login.userData,
});

export default connect(mapStateToProps)(GroupRequest);
