import { useCallback } from 'react';
import useEventListener from './useEventListener';

export default function useEscapeListener(callback) {
  const escapeCallback = useCallback((event) => {
    if (event.key === 'Escape') callback();
  }, [callback]);

  useEventListener('keydown', escapeCallback, document);
}
