import React, { Component } from 'react';
import InputMask from 'react-input-mask';

import FilterWrapper from '../../../../ui/Layout/FilterWrapper';

import formatCurrency from '../../../../../shared/utils/formatCurrency';
import { scrollTop } from '../../../../../shared/utils/ScrollToTop';
import CategoryFilter from '../../Filters/FilterCategory';

class Sidebar extends Component {
  state = {
    categoriesFull: false,
  };

  toggle = () => {
    this.setState(prev => ({ categoriesFull: !prev.categoriesFull }));
  };


  toggleAllByGroupId = categories => addCategories => {
    const { searchFilters, setSelectedCategoryIds } = this.props;
    const selected = categories
      .reduce((acc, { id }) => [...acc, id], []);

    if (addCategories) {
      const result = searchFilters.categories.filter(category => !selected.includes(category));
      setSelectedCategoryIds([...result]);
    } else {
      const result = new Set([...selected, ...searchFilters.categories]);
      setSelectedCategoryIds([...result]);
    }
  };

  render() {
    const {
      baseRateMin,
      baseRateMax,
      areaMin,
      areaMax,
      filtersLoading,
      setData,
      searchFilters,
      setSelectedCategoryIds,
      reset,
      search,
      filtersMaxArea,
      filtersMinArea,
      filtersMaxRate,
      filtersMinRate,
      availableCategoriesWithoutGroups,
      availableCategoriesWithGroups,
    } = this.props;

    return (
      <>
        <div className="find__sidebar-notice">
          Цены указаны с НДС, являются <br />
          предварительными и могут быть изменены
        </div>
        <div className="filters">
          <FilterWrapper title="Размер площади, м²">
            <div className="filter__range">
              <div className="filter__range-row">
                <div className="filter__range-input">
                  <InputMask
                    mask="9999"
                    type="text"
                    className="i-input"
                    placeholder={`От ${filtersMinArea || 0}`}
                    maskChar={null}
                    name="areaMin"
                    value={areaMin}
                    onChange={setData}
                  />
                </div>
                <div className="filter__range-between" />
                <div className="filter__range-input">
                  <InputMask
                    type="text"
                    className="i-input"
                    placeholder={`До ${filtersMaxArea || 0}`}
                    mask="9999"
                    maskChar={null}
                    name="areaMax"
                    value={areaMax}
                    onChange={setData}
                  />
                </div>
              </div>
              <div className="filter__range-unit">м²</div>
            </div>
          </FilterWrapper>
          <FilterWrapper title="Стоимость аренды, ₽">
            <div className="filter__range">
              <div className="filter__range-row">
                <div className="filter__range-input">
                  <InputMask
                    type="text"
                    className="i-input"
                    placeholder={`От ${formatCurrency(filtersMinRate || 0)}`}
                    mask="999999"
                    maskChar={null}
                    name="baseRateMin"
                    value={baseRateMin}
                    onChange={setData}
                  />
                </div>
                <div className="filter__range-between" />
                <div className="filter__range-input">
                  <InputMask
                    type="text"
                    className="i-input"
                    placeholder={`До ${formatCurrency(filtersMaxRate || 0)}`}
                    mask="999999"
                    maskChar={null}
                    name="baseRateMax"
                    value={baseRateMax}
                    onChange={setData}
                  />
                </div>
              </div>
              <div className="filter__range-unit">₽</div>
            </div>
          </FilterWrapper>
          {!filtersLoading && (
            <>
              <FilterWrapper title="Категория площади">
                {availableCategoriesWithGroups.map(group => (
                  group.categories.length > 0
                    && (
                      <CategoryFilter
                        key={group.id}
                        availableCategories={group.categories}
                        selectedItemIds={searchFilters.categories}
                        onChange={setSelectedCategoryIds}
                        toggleAll={this.toggleAllByGroupId(group.categories)}
                        title={group.name}
                      />
                    )
                ))}
                {availableCategoriesWithoutGroups.length > 0 && (
                <CategoryFilter
                  availableCategories={availableCategoriesWithoutGroups}
                  selectedItemIds={searchFilters.categories}
                  onChange={setSelectedCategoryIds}
                  toggleAll={this.toggleAllByGroupId(availableCategoriesWithoutGroups)}
                  title="Без группы"
                />
                )}
              </FilterWrapper>
            </>
          )}

          <div className="filter-reset-apply">
            <div className="filter-apply-btn">
              <span
                role="button"
                tabIndex="0"
                onKeyDown={scrollTop(search, 200)}
                onClick={scrollTop(search, 200)}
                className="i-button i-button--small i-button--135"
              >
                Применить
              </span>
            </div>
            <span
              role="button"
              tabIndex="0"
              onClick={reset}
              onKeyDown={reset}
              className="filter-reset-btn"
            >
              Сбросить
            </span>
          </div>
        </div>
      </>
    );
  }
}

export default Sidebar;
