import React from 'react';

const Card = ({
  title, description, actionPeriod, image,
}) => (
  <div className="offer__item">
    <div className="offer__item-inner">
      <div className="offer__photo">
        <img src={image} alt="" />
      </div>
      <div className="offer__body">
        <div className="offer__months">{actionPeriod}</div>
        <h3 className="h3 offer__title">{title}</h3>
        <p className="offer__text">{description}</p>
      </div>
    </div>
  </div>
);

export default Card;
