import axios from 'axios';

export default class DocumentsAPI {
  _apiBase = `${process.env.REACT_APP_API_BASE}/documents`;

  static _defaultErrorMessage = 'Произошла ошибка. Пожалуйста, попробуйте позже, либо обратитесь к администратору.';

  getRequests = async (page, type) => {
    // eslint-disable-next-line no-useless-escape
    const Token = document.cookie.replace(/(?:(?:^|.*;\s*)Token\s*\=\s*([^;]*).*$)|^.*$/, '$1');
    try {
      let res;
      switch (type) {
        case 'all':
          res = await axios.get(`${this._apiBase}/requests/?page=${page}`, {
            headers: {
              Authorization: `Token ${Token}`,
            },
          });
          return res;
        case 'group':
          res = await axios.get(`${this._apiBase}/requests/?page=${page}&is_group=true`, {
            headers: {
              Authorization: `Token ${Token}`,
            },
          });
          return res;
        case 'space':
          res = await axios.get(`${this._apiBase}/requests/?page=${page}&is_group=false`, {
            headers: {
              Authorization: `Token ${Token}`,
            },
          });
          return res;
        default:
          res = await axios.get(`${this._apiBase}/requests/?page=${page}`, {
            headers: {
              Authorization: `Token ${Token}`,
            },
          });
          return res;
      }
    } catch (e) {
      return e;
    }
  };

  getContracts = async () => {
    // eslint-disable-next-line no-useless-escape
    const Token = document.cookie.replace(/(?:(?:^|.*;\s*)Token\s*\=\s*([^;]*).*$)|^.*$/, '$1');
    try {
      const res = await axios.get(`${this._apiBase}/contracts/`, {
        headers: {
          Authorization: `Token ${Token}`,
        },
      });
      return res;
    } catch (e) {
      return e;
    }
  };

  getContract = async id => {
    // eslint-disable-next-line no-useless-escape
    const Token = document.cookie.replace(/(?:(?:^|.*;\s*)Token\s*\=\s*([^;]*).*$)|^.*$/, '$1');
    try {
      const res = await axios.get(`${this._apiBase}/contracts/${id}/`, {
        headers: {
          Authorization: `Token ${Token}`,
        },
      });
      return res;
    } catch (e) {
      return e;
    }
  };

  getPayments = async (id, search) => {
    // eslint-disable-next-line no-useless-escape
    const Token = document.cookie.replace(/(?:(?:^|.*;\s*)Token\s*\=\s*([^;]*).*$)|^.*$/, '$1');
    try {
      const res = await axios.get(`${this._apiBase}/contracts/${id}/payments/${search}`, {
        headers: {
          Authorization: `Token ${Token}`,
        },
      });
      return res;
    } catch (e) {
      return e;
    }
  };

  confirmRequest = (organizationId, uuid) => axios
    .get(`${this._apiBase}/organizations/${organizationId}/confirm/${uuid}`)
    .then(DocumentsAPI._getMessageFromRequestConfirmationResponse)
    .catch(DocumentsAPI._getMessageFromRequestConfirmationResponseError);

  declineRequest = (organizationId, uuid) => axios
    .get(`${this._apiBase}/organizations/${organizationId}/decline/${uuid}`)
    .then(DocumentsAPI._getMessageFromRequestConfirmationResponse)
    .catch(DocumentsAPI._getMessageFromRequestConfirmationResponseError);

  static _getMessageFromRequestConfirmationResponse(response) {
    if (response.status === 200) {
      return response.data.detail;
    }

    return DocumentsAPI._defaultErrorMessage;
  }

  static _getMessageFromRequestConfirmationResponseError(error) {
    if (error.response.status === 400) {
      return error.response.data.detail;
    }

    if (error.response.status === 404) {
      return 'Станица не найдена';
    }

    return DocumentsAPI._defaultErrorMessage;
  }
}
