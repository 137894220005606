import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import formatCurrency from '../../../../../shared/utils/formatCurrency';

class Card extends Component {
  state = {};

  render() {
    const {
      askoId, id, name, totalArea, rate, space, description, leaseTerm, regions,
    } = this.props;
    return (
      <div className="card card--groups">
        <div className="card__inner">
          <div className="card__header">
            <h2 className="card__name">
              <Link target="_blank" rel="noopener noreferrer" to={`/find/group/${id}/`}>
                {name}
              </Link>
            </h2>
            <div className="card__id">
              <div className="card__id-num">№ {askoId}</div>
              <div className="card__id-name">{regions && regions.map(el => el.name).join(', ')}</div>
            </div>
          </div>
          <div className="card__features">
            <div className="card__features-item">
              <div className="card__features-lines">
                <div className="card__features-val">
                  {totalArea} <small>м²</small>
                </div>
                <div className="card__features-notice">
                  в среднем {totalArea && space ? Math.floor(totalArea / space) : ''} м²
                </div>
              </div>
            </div>
            <div className="card__features-item">
              <div className="card__features-lines">
                <div className="card__features-val">{space}</div>
                <div className="card__features-notice">площадей</div>
              </div>
            </div>
            <div className="card__features-item">
              <div className="card__features-lines">
                <div className="card__features-val">
                  <small>от </small>
                  {formatCurrency(rate)}
                  <small> ₽/мес</small>
                </div>
                <div className="card__features-notice">
                  средняя цена {formatCurrency(rate / totalArea)} ₽/м²
                </div>
              </div>
            </div>
            <div className="card__features-item">
              <div className="card__features-lines">
                <div className="card__features-val">
                  {leaseTerm} <small>мес</small>
                </div>
                <div className="card__features-notice">с возможной пролонгацией</div>
              </div>
            </div>
          </div>
          <div className="card__bottom">
            <div className="card__description">{description}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
