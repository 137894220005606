import React from 'react';

const Tooltip = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM10.7885 8.31984C11.0205 8.02384 11.1365 7.60784 11.1365 7.07184C11.1365 6.35984 10.9005 5.81184 10.4285 5.42784C9.95647 5.03584 9.36047 4.83984 8.64047 4.83984C8.16047 4.83984 7.74847 4.89584 7.40447 5.00784C7.06047 5.11184 6.75247 5.25184 6.48047 5.42784V7.23984H6.73247C6.92447 7.01584 7.15647 6.81984 7.42847 6.65184C7.70847 6.48384 8.02847 6.39984 8.38847 6.39984C8.70047 6.39984 8.93647 6.48384 9.09647 6.65184C9.26447 6.81984 9.34847 7.05184 9.34847 7.34784C9.34847 7.62784 9.24047 7.86384 9.02447 8.05584C8.80847 8.24784 8.58447 8.45584 8.35247 8.67984C8.12047 8.90384 7.92847 9.17584 7.77647 9.49584C7.62447 9.81584 7.61247 10.2438 7.74047 10.7798H9.33647C9.23247 10.3558 9.26847 10.0278 9.44447 9.79584C9.62047 9.55584 9.83247 9.32384 10.0805 9.09984C10.3285 8.87584 10.5645 8.61584 10.7885 8.31984ZM9.18047 12.8678C9.36447 12.6998 9.45647 12.4798 9.45647 12.2078C9.45647 11.9358 9.36447 11.7158 9.18047 11.5478C9.00447 11.3798 8.78047 11.2958 8.50847 11.2958C8.24447 11.2958 8.02047 11.3838 7.83647 11.5598C7.66047 11.7278 7.57247 11.9438 7.57247 12.2078C7.57247 12.4798 7.66047 12.6998 7.83647 12.8678C8.02047 13.0358 8.24447 13.1198 8.50847 13.1198C8.78047 13.1198 9.00447 13.0358 9.18047 12.8678Z"
      fill="#999999"
    />
  </svg>
);

export default Tooltip;
