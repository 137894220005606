import React, { useEffect, useReducer, useRef } from 'react';
import cx from 'classnames';
import InputMask from 'react-input-mask';
import { getBlockEnterKeyHandler } from '../../../../../shared/utils/getBlockEnterKeyHandler';
import FormField from '../../../../ui/Form/FormField';
import Dropdown from '../../../../ui/Layout/Dropdown';
import {
  createEmptyIndividualBeneficiary,
  createEmptyLegalBeneficiary,
  getDefaultForm, getDefaultValidation,
  SIGN_TYPE,
} from '../../../../../shared/constants/Profile';
import Input from '../../../../ui/Form/Input';
import INNInput from '../../../../ui/Form/INNInput';
import KPPInput from '../../../../ui/Form/KPPInput';
import Textarea from '../../../../ui/Form/Textarea';
import DatePicker from '../../../../ui/Form/DatePicker';
import PhoneInput from '../../../../ui/Form/PhoneInput';
import MembersItem from '../MembersItem';
import IndividualBeneficiariesItem from '../IndividualBeneficiariesItem';
import LegalBeneficiariesItem from '../LegalBeneficiariesItem';
import Plus from '../../../../ui/svg/plus';
import OrganizationAPI from '../../../../../services/organizationAPI';
import profileReducer from './profileReducers';
import { ProfileActions } from './profileActions';
import { mapFormToTransferObject, mapTransferObjectToForm, omitFalseValues } from '../mappers';
import { registerActions } from '../../../../../store';

const organizationApi = new OrganizationAPI();

const reducer = (state, action) => {
  const { type, payload: { value, field } } = action;
  switch (type) {
    case 'SET_FIELD':
      return {
        ...state,
        [field]: value,
      };
    case 'TOGGLE_VALUE':
      return {
        ...state,
        [field]: !value,
      };
    default:
      throw new Error('No current field on state');
  }
};

const PartnerForm = ({
  dispatch,
  history,
  dropdownData,
  ...props
}) => {
  const [state, dispatchState] = useReducer(reducer, {
    successfullySent: false,
    regDataOpened: true,
    individualOpened: true,
    beneficiariesOpened: true,
    loading: true,
    signs: [],
  });
  const [form, dispatchForm] = useReducer(profileReducer, getDefaultForm());
  const [invalidFields, dispatchInvalidFields] = useReducer(profileReducer, getDefaultValidation());

  const formRef = useRef();

  const getOrganizationNameByTypeId = typeId => {
    try {
      const { types } = dropdownData;

      return types.find(type => type.id === typeId).name;
    } catch {
      return null;
    }
  };

  const getOrganizationTypeIdByName = name => {
    try {
      const { types } = dropdownData;

      return types.find(type => type.name === name).id;
    } catch {
      return null;
    }
  };
  const validateDispatchForm = (action) => {
    const { type } = action;
    let { payload } = action;
    if (type === 'ADD_LEGAL_BENEFICIARY') payload = createEmptyLegalBeneficiary();
    if (type === 'ADD_INDIVIDUAL_BENEFICIARY') payload = createEmptyIndividualBeneficiary();

    dispatchForm({ type, payload });
    handleValidation({ type, payload });
  };

  const profileActions = new ProfileActions(validateDispatchForm);

  const handleValidation = ({ type, payload }) => {
    const { value, filter, length } = payload;
    const isAddBeneficiaryType = ['ADD_LEGAL_BENEFICIARY', 'ADD_INDIVIDUAL_BENEFICIARY'].includes(type);

    if (isAddBeneficiaryType) {
      dispatchInvalidFields({ type, payload });
    }

    if (value === '') {
      dispatchInvalidFields({ type, payload: { filter, value: true } });
    } else if (value && (value.length > 0 || value > 0)) {
      dispatchInvalidFields({ type, payload: { filter, value: false } });
    }

    if (length <= 0) {
      dispatchInvalidFields({ type, payload: true });
    } else if (length > 1 || payload > 0) {
      dispatchInvalidFields({ type, payload: false });
    }
  };

  const toggleRegData = () => {
    dispatchState({
      type: 'TOGGLE_VALUE',
      payload: {
        field: 'regDataOpened',
        value: state.regDataOpened,
      },
    });
  };

  const toggleIndividual = () => {
    dispatchState({
      type: 'TOGGLE_VALUE',
      payload: {
        field: 'individualOpened',
        value: state.individualOpened,
      },
    });
  };

  const toggleBeneficiaries = () => {
    dispatchState({
      type: 'TOGGLE_VALUE',
      payload: {
        field: 'beneficiariesOpened',
        value: state.beneficiariesOpened,
      },
    });
  };

  const setCompanySign = (value) => {
    profileActions.setCompanySign(getSignIdByName(value));
  };

  const getSignNameById = id => {
    try {
      return signs.find(sign => sign.id === id).name;
    } catch {
      return null;
    }
  };

  const getSignIdByName = name => {
    try {
      return signs.find(sign => sign.name === name).id;
    } catch {
      return null;
    }
  };


  const loadForm = async () => {
    const formTransferObject = await organizationApi.getProfile();
    const transferInvalidFields = omitFalseValues(mapTransferObjectToForm({ ...formTransferObject }));
    dispatchInvalidFields({ type: 'SET_INITIAL_STATE', payload: transferInvalidFields });

    profileActions.setInitialState(mapTransferObjectToForm(formTransferObject));


    dispatchState({
      type: 'SET_FIELD',
      payload: {
        field: 'loading',
        value: false,
      },
    });
  };

  useEffect(() => {
    Promise.all([loadSigns(), loadForm()]);

    dispatch(registerActions.getDropdownData());
  }, []);


  const loadSigns = async () => {
    const signs = await organizationApi.getSigns();

    dispatchState({
      type: 'SET_FIELD',
      payload: {
        field: 'signs',
        value: signs,
      },
    });
    dispatchState({
      type: 'SET_FIELD',
      payload: {
        field: 'signsLoaded',
        value: true,
      },
    });
  };

  const submit = () => {
    const dataToPush = mapFormToTransferObject(form);

    organizationApi.patchProfile(dataToPush)
      .then(() => {
        dispatchState({
          type: 'SET_FIELD',
          payload: { field: 'successfullySent', value: true },
        });
      })
      .catch(error => {
        const firstErrorElement = formRef.current.querySelector('.vf-error');
        dispatchInvalidFields({ type: 'SET_INITIAL_STATE', payload: { ...invalidFields, ...error } });

        try {
          firstErrorElement.scrollIntoView({
            behavior: 'smooth',
          });
        } catch {
          formRef.current.scrollIntoView({
            behavior: 'smooth',
          });
        }
      });
  };

  const organizationTypeName = getOrganizationNameByTypeId(form.type);
  const isOrganizationIPOrSelfEmployee = (organizationTypeName === 'ИП' || organizationTypeName === 'Самозанятый');

  const {
    regDataOpened,
    individualOpened,
    beneficiariesOpened,
    loading,
    successfullySent,
    signs,
    signsLoaded,
  } = state;

  if (loading) return 'Load...';

  if (successfullySent) {
    return (
      <div className="form-wrap">
        <h4 className="form-h4">Анкета сохранена и отправлена</h4>
      </div>
    );
  }
  return (

    <div className="lk-content lk-profile">
      <h1 className="h2 lk-profile__title">Анкета партнёра</h1>
      <div className="lk-profile__under-title">Все поля обязательные для заполнения</div>
      <form ref={formRef} action="">
        <div className="form-wrap">
          <div className="form-group">
            <div
              className="lk-profile__header js-profileTabTitle"
              role="tab"
              tabIndex="0"
              onClick={toggleRegData}
              onKeyDown={getBlockEnterKeyHandler(toggleRegData)}
            >
              <h3 className="h3 form-h3 lk-profile__header-h3">
                <span
                  className={cx('lk-profile__header-title', {
                    'is-open': regDataOpened,
                  })}
                >
                  1. Регистрационные данные компании
                </span>
              </h3>
            </div>
            <div
              style={{ display: regDataOpened ? 'block' : 'none' }}
              className="lk-profile__body js-profileTabBody"
            >
              <div className="lk-profile__body-inner">
                <FormField label="Признак компании">
                  {signsLoaded && (
                  <Dropdown
                    placeholder="Выберите из списка"
                    options={signs}
                    value={getSignNameById(form.companySign)}
                    error={invalidFields.companySign}
                    onChange={setCompanySign}
                    optionsWithId
                  />
                  )}
                </FormField>
                {form.companySign === SIGN_TYPE.NOT_RESIDENT && (
                <FormField>
                  <Input
                    className={cx({ 'vf-error': invalidFields.englishName })}
                    value={form.englishName}
                    onChange={profileActions.setEnglishName}
                    placeholder="Наименование на английском языке"
                  />
                </FormField>
                )}
                <FormField>
                  <Input
                    className={cx({ 'vf-error': invalidFields.fullName })}
                    value={form.fullName}
                    onChange={profileActions.setFullName}
                    placeholder="Полное наименование"
                  />
                </FormField>
                <FormField>
                  <Input
                    className={cx({ 'vf-error': invalidFields.shortName })}
                    value={form.shortName}
                    onChange={profileActions.setShortName}
                    placeholder="Сокращённое наименование"
                  />
                </FormField>

                <FormField label="Формы собственности">
                  {dropdownData && (
                  <Dropdown
                    options={dropdownData.types}
                    optionsWithId
                    placeholder="Выберите из списка"
                    value={getOrganizationNameByTypeId(form.organization.type)}
                    error={invalidFields.organization.type < 0}
                    onChange={value => profileActions.setOrganizationType(
                      getOrganizationTypeIdByName(value),
                    )}
                  />
                  )}
                </FormField>

                {!isOrganizationIPOrSelfEmployee && (
                <FormField>
                  <InputMask
                    className={cx('i-input', { 'vf-error': invalidFields.organization.OGRN })}
                    value={form.organization.OGRN}
                    onChange={profileActions.setOrganizationOGRN}
                    mask="999999999999999"
                    maskChar={null}
                    placeholder="ОГРН/ОГРНИП"
                  />
                </FormField>
                )}
                <FormField>
                  <INNInput
                    className={cx({ 'vf-error': invalidFields.organization.INN })}
                    value={form.organization.INN}
                    onChange={profileActions.setOrganizationINN}
                    placeholder="КПП"
                  />
                </FormField>
                {!isOrganizationIPOrSelfEmployee && (
                <FormField>
                  <KPPInput
                    className={cx({ 'vf-error': invalidFields.organization.KPP })}
                    value={form.organization.KPP}
                    onChange={profileActions.setOrganizationKPP}
                    placeholder="КПП"
                  />
                </FormField>
                )}
                <FormField>
                  <InputMask
                    className={cx('i-input', { 'vf-error': invalidFields.organization.OKPO })}
                    value={form.organization.OKPO}
                    onChange={profileActions.setOrganizationOKPO}
                    placeholder="ОКПО"
                    mask="9999999999"
                    maskChar={null}
                  />
                </FormField>
                <FormField>
                  <InputMask
                    className={cx('i-input', { 'vf-error': invalidFields.organization.OKVED })}
                    value={form.organization.OKVED}
                    onChange={profileActions.setOrganizationOKVED}
                    placeholder="ОКВЭД"
                    mask="9999999999"
                    maskChar={null}
                    formatChars={{
                      9: '[0-9, .]',
                    }}
                  />
                </FormField>
                <FormField>
                  <InputMask
                    className={cx('i-input', { 'vf-error': invalidFields.organization.OKONX })}
                    value={form.organization.OKONX}
                    onChange={profileActions.setOrganizationOKONX}
                    placeholder="ОКОНХ"
                    mask="99999"
                    maskChar={null}
                  />
                </FormField>
                <FormField>
                  <Textarea
                    className={cx({ 'vf-error': invalidFields.organization.legalAddress })}
                    value={form.organization.legalAddress}
                    onChange={profileActions.setOrganizationLegalAddress}
                    placeholder="Юридический адрес"
                  />
                </FormField>
                <FormField>
                  <Textarea
                    className={cx({ 'vf-error': invalidFields.organization.physicalAddress })}
                    value={form.organization.physicalAddress}
                    onChange={profileActions.setOrganizationPhysicalAddress}
                    placeholder="Фактический адрес"
                  />
                </FormField>
                <FormField label="Дата регистрации">
                  <DatePicker
                    error={invalidFields.registration.date}
                    currentDate={form.registration.date}
                    handleChange={profileActions.setRegistrationDate}
                  />
                </FormField>
                <FormField>
                  <Textarea
                    className={cx({ 'vf-error': invalidFields.registration.authorityName })}
                    value={form.registration.authorityName}
                    onChange={profileActions.setRegistrationAuthorityName}
                    placeholder="Наименование и номер регистрирующего органа"
                  />
                </FormField>
                <FormField>
                  <PhoneInput
                    className={cx({ 'vf-error': invalidFields.phone })}
                    value={form.phone}
                    onChange={profileActions.setPhone}
                    placeholder="Телефон"
                    я
                  />
                </FormField>
                <FormField>
                  <Input
                    className={cx({ 'vf-error': invalidFields.email })}
                    value={form.email}
                    onChange={profileActions.setEmail}
                    placeholder="E-mail"
                  />
                </FormField>

                <h4 className="form-h4">Реквизиты</h4>

                <FormField>
                  <Input
                    className={cx({ 'vf-error': invalidFields.bank.country })}
                    value={form.bank.country}
                    onChange={profileActions.setBankCountry}
                    placeholder="Страна банка"
                  />
                </FormField>
                <FormField>
                  <Input
                    className={cx({ 'vf-error': invalidFields.bank.name })}
                    value={form.bank.name}
                    onChange={profileActions.setBankName}
                    placeholder="Наименование банка"
                  />
                </FormField>
                <FormField>
                  <Input
                    className={cx({ 'vf-error': invalidFields.bank.address })}
                    value={form.bank.address}
                    onChange={profileActions.setBankAddress}
                    placeholder="Адрес банка"
                  />
                </FormField>
                <FormField>
                  <InputMask
                    className={cx('i-input', { 'vf-error': invalidFields.bank.BIK })}
                    value={form.bank.BIK}
                    onChange={profileActions.setBankBIK}
                    placeholder="БИК банка"
                    mask="999999999"
                    maskChar={null}
                  />
                </FormField>
                <FormField>
                  <InputMask
                    className={cx('i-input', { 'vf-error': invalidFields.bank.checkingAccount })}
                    value={form.bank.checkingAccount}
                    onChange={profileActions.setBankCheckingAccount}
                    placeholder="Расчётный счёт"
                    mask="99999999999999999999"
                    maskChar={null}
                  />
                </FormField>
                <FormField>
                  <InputMask
                    className={cx('i-input', { 'vf-error': invalidFields.bank.correspondentAccount })}
                    value={form.bank.correspondentAccount}
                    onChange={profileActions.setBankCorrespondentAccount}
                    placeholder="Корр счёт банка"
                    mask="99999999999999999999"
                    maskChar={null}
                  />
                </FormField>
                <FormField>
                  <Input
                    className={cx({ 'vf-error': invalidFields.bank.SWIFT })}
                    value={form.bank.SWIFT}
                    onChange={profileActions.setBankSWIFT}
                    placeholder="SWIFT"
                  />
                </FormField>
                <FormField>
                  <Input
                    className={cx({ 'vf-error': invalidFields.bank.IBAN })}
                    value={form.bank.IBAN}
                    onChange={profileActions.setBankIBAN}
                    placeholder="IBAN"
                  />
                </FormField>
                <FormField>
                  <Input
                    className={cx({ 'vf-error': invalidFields.bank.ABA })}
                    value={form.bank.ABA}
                    onChange={profileActions.setBankABA}
                    placeholder="ABA"
                  />
                </FormField>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div
              className="lk-profile__header js-profileTabTitle"
              role="tab"
              tabIndex="0"
              onClick={toggleIndividual}
              onKeyDown={getBlockEnterKeyHandler(toggleIndividual)}
            >
              <h3 className="h3 form-h3 lk-profile__header-h3">
                <span
                  className={cx('lk-profile__header-title', {
                    'is-open': individualOpened,
                  })}
                >
                  2. Физические лица, относящиеся к компании
                </span>
              </h3>
            </div>
            <div
              style={{ display: individualOpened ? 'block' : 'none' }}
              className="lk-profile__body js-profileTabBody"
            >
              <div className="lk-profile__body-inner lk-profile__body-inner--border">
                {form.members.map((member, index) => (
                  <MembersItem
                    member={member}
                    key={member.type}
                    index={index}
                    profileActions={profileActions}
                    form={form}
                    invalidField={invalidFields.members.find(({ type }) => type === member.type)}
                  />

                ))}
              </div>
            </div>
          </div>
          <div className="form-group">
            <div
              className="lk-profile__header js-profileTabTitle"
              role="tab"
              tabIndex="0"
              onClick={toggleBeneficiaries}
              onKeyDown={getBlockEnterKeyHandler(toggleBeneficiaries)}
            >
              <h3 className="h3 form-h3 lk-profile__header-h3">
                <span
                  className={cx('lk-profile__header-title', {
                    'is-open': beneficiariesOpened,
                  })}
                >
                  3. Конечные бенефициары
                </span>
              </h3>
            </div>
            <div
              style={{ display: beneficiariesOpened ? 'block' : 'none' }}
              className="lk-profile__body js-profileTabBody"
            >
              <div className="lk-profile__body-inner">
                {form.individualBeneficiaries.map((beneficiary) => (
                  <IndividualBeneficiariesItem
                    key={beneficiary.id}
                    beneficiary={beneficiary}
                    profileActions={profileActions}
                    invalidField={invalidFields.individualBeneficiaries.find(({ id }) => id === beneficiary.id)}
                  />
                ))}

                {form.legalBeneficiaries.map((beneficiary) => (
                  <LegalBeneficiariesItem
                    key={beneficiary.id}
                    beneficiary={beneficiary}
                    profileActions={profileActions}
                    dropdownData={dropdownData}
                    invalidField={invalidFields.legalBeneficiaries.find(({ id }) => id === beneficiary.id)}
                  />
                ))}
              </div>
              <div className="form-add">
                <div className="form-add__row form-add__row--benef">
                  <div className="form-add__item">
                    <div
                      className="i-button i-button--outline i-button--small form-add__btn js-tmpBenFbtn"
                      role="button"
                      tabIndex="0"
                      onClick={profileActions.addIndividualBeneficiary}
                      onKeyDown={profileActions.addIndividualBeneficiary}
                    >
                      <i>
                        <Plus />
                      </i>
                      Добавить бенефициара
                    </div>
                    <div className="form-add__title">Физическое лицо</div>
                  </div>
                  <div className="form-add__item">
                    <div
                      className="i-button i-button--outline i-button--small form-add__btn js-tmpBenYbtn"
                      role="button"
                      tabIndex="0"
                      onClick={profileActions.addLegalBeneficiary}
                      onKeyDown={profileActions.addLegalBeneficiary}
                    >
                      <i>
                        <Plus />
                      </i>
                      Добавить бенефициара
                    </div>
                    <div className="form-add__title">Юридическое лицо</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form-line-bottom form-line-bottom--justify">
          <button type="button" onClick={submit} className="i-button i-button--270">
            Сохранить и отправить
          </button>
          <button
            type="button"
            className="i-button i-button--white"
            onClick={history.goBack}
          >
            Отменить
          </button>
        </div>
      </form>
    </div>
  );
};

export default PartnerForm;
