import { useCallback, useMemo, useState } from 'react';

const isCookiePolicyAcceptedKey = 'isCookiePolicyAccepted';
const isCookiePolicyAcceptedValue = 'accepted';

export default function useCookiePolicyState() {
  const [localStorageValue, setLocalStorageValue] = useState(
    localStorage.getItem(isCookiePolicyAcceptedKey),
  );

  const isCookiePolicyAccepted = useMemo(() => (
    localStorageValue === isCookiePolicyAcceptedValue
  ), [localStorageValue]);

  const acceptCookiePolicy = useCallback(() => {
    localStorage.setItem(isCookiePolicyAcceptedKey, isCookiePolicyAcceptedValue);
    setLocalStorageValue(localStorage.getItem(isCookiePolicyAcceptedKey));
  }, []);

  return { isCookiePolicyAccepted, acceptCookiePolicy };
}
