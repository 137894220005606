import mergeWith from 'lodash/mergeWith';
import mapValues from 'lodash/mapValues';
import isPlainObject from 'lodash/isPlainObject';
import isArray from 'lodash/isArray';
import omitBy from 'lodash/omitBy';
import {
  createEmptyIndividualBeneficiary,
  createEmptyLegalBeneficiary,
  DEFAULT_BANK,
  DEFAULT_MAIN_INFORMATION,
  DEFAULT_MEMBER,
  DEFAULT_ORGANIZATION,
  DEFAULT_REGISTRATION,
  getEmptyMemberWithType,
  MEMBER_TYPE,
} from '../../../../shared/constants/Profile';


function replaceEmptyStringsWithNull(object) {
  return mapValues(object, value => {
    if (isPlainObject(value)) {
      return replaceEmptyStringsWithNull(value);
    }

    if (isArray(value)) {
      return value.map(replaceEmptyStringsWithNull);
    }

    if (value === '') {
      return null;
    }

    return value;
  });
}

function omitNullValues(object) {
  return mapValues(
    omitBy(object, value => value === null),
    value => {
      if (isPlainObject(value)) {
        return omitNullValues(value);
      }

      if (isArray(value)) {
        return value.map(omitNullValues);
      }

      return value;
    },
  );
}

export function omitFalseValues(object) {
  return mapValues(
    object,
    value => {
      if (isPlainObject(value)) {
        return omitFalseValues(value);
      }

      if (isArray(value)) {
        return value.map(omitFalseValues);
      }
      if (typeof value === 'string' || typeof value === 'object') return false;
      return value;
    },
  );
}

function removeSpecificEmptyFields(object) {
  const notAllowedEmptyFields = new Set(['sign']);

  return omitBy(object, (value, key) => notAllowedEmptyFields.has(key) && value === null);
}

function notEmptyCustomizer(objectValue, sourceValue) {
  return objectValue === null || objectValue === undefined ? sourceValue : objectValue;
}

function parseDateTransferStringToFormDate(dateTransferString) {
  const deprecatedInputValues = new Set([null, undefined, '']);

  if (deprecatedInputValues.has(dateTransferString)) return '';

  const [year, month, day] = dateTransferString.split('-').map(Number);

  return new Date(year, month - 1, day);
}

function wrapFormDateToDateTransferString(formDate) {
  const deprecatedInputValues = new Set([null, '']);

  if (deprecatedInputValues.has(formDate)) return null;

  const year = formDate.getFullYear().toString();
  const month = (formDate.getMonth() + 1).toString().padStart(2, '0');
  const day = formDate.getDate().toString().padStart(2, '0');

  return [year, month, day].join('-');
}

// Main information

function extractFormMainInformationFromTransferObject(transferObject) {
  return mergeWith({
    companySign: transferObject.sign,
    englishName: transferObject.english_name,
    fullName: transferObject.full_name,
    shortName: transferObject.short_name,
    phone: transferObject.phone,
    email: transferObject.email,
    hasTrustPerson: transferObject.has_trust_person,
    hasChiefAccountant: transferObject.has_chief_accountant,
  }, DEFAULT_MAIN_INFORMATION, notEmptyCustomizer);
}

function extractMainInformationTransferObjectFromForm(form) {
  return {
    sign: form.companySign,
    english_name: form.englishName,
    full_name: form.fullName,
    short_name: form.shortName,
    phone: form.phone,
    email: form.email,
    has_trust_person: form.hasTrustPerson,
    has_chief_accountant: form.hasChiefAccountant,
  };
}

// Organization

function extractFormOrganizationFromTransferObject(transferObject) {
  return mergeWith({
    type: transferObject.organization_type,
    OGRN: transferObject.OGRN,
    INN: transferObject.INN,
    KPP: transferObject.KPP,
    OKPO: transferObject.OKPO,
    OKVED: transferObject.OKVED,
    OKONX: transferObject.OKONX,
    legalAddress: transferObject.legal_address,
    physicalAddress: transferObject.physical_address,
  }, DEFAULT_ORGANIZATION, notEmptyCustomizer);
}

function mapFormOrganizationToOrganizationTransferObject(formOrganization) {
  return {
    organization_type: formOrganization.type,
    OGRN: formOrganization.OGRN,
    INN: formOrganization.INN,
    KPP: formOrganization.KPP,
    OKPO: formOrganization.OKPO,
    OKVED: formOrganization.OKVED,
    OKONX: formOrganization.OKONX,
    legal_address: formOrganization.legalAddress,
    physical_address: formOrganization.physicalAddress,
  };
}

// Registration

function extractFormRegistrationFromTransferObject(transferObject) {
  return mergeWith({
    date: parseDateTransferStringToFormDate(transferObject.register_date),
    authorityName: transferObject.registering_authority,
  }, DEFAULT_REGISTRATION, notEmptyCustomizer);
}

function mapFormRegistrationToRegistrationTransferObject(formRegistration) {
  return {
    register_date: wrapFormDateToDateTransferString(formRegistration.date),
    registering_authority: formRegistration.authorityName,
  };
}

// Bank

function extractFormBankFromTransferObject(transferObject) {
  return mergeWith({
    country: transferObject.bank_country,
    name: transferObject.bank_name,
    address: transferObject.bank_address,
    BIK: transferObject.bank_BIK,
    checkingAccount: transferObject.bank_checking_account,
    correspondentAccount: transferObject.bank_correspondent_account,
    SWIFT: transferObject.SWIFT,
    IBAN: transferObject.IBAN,
    ABA: transferObject.ABA,
  }, DEFAULT_BANK, notEmptyCustomizer);
}

function mapFormBankToBankTransferObject(formBank) {
  return {
    bank_country: formBank.country,
    bank_name: formBank.name,
    bank_address: formBank.address,
    bank_BIK: formBank.BIK,
    bank_checking_account: formBank.checkingAccount,
    bank_correspondent_account: formBank.correspondentAccount,
    SWIFT: formBank.SWIFT,
    IBAN: formBank.IBAN,
    ABA: formBank.ABA,
  };
}

// Members

function parseTransferPassportNumberString(numberString = '') {
  if (numberString.length !== 10) {
    return {
      series: '',
      number: '',
    };
  }

  return {
    series: numberString.slice(0, 4),
    number: numberString.slice(4),
  };
}

function mapMemberTransferObjectToFormMember(memberTransferObject) {
  const parsedPassportNumberString = parseTransferPassportNumberString(
    memberTransferObject.passport_number,
  );

  return mergeWith({
    type: memberTransferObject.member_type,
    firstName: memberTransferObject.first_name,
    middleName: memberTransferObject.middle_name,
    lastName: memberTransferObject.last_name,
    birthDate: parseDateTransferStringToFormDate(memberTransferObject.date_of_birth),
    passport: {
      series: parsedPassportNumberString.series,
      number: parsedPassportNumberString.number,
      issuer: memberTransferObject.passport_issue_authority,
      issueDate: parseDateTransferStringToFormDate(memberTransferObject.passport_issue_date),
      registrationAddress: memberTransferObject.legal_address,
    },
  }, DEFAULT_MEMBER, notEmptyCustomizer);
}

function mapFormMemberToMemberTransferObject(formMember) {
  return {
    middle_name: formMember.middleName,
    first_name: formMember.firstName,
    last_name: formMember.lastName,
    date_of_birth: wrapFormDateToDateTransferString(formMember.birthDate),
    passport_number: formMember.passport.series.concat(formMember.passport.number),
    passport_issue_authority: formMember.passport.issuer,
    passport_issue_date: wrapFormDateToDateTransferString(formMember.passport.issueDate),
    legal_address: formMember.passport.registrationAddress,
    member_type: formMember.type,
  };
}

// Legal beneficiaries

function mapLegalBeneficiaryTransferObjectToFormLegalBeneficiary(legalBeneficiaryTransferObject) {
  return mergeWith({
    fullName: legalBeneficiaryTransferObject.full_name,
    shortName: legalBeneficiaryTransferObject.short_name,
    phone: legalBeneficiaryTransferObject.phone,
    email: legalBeneficiaryTransferObject.email,
    organization: {
      type: legalBeneficiaryTransferObject.organization_type,
      OGRN: legalBeneficiaryTransferObject.OGRN,
      INN: legalBeneficiaryTransferObject.INN,
      KPP: legalBeneficiaryTransferObject.KPP,
      OKPO: legalBeneficiaryTransferObject.OKPO,
      OKVED: legalBeneficiaryTransferObject.OKVED,
      OKONX: legalBeneficiaryTransferObject.OKONX,
      legalAddress: legalBeneficiaryTransferObject.legal_address,
      physicalAddress: legalBeneficiaryTransferObject.physical_address,
    },
    registration: {
      date: parseDateTransferStringToFormDate(legalBeneficiaryTransferObject.register_date),
      authorityName: legalBeneficiaryTransferObject.registering_authority,
    },
  }, createEmptyLegalBeneficiary(), notEmptyCustomizer);
}

function mapFormLegalBeneficiaryToLegalBeneficiaryTransferObject(formLegalBeneficiary, index) {
  return {
    id: index,
    full_name: formLegalBeneficiary.fullName,
    short_name: formLegalBeneficiary.shortName,
    organization_type: formLegalBeneficiary.organization.type,
    KPP: formLegalBeneficiary.organization.KPP,
    INN: formLegalBeneficiary.organization.INN,
    OGRN: formLegalBeneficiary.organization.OGRN,
    OKVED: formLegalBeneficiary.organization.OKVED,
    OKONX: formLegalBeneficiary.organization.OKONX,
    OKPO: formLegalBeneficiary.organization.OKPO,
    legal_address: formLegalBeneficiary.organization.legalAddress,
    physical_address: formLegalBeneficiary.organization.physicalAddress,
    register_date: wrapFormDateToDateTransferString(formLegalBeneficiary.registration.date),
    registering_authority: formLegalBeneficiary.registration.authorityName,
    email: formLegalBeneficiary.email,
    phone: formLegalBeneficiary.phone,
  };
}

// Individual beneficiaries

function mapIndividualBeneficiaryTransferObjectToFormIndividualBeneficiary(
  individualBeneficiaryTransferObject,
) {
  return mergeWith({
    firstName: individualBeneficiaryTransferObject.first_name,
    middleName: individualBeneficiaryTransferObject.middle_name,
    lastName: individualBeneficiaryTransferObject.last_name,
    birthDate: parseDateTransferStringToFormDate(individualBeneficiaryTransferObject.date_of_birth),
    passport: {
      series: individualBeneficiaryTransferObject.series,
      number: individualBeneficiaryTransferObject.number,
      issuer: individualBeneficiaryTransferObject.passport_issue_authority,
      issueDate: parseDateTransferStringToFormDate(
        individualBeneficiaryTransferObject.passport_issue_date,
      ),
      registrationAddress: individualBeneficiaryTransferObject.legal_address,
    },
  }, createEmptyIndividualBeneficiary(), notEmptyCustomizer);
}

function mapFormIndividualBeneficiaryToIndividualBeneficiaryTransferObject(
  formIndividualBeneficiary,
  index,
) {
  return {
    id: index,
    middle_name: formIndividualBeneficiary.middleName,
    first_name: formIndividualBeneficiary.firstName,
    last_name: formIndividualBeneficiary.lastName,
    date_of_birth: wrapFormDateToDateTransferString(formIndividualBeneficiary.birthDate),
    passport_number: formIndividualBeneficiary.passport.series
      .concat(formIndividualBeneficiary.passport.number),
    passport_issue_authority: formIndividualBeneficiary.passport.issuer,
    passport_issue_date: wrapFormDateToDateTransferString(
      formIndividualBeneficiary.passport.issueDate,
    ),
    legal_address: formIndividualBeneficiary.passport.registrationAddress,
  };
}

// Public form mappers

export function mapTransferObjectToForm(transferObject) {
  const form = {
    ...extractFormMainInformationFromTransferObject(transferObject),
    organization: extractFormOrganizationFromTransferObject(transferObject),
    registration: extractFormRegistrationFromTransferObject(transferObject),
    bank: extractFormBankFromTransferObject(transferObject),
    members: transferObject.members.map(mapMemberTransferObjectToFormMember),
    legalBeneficiaries: transferObject.legal_beneficiaries
      .map(mapLegalBeneficiaryTransferObjectToFormLegalBeneficiary),
    individualBeneficiaries: transferObject.individual_beneficiaries
      .map(mapIndividualBeneficiaryTransferObjectToFormIndividualBeneficiary),
  };

  if (!form.members.some(member => member.type === MEMBER_TYPE.GENERAL_MANAGER)) {
    form.members.push(getEmptyMemberWithType(MEMBER_TYPE.GENERAL_MANAGER));
  }

  if (!form.members.some(member => member.type === MEMBER_TYPE.TRUSTED_PERSON)) {
    form.members.push(getEmptyMemberWithType(MEMBER_TYPE.TRUSTED_PERSON));
  }

  if (!form.members.some(member => member.type === MEMBER_TYPE.CHIEF_ACCOUNTANT)) {
    form.members.push(getEmptyMemberWithType(MEMBER_TYPE.CHIEF_ACCOUNTANT));
  }

  return form;
}

export function mapFormToTransferObject(form) {
  let members = [...form.members];

  if (!form.hasChiefAccountant) {
    members = members.filter(member => member.type !== MEMBER_TYPE.CHIEF_ACCOUNTANT);
  }

  if (!form.hasTrustPerson) {
    members = members.filter(member => member.type !== MEMBER_TYPE.TRUSTED_PERSON);
  }

  const formWithoutEmptyStrings = replaceEmptyStringsWithNull({
    ...extractMainInformationTransferObjectFromForm(form),
    ...mapFormOrganizationToOrganizationTransferObject(form.organization),
    ...mapFormRegistrationToRegistrationTransferObject(form.registration),
    ...mapFormBankToBankTransferObject(form.bank),
    members: members.map(mapFormMemberToMemberTransferObject),
    legal_beneficiaries: form.legalBeneficiaries
      .map(mapFormLegalBeneficiaryToLegalBeneficiaryTransferObject),
    individual_beneficiaries: form.individualBeneficiaries
      .map(mapFormIndividualBeneficiaryToIndividualBeneficiaryTransferObject),
  });

  return removeSpecificEmptyFields(formWithoutEmptyStrings);
}

function getValidationMessageOrNull(messages) {
  try {
    return messages[0];
  } catch {
    return null;
  }
}

function extractMemberValidationFromTransferObject(transferObject) {
  return {
    firstName: getValidationMessageOrNull(transferObject.first_name),
    middleName: getValidationMessageOrNull(transferObject.middle_name),
    lastName: getValidationMessageOrNull(transferObject.last_name),
    birthDate: getValidationMessageOrNull(transferObject.date_of_birth),
    passport: {
      series: getValidationMessageOrNull(transferObject.passport_number),
      number: getValidationMessageOrNull(transferObject.passport_number),
      issuer: getValidationMessageOrNull(transferObject.passport_issue_authority),
      issueDate: getValidationMessageOrNull(transferObject.passport_issue_date),
      registrationAddress: getValidationMessageOrNull(transferObject.legal_address),
    },
  };
}

function extractMembersValidationFromTransferObject(formMembers, membersTransferObject = []) {
  return membersTransferObject.reduce((validation, transferObject, index) => {
    const { type } = formMembers[index];

    return {
      ...validation,
      [type]: extractMemberValidationFromTransferObject(transferObject),
    };
  }, {});
}

function extractLegalBeneficiaryValidationFromTransferObject(
  legalBeneficiaryTransferObject,
) {
  let transferObject = { ...legalBeneficiaryTransferObject };

  if (!transferObject.legal) {
    transferObject = { ...transferObject, legal: {} };
  }

  return {
    fullName: getValidationMessageOrNull(transferObject.full_name),
    shortName: getValidationMessageOrNull(transferObject.legal.short_name),
    phone: getValidationMessageOrNull(transferObject.phone),
    email: getValidationMessageOrNull(transferObject.legal.email),
    organization: {
      type: getValidationMessageOrNull(transferObject.organization_type),
      INN: getValidationMessageOrNull(transferObject.INN),
      KPP: getValidationMessageOrNull(transferObject.KPP),
      legalAddress: getValidationMessageOrNull(transferObject.legal.legal_address),
      physicalAddress: getValidationMessageOrNull(transferObject.legal.physical_address),
    },
    registration: {
      date: getValidationMessageOrNull(transferObject.legal.register_date),
      authorityName: getValidationMessageOrNull(transferObject.legal.registering_authority),
    },
  };
}

function extractLegalBeneficiariesValidationFromTransferObject(
  formLegalBeneficiaries,
  legalBeneficiariesTransferObject = [],
) {
  return legalBeneficiariesTransferObject.reduce((validation, transferObject, index) => {
    const { id } = formLegalBeneficiaries[index];

    return {
      ...validation,
      [id]: extractLegalBeneficiaryValidationFromTransferObject(transferObject),
    };
  }, {});
}

function extractIndividualBeneficiaryValidationFromTransferObject(
  individualBeneficiaryTransferObject = {},
) {
  return {
    firstName: getValidationMessageOrNull(individualBeneficiaryTransferObject.first_name),
    middleName: getValidationMessageOrNull(individualBeneficiaryTransferObject.middle_name),
    lastName: getValidationMessageOrNull(individualBeneficiaryTransferObject.last_name),
    birthDate: getValidationMessageOrNull(individualBeneficiaryTransferObject.date_of_birth),
    passport: {
      series: getValidationMessageOrNull(individualBeneficiaryTransferObject.passport_number),
      number: getValidationMessageOrNull(individualBeneficiaryTransferObject.passport_number),
      issuer: getValidationMessageOrNull(
        individualBeneficiaryTransferObject.passport_issue_authority,
      ),
      issueDate: getValidationMessageOrNull(
        individualBeneficiaryTransferObject.passport_issue_date,
      ),
      registrationAddress: getValidationMessageOrNull(
        individualBeneficiaryTransferObject.legal_address,
      ),
    },
  };
}

function extractIndividualBeneficiariesValidationFromTransferObject(
  formIndividualBeneficiaries,
  individualBeneficiariesTransferObject = [],
) {
  return individualBeneficiariesTransferObject.reduce((validation, transferObject, index) => {
    const { id } = formIndividualBeneficiaries[index];

    return {
      ...validation,
      [id]: extractIndividualBeneficiaryValidationFromTransferObject(transferObject),
    };
  }, {});
}

export function mapValidationTransferObjectToFormValidation(form, validationTransferObject) {
  return omitNullValues({
    sign: getValidationMessageOrNull(validationTransferObject.sign),
    shortName: getValidationMessageOrNull(validationTransferObject.short_name),
    email: getValidationMessageOrNull(validationTransferObject.email),
    phone: getValidationMessageOrNull(validationTransferObject.phone),
    organization: {
      legalAddress: getValidationMessageOrNull(validationTransferObject.legal_address),
      physicalAddress: getValidationMessageOrNull(validationTransferObject.physical_address),
    },
    registration: {
      date: getValidationMessageOrNull(validationTransferObject.register_date),
      authorityName: getValidationMessageOrNull(validationTransferObject.registering_authority),
    },
    bank: {},
    members: extractMembersValidationFromTransferObject(
      form.members,
      validationTransferObject.members,
    ),
    legalBeneficiaries: extractLegalBeneficiariesValidationFromTransferObject(
      form.legalBeneficiaries,
      validationTransferObject.legal_beneficiaries,
    ),
    individualBeneficiaries: extractIndividualBeneficiariesValidationFromTransferObject(
      form.individualBeneficiaries,
      validationTransferObject.individual_beneficiaries,
    ),
  });
}
