import axios from 'axios';

export default class pagesAPI {
  _apiBase = `${process.env.REACT_APP_API_BASE}`;

  getFAQ = async () => {
    const res = await axios.get(`${this._apiBase}/faq/`);
    try {
      return res;
    } catch {
      return res;
    }
  };

  getTerms = async () => {
    const res = await axios.get(`${this._apiBase}/terms/`);
    try {
      return res;
    } catch {
      return res;
    }
  };

  getSpecialOffers = async () => {
    const res = await axios.get(`${this._apiBase}/special_offers/`);
    try {
      return res;
    } catch {
      return res;
    }
  };

  getContacts = async id => {
    const res = await axios.get(`${this._apiBase}/contacts/${id}`);
    try {
      return res;
    } catch {
      return res;
    }
  };

  feedback = async data => {
    const newData = { ...data };
    for (const key in newData) {
      if (newData[key] === '') newData[key] = null;
    }
    const res = await axios.post(`${this._apiBase}/feedback/`, {
      email: newData.email,
      phone: newData.phone.replace(/\D+/g, ''),
      feedback_body: newData.question,
      captcha_token: newData.captchaToken,
      privacy_policy: newData.agreement,
    });
    try {
      return res;
    } catch {
      return res;
    }
  };
}
