import React, { Component } from 'react';
import Slider from 'react-slick';
import Card from './SimilarCard';

class Similar extends Component {
  state = {};

  render() {
    const { suggestions } = this.props;
    return (
      <div className="container">
        <div className="find-item__arrows i-pagination">
          <span
            onClick={() => {
              this.slider.slickPrev();
            }}
            id="carouselPrev"
            className="i-pagination__item i-pagination__item--prev"
          />
          <span
            onClick={() => {
              this.slider.slickNext();
            }}
            id="carouselNext"
            className="i-pagination__item i-pagination__item--next"
          />
        </div>
        <h2 className="h2 h2--mb30">Похожие предложения</h2>
        <div className="find-item__slider-wrap">
          <div className="find-item__slider js-carousel">
            <Slider
              ref={c => (this.slider = c)}
              arrows={false}
              speed={500}
              draggable={false}
              slidesToShow={3}
              infinite={false}
              slidesToScroll={1}
            >
              {suggestions.map(el => (<Card key={el.id} id={el.id} askoId={el.asko_id} address={el.address} baseRate={el.base_rate} area={el.area} photos={el.photos} />))}

            </Slider>
          </div>
        </div>
      </div>
    );
  }
}

export default Similar;
