import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import OrganizationAPI from '../../../services/organizationAPI';
import SearchableSelect from '../../../components/ui/Layout/HeaderSearch';

const organizationAPI = new OrganizationAPI();

function Regions({
  close,
  dispatch,
  geolocationCurrent,
}) {
  const regionsWrapper = useRef(null);
  const [regions, setRegions] = useState({});
  const [regionsLoaded, setRegionsLoaded] = useState(false);

  const [currentRegion, setCurrentRegion] = useState(null);
  const [currentLetter, setCurrentLetter] = useState(null);

  useEffect(() => {
    document.body.classList.add('is-modal');

    organizationAPI
      .getAlphabetRegions()
      .then(response => {
        setRegions(response.data);
        setRegionsLoaded(true);
      });

    return () => {
      document.body.classList.remove('is-modal');
    };
  }, []);

  const regionGroups = useMemo(() => Object.keys(regions).reduce((groups, letter) => {
    groups.push({
      letter,
      subregions: regions[letter],
    });

    return groups;
  }, []), [regions]);

  const subregions = useMemo(() => {
    if (!currentRegion || !currentLetter) return [];

    return regions[currentLetter].find(region => region.id === currentRegion).regions;
  }, [currentLetter, currentRegion, regions]);

  const selectRegion = (region) => {
    localStorage.setItem('currentRegionId', region.id.toString());

    dispatch({
      type: 'SET_REGION',
      payload: {
        id: region.id,
        name: region.name,
      },
    });

    close();
  };

  return regionsLoaded ? (
    <div ref={regionsWrapper} className="regions" id="regions">
      <form id="form_location_id" method="post" action="/geoip/setlocation/">
        <input type="hidden" value="" id="location_id" name="location_id" />
      </form>
      <button type="button" onClick={close} className="regions__close" id="closeRegions">
        <i className="icon icon-x" />
      </button>
      <div className="regions-container">
        <div className="regions__search">
          <SearchableSelect close={close} dispatch={dispatch} />
        </div>
        <div className="regions__sheet">
          <div className="regions__sheet_left">
            <div id="popular" className="regions__nav">
              <ul className="regions__list">{
                subregions.map(subregion => (
                  <li
                    key={subregion.id}
                    className={cx('regions__item', { active: geolocationCurrent.id === subregion.id })}
                  >
                    <span
                      className="regions__link"
                      role="button"
                      tabIndex="0"
                      onClick={() => selectRegion(subregion)}
                      onKeyDown={event => {
                        if (event.key === 'Enter') {
                          selectRegion(subregion);
                        }
                      }}
                    >
                      <span className="regions__text">{subregion.name}</span>
                      <i className="icon icon-x icon__region_clear" />
                    </span>
                  </li>
                ))
              }
              </ul>
            </div>
          </div>
          <div className="regions__sheet_right">
            <div id="regionsView" className="row">
              <div className="regions__group-col">{
                regionGroups.map((group, index) => (
                  <div key={index} className="regions__group">
                    <h3 className="regions__title">{group.letter}</h3>
                    <ul className="regions__list">
                      {group.subregions.map(subregion => (
                        <li
                          key={subregion.id}
                          className={cx('regions__item', { active: geolocationCurrent.id === subregion.id })}
                        >
                          <span
                            role="button"
                            tabIndex="0"
                            className="regions__link"
                            onClick={() => {
                              if (subregion.regions.length) {
                                setCurrentRegion(subregion.id);
                                setCurrentLetter(group.letter);
                                regionsWrapper.current.scrollTop = 0;
                              } else {
                                selectRegion(subregion);
                              }
                            }}
                            onKeyDown={(event) => {
                              if (event.key === 'Enter') {
                                if (subregion.regions.length) {
                                  setCurrentRegion(subregion.id);
                                  setCurrentLetter(group.letter);
                                  regionsWrapper.current.scrollTop = 0;
                                } else {
                                  selectRegion(subregion);
                                }
                              }
                            }}
                          >
                            <span className="regions__text">{subregion.name}</span>
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div ref={regionsWrapper} className="regions" id="regions">
      Загружаю...
    </div>
  );
}

const mapStateToProps = state => ({
  geolocationCurrent: state.login.geolocationCurrent,
});

export default connect(mapStateToProps)(Regions);
