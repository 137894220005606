import axios from 'axios';

export default class AuthAPI {
  _apiBase = `${process.env.REACT_APP_API_BASE}/auth`;

  register = async data => {
    const newData = { ...data };

    Object.keys(newData).forEach(key => {
      if (data[key] === '') newData[key] = null;
    });

    if (newData.legal_form === 1) {
      newData.KPP = null;
    }

    return axios.post(`${this._apiBase}/registration/`, {
      email: newData.email,
      password1: newData.password1,
      password2: newData.password2,
      recaptcha: newData.recaptcha,
      middle_name: newData.middle_name,
      first_name: newData.first_name,
      last_name: newData.last_name,
      phone: newData.phone.replace(/\D+/g, ''),
      captcha_token: newData.captcha_token,
      organization: {
        region: +newData.region,
        organization_type: newData.legal_form,
        full_name: newData.full_name,
        INN: newData.INN,
        KPP: newData.KPP,
        website: newData.website,
        phone: newData.organization_phone.replace(/\D+/g, ''),
      },
    });
  };

  login = async (email, password, captchaToken) => {
    try {
      const res = await axios.post(`${this._apiBase}/login/`, {
        email,
        password,
        captcha_token: captchaToken,
      });

      document.cookie = `Token=${res.data.key}`;
      return res;
    } catch (e) {
      return e;
    }
  };

  logout = async () => {
    // eslint-disable-next-line no-useless-escape
    const Token = document.cookie.replace(/(?:(?:^|.*;\s*)Token\s*\=\s*([^;]*).*$)|^.*$/, '$1');
    try {
      const res = await axios.post(
        `${this._apiBase}/logout/`,
        {},
        {
          headers: {
            Authorization: `Token ${Token}`,
          },
        },
      );
      document.cookie = 'Token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      delete axios.defaults.headers.common.Authorization;
      return res;
    } catch (e) {
      return e;
    }
  };

  getUser = async () => {
    // eslint-disable-next-line no-useless-escape
    const Token = document.cookie.replace(/(?:(?:^|.*;\s*)Token\s*\=\s*([^;]*).*$)|^.*$/, '$1');
    const res = await axios.get(`${this._apiBase}/user/`, {
      headers: {
        Authorization: `Token ${Token}`,
      },
    });
    try {
      return res;
    } catch {
      return { data: { error: true, detail: 'Вы не авторизованы' } };
    }
  };

  patchUser = async data => {
    // eslint-disable-next-line no-useless-escape
    const Token = document.cookie.replace(/(?:(?:^|.*;\s*)Token\s*\=\s*([^;]*).*$)|^.*$/, '$1');
    try {
      const res = await axios.patch(
        `${this._apiBase}/user/`,
        { ...data },
        {
          headers: {
            Authorization: `Token ${Token}`,
          },
        },
      );
      return res;
    } catch (e) {
      return e;
    }
  };

  verify = async key => {
    try {
      const res = await axios.post(`${this._apiBase}/verify-email/`, {
        key,
      });
      return res;
    } catch (e) {
      return e;
    }
  };

  passwordReset = async (email, captchaToken) => {
    try {
      const res = await axios.post(`${this._apiBase}/password/reset/`, {
        email,
        captcha_token: captchaToken,
      });
      return res;
    } catch (e) {
      return e;
    }
  };

  passwordChange = async (new_password1, new_password2, uid, token) => {
    const res = await axios.post(`${this._apiBase}/password/reset/confirm/`, {
      new_password1,
      new_password2,
      uid,
      token,
    });
    try {
      return res;
    } catch (e) {
      return e;
    }
  };
}
