import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';

import Tooltip from '../../../../ui/Layout/Tooltip';

import formatCurrency from '../../../../../shared/utils/formatCurrency';

class MainInfo extends Component {
  state = { currentSlide: 1, slidesCount: undefined, isSliding: false };

  componentDidMount() {
    const { data } = this.props;
    this.setState({ slidesCount: data.photos.length });
  }

  nextSlide = () => {
    const { isSliding } = this.state;
    this.setState({ isSliding: true }, () => {
      if (!isSliding) {
        const { currentSlide, slidesCount } = this.state;
        setTimeout(() => {
          this.setState(prev => {
            if (currentSlide === slidesCount) return { currentSlide: 1 };
            return { currentSlide: prev.currentSlide + 1 };
          }, this.setState({ isSliding: false }));
        }, 500);
      }
    });
  };

  prevSlide = () => {
    const { isSliding } = this.state;
    this.setState({ isSliding: true }, () => {
      if (!isSliding) {
        const { currentSlide, slidesCount } = this.state;
        setTimeout(() => {
          this.setState(prev => {
            if (currentSlide === 1) return { currentSlide: slidesCount };
            return { currentSlide: prev.currentSlide - 1 };
          }, this.setState({ isSliding: false }));
        }, 500);
      }
    });
  };

  renderSubmit = () => (
    <div className="card__submit">
      <span className="i-button i-button--green i-button--270 card__submit-sent">
        Заявка отправлена
      </span>

      <div className="card__submit-notice">
        Статус заявки можно отследить <br />в личном кабинете
      </div>
    </div>
  );

  renderGroup = data => (
    <div className="card__ingroup">
      <div className="card__ingroup-left">
        <div className="card__ingroup-title">Объект сдаётся в группе</div>
        <Link to={`/find/group/${data.group.id}`} className="i-link-red card__ingroup-link">
          Подробнее
        </Link>
      </div>
      <div className="card__ingroup-right">
        <div className="card__ingroup-id">
          Номер группы: <span>{data.group.asko_id}</span>
        </div>
      </div>
    </div>
  );

  renderOccupationDate = data => (
    <div className="card__more-item">
      Освобождается:{' '}
      <span>{format(data.asko_to_date, 'D MMMM', { locale: ru })}</span>
    </div>
  );

  renderSend = toggleModal => (
    <div
      onKeyPress={toggleModal}
      role="button"
      tabIndex="0"
      onClick={toggleModal}
      className="card__submit"
    >
      <span className="i-button i-button--270">Подать заявку</span>
    </div>
  );

  render() {
    const { toggleModal, data } = this.props;
    const { currentSlide, slidesCount } = this.state;
    return (
      <>
        <h1 className="h1 find__h1">{data.address}</h1>
        <div className="find-item__topnav">
          <Link to={`/find?shop=${data.shop}`}>Свободные площади по этому адресу</Link>
          <a href="#map" className="find-item__topnav-onmap">
            Показать на карте
          </a>
        </div>

        <div className="find-item__body">
          <div className="find-item__left">
            {!data.photos.length && (
              <div className="m-slider m-slider--item">
                <div className="m-slider__main m-slider__main--380">
                  <div className="m-slider__wrap js-mSlider">
                    <div
                      className="m-slider__slide-img"
                      style={{ backgroundImage: 'url(../../img/media/no_img.png)' }}
                    />
                  </div>
                </div>
              </div>
            )}
            {!!data.photos.length && (
              <div className="m-slider m-slider--item">
                <div className="m-slider__main m-slider__main--380">
                  <div
                    className="js-mNextSlide m-slider__next"
                    onClick={() => {
                      this.slider.slickNext();
                      this.nextSlide();
                    }}
                    onKeyPress={() => {
                      this.slider.slickNext();
                      this.nextSlide();
                    }}
                    role="button"
                    tabIndex="0"
                  />
                  <div
                    className="js-mPrevSlide m-slider__prev"
                    onClick={() => {
                      this.slider.slickPrev();
                      this.prevSlide();
                    }}
                    onKeyPress={() => {
                      this.slider.slickPrev();
                      this.prevSlide();
                    }}
                    role="button"
                    tabIndex="0"
                  />
                  <div className="m-slider__index">
                    <span className="js-mCurrent">{currentSlide}</span>
                    <span className="m-slider__in">из</span>
                    <span className="js-mSize">{slidesCount}</span>
                  </div>
                  <div className="m-slider__wrap js-mSlider">
                    <Slider
                      draggable={false}
                      ref={c => (this.slider = c)}
                      arrows={false}
                      infinite
                      speed={500}
                    >
                      {data.photos.map((el, idx) => (
                        <div key={idx} className="m-slider__slide">
                          <div
                            style={{ backgroundImage: `url(${el})` }}
                            className="m-slider__slide-img m-slider__slide-img_contain"
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
                <div className="m-slider__thumbs">
                  {data.photos.map((el, idx) => (
                    <div
                      key={idx}
                      onClick={() => {
                        this.slider.slickGoTo(idx);
                        this.setState({ currentSlide: idx + 1 });
                      }}
                      className="m-slider__thumb js-thumbSlider"
                      style={{ backgroundImage: `url(${el})` }}
                      onKeyPress={() => {
                        this.slider.slickGoTo(idx);
                        this.setState({ currentSlide: idx + 1 });
                      }}
                      role="button"
                      tabIndex="0"
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="find-item__right">
            <div className="card card--item">
              <div className="card__inner">
                <div className="card__area">{data.shop_type}</div>
                <div className="card__features">
                  <div className="card__features-item">
                    <div className="card__features-caption">Площадь</div>
                    <div className="card__features-lines">
                      <div className="card__features-val">
                        {data.area} <small>м²</small>
                      </div>
                    </div>
                  </div>
                  <div className="card__features-item">
                    <div className="card__features-caption">Аренда в месяц</div>
                    <div className="card__features-lines">
                      <div className="card__features-val">
                        <small>от </small>
                        {formatCurrency(data.base_rate)}
                        <small> ₽/мес </small>
                        <Tooltip>
                          Цены указаны с НДС, являются предварительными и могут быть изменены
                        </Tooltip>
                      </div>
                      <div className="card__features-notice">
                        {formatCurrency(data.base_rate / data.area)} ₽/м²
                      </div>
                    </div>
                  </div>
                  <div className="card__features-item">
                    <div className="card__features-caption">Срок аренды</div>
                    <div className="card__features-lines">
                      <div className="card__features-val">
                        {data.lease_term} <small>мес</small>
                      </div>
                      <div className="card__features-notice">с возможной пролонгацией</div>
                    </div>
                  </div>
                </div>
                <div className="card__description">
                  {(data.category ? `${data.category}. ` : '') + (data.desc || '')}
                </div>
                <div className="card__more">
                  {data.occupancy_status !== 'Свободна' ? this.renderOccupationDate(data) : ''}
                  <div className="card__more-item">
                    Номер объекта: <span>{data.asko_id}</span>
                  </div>
                </div>
                {data.group
                  ? this.renderGroup(data)
                  : data.request
                    ? this.renderSubmit()
                    : this.renderSend(toggleModal)}
              </div>
            </div>
          </div>
        </div>
        <div className="find-item__update">
          Информация обновлена {format(data.asko_updated_date, 'D MMMM YYYY', { locale: ru })}
        </div>

        <h2 className="h2 h2--mb30">Общая информация</h2>
        <div className="keyval">
          <div className="keyval__item">
            <div className="keyval__item-key">Адрес магазина</div>
            <div className="keyval__item-dots" />
            <div className="lk-contract__item-val">{data.address}</div>
          </div>
          <div className="keyval__item">
            <div className="keyval__item-key">Срок аренды (минимальный)</div>
            <div className="keyval__item-dots" />
            <div className="lk-contract__item-val">1 месяц</div>
          </div>
          <div className="keyval__item">
            <div className="keyval__item-key">Тип магазина</div>
            <div className="keyval__item-dots" />
            <div className="lk-contract__item-val">{data.shop_type}</div>
          </div>
          <div className="keyval__item">
            <div className="keyval__item-key">Срок аренды (максимальный)</div>
            <div className="keyval__item-dots" />
            <div className="lk-contract__item-val">{data.lease_term} месяцев</div>
          </div>
          <div className="keyval__item">
            <div className="keyval__item-key">Размер площади</div>
            <div className="keyval__item-dots" />
            <div className="lk-contract__item-val">{data.area} м²</div>
          </div>
          <div className="keyval__item">
            <div className="keyval__item-key">Категория площади</div>
            <div className="keyval__item-dots" />
            <div className="lk-contract__item-val">{data.category}</div>
          </div>
          <div className="keyval__item">
            <div className="keyval__item-key">Аренда в месяц</div>
            <div className="keyval__item-dots" />
            <div className="lk-contract__item-val">{formatCurrency(data.base_rate)} ₽/мес</div>
          </div>
          <div className="keyval__item">
            <div className="keyval__item-key">Возможность пролонгации</div>
            <div className="keyval__item-dots" />
            <div className="lk-contract__item-val">{data.prolong ? 'Да' : 'Нет'}</div>
          </div>
          <div className="keyval__item">
            <div className="keyval__item-key">Средняя цена за м²</div>
            <div className="keyval__item-dots" />
            <div className="lk-contract__item-val">
              {formatCurrency(data.base_rate / data.area)} ₽/м²
            </div>
          </div>
          <div className="keyval__item">
            <div className="keyval__item-key">Техническое состояние площади</div>
            <div className="keyval__item-dots" />
            <div className="lk-contract__item-val">{data.short_desc || ''}</div>
          </div>
          <div className="keyval__item">
            <div className="keyval__item-key">Статус площади</div>
            <div className="keyval__item-dots" />
            <div className="lk-contract__item-val">{data.occupancy_status}</div>
          </div>
        </div>
      </>
    );
  }
}

export default MainInfo;
