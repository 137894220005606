import React from 'react';

const Svg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6 13H16V15H6V13Z" fill="#E0E0E0" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0 13H2V15H0V13Z" fill="#E0E0E0" />
    <path
      d="M4 11.8333V15.1667C4 15.6267 3.70133 16 3.33333 16H2.66667C2.29867 16 2 15.6267 2 15.1667V11.8333C2 11.3733 2.29867 11 2.66667 11H3.33333C3.70133 11 4 11.3733 4 11.8333Z"
      fill="#E0E0E0"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M10 1H16V3H10V1Z" fill="#E0E0E0" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0 1H6V3H0V1Z" fill="#E0E0E0" />
    <path
      d="M8 0.833333V4.16667C8 4.62667 7.70133 5 7.33333 5H6.66667C6.29867 5 6 4.62667 6 4.16667V0.833333C6 0.373333 6.29867 0 6.66667 0H7.33333C7.70133 0 8 0.373333 8 0.833333Z"
      fill="#E0E0E0"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M14 7H16V9H14V7Z" fill="#E0E0E0" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0 7H10V9H0V7Z" fill="#E0E0E0" />
    <path
      d="M12 6.66667V9.33333C12 9.70133 11.7013 10 11.3333 10H10.6667C10.2987 10 10 9.70133 10 9.33333V6.66667C10 6.29867 10.2987 6 10.6667 6H11.3333C11.7013 6 12 6.29867 12 6.66667Z"
      fill="#E0E0E0"
    />
  </svg>
);

export default Svg;
