export function localizeDayOfWeek(day) {
  const daysOfWeek = {
    Monday: 'Пн',
    Tuesday: 'Вт',
    Wednesday: 'Ср',
    Thursday: 'Чт',
    Friday: 'Пт',
    Saturday: 'Сб',
    Sunday: 'Вс',
  };

  return daysOfWeek[day];
}
