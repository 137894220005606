/* global document */
import AuthAPI from '../../services/authAPI';
import OrganizationAPI from '../../services/organizationAPI';
import { GeolocationRegion, GeolocationDetector } from '../../components/modals/Login';

const authAPI = new AuthAPI();
const organizationAPI = new OrganizationAPI();

export const loginByTokenRequest = () => dispatch => {
  dispatch({ type: 'REQUEST_LOGIN_BY_TOKEN' });
  authAPI
    .getUser()
    .then(res => {
      if (res.status >= 200 && res.status <= 399) {
        dispatch({
          type: 'SUCCESS_LOGIN_BY_TOKEN',
          payload: res.data,
        });
      } else {
        dispatch({
          type: 'FAILED_LOGIN_BY_TOKEN',
        });
        document.cookie = 'Token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      }
    })
    .catch(() => {
      dispatch({
        type: 'FAILED_LOGIN_BY_TOKEN',
      });
      document.cookie = 'Token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    });
};

export const loginRequest = (login, password, captchaToken) => dispatch => {
  dispatch({ type: 'REQUEST_LOGIN' });
  authAPI
    .login(login, password, captchaToken)
    .then(res => {
      if (res.status >= 200 && res.status <= 399) {
        dispatch({
          type: 'SUCCESS_LOGIN',
        });
        document.cookie = `Token=${res.data.key}`;
        dispatch(loginByTokenRequest());
      } else {
        try {
          dispatch({
            type: 'ERROR_LOGIN',
            payload: Object.values(res.response.data),
          });
        } catch {
          dispatch({
            type: 'ERROR_LOGIN',
            payload: ['Произошла ошибка входа'],
          });
        }
      }
    })
    .catch(res => {
      dispatch({
        type: 'ERROR_LOGIN',
        payload: Object.values(res.response.data),
      });
    });
};

export const logout = () => dispatch => {
  dispatch({ type: 'REQUEST_LOGOUT' });
  authAPI.logout().then(res => {
    if (res.status >= 200 && res.status <= 399) {
      dispatch({
        type: 'SUCCESS_LOGOUT',
      });
    } else {
      dispatch({
        type: 'ERROR_LOGOUT',
        payload: 'Ошибка.',
      });
    }
  });
};

function mapRegionsToGeolocationRegions(regions) {
  return regions.map(region => new GeolocationRegion(region.id, region.name));
}

const unknownRegion = {
  id: -1,
  name: 'Не определено',
};

export const getGeolocation = () => dispatch => {
  dispatch({ type: 'GEOLOCATION_REQUEST' });

  organizationAPI.getRegions()
    .then(response => response.data)
    .then(mapRegionsToGeolocationRegions)
    .then(geolocationRegions => new GeolocationDetector(geolocationRegions))
    .then(geolocationDetector => geolocationDetector.getDetectedRegion())
    .then(region => dispatch({ type: 'GEOLOCATION_SUCCESS', payload: region }))
    .catch(() => dispatch({ type: 'GEOLOCATION_CANCELED', payload: unknownRegion }));
};
