import React from 'react';

const NoResult = ({ reset }) => (
  <>
    <div className="find__noresult">
      <p>
        По заданным параметрам ничего не найдено, <br />
        попробуйте изменить условия поиска.
      </p>
      <span
        className="i-link-red"
        role="button"
        tabIndex="0"
        onClick={reset}
        onKeyDown={reset}
      >
        Сбросить все фильтры
      </span>
    </div>
  </>
);

export default NoResult;
