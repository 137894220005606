import React, { Component } from 'react';

import {
  YMaps, Map, Clusterer, Placemark,
} from 'react-yandex-maps';

import mapZoomIn from '../../../../ui/svg/map-zoomin.svg';
import mapZoomOut from '../../../../ui/svg/map-zoomout.svg';
import mapPin from '../../Map/pin-map-sprite.svg';
import clusterMap from '../../Map/cluster-map.png';

import formatCurrency from '../../../../../shared/utils/formatCurrency';

class OnMap extends Component {
  state = {
    mapState: {
      center: this.props.data.point.coordinates,
      zoom: 16,
    },
  };

  render() {
    const { mapState } = this.state;
    const { data } = this.props;
    return (
      <div className="map-wrap">
        <div className="map" id="map">
          <YMaps query={{ coordorder: 'longlat' }}>
            <Map
              instanceRef={ref => ref && ref.behaviors.disable('scrollZoom')}
              onClick={this.handleClick}
              width="100%"
              height="100%"
              state={mapState}
              options={{
                maxZoom: 18,
                minZoom: 4,
                suppressMapOpenBlock: true,
              }}
            >
              <Clusterer
                options={{
                  clusterIcons: [
                    {
                      href: clusterMap,
                      size: [58, 58],
                      // Отступ, чтобы центр картинки совпадал с центром кластера.
                      offset: [-29, -29],
                      shape: {
                        type: 'Circle',
                        coordinates: [0, 0],
                        radius: 29,
                      },
                    },
                  ],
                  maxZoom: 16,
                  minClusterSize: 2,
                  gridSize: 32,
                }}
              >
                <Placemark
                  geometry={data.point.coordinates}
                  properties={{
                    balloonContentBody:
                      "<div class='map-balloon'>"
                      + "<div class='map-balloon__title'>"
                      + `<a href='./${data.id}' target='_blank' class='i-link-black'>${
                        data.address
                      }</a>`
                      + '</div>'
                      + "<div class='map-balloon__free'>"
                      + `<b>${data.occupancy_status}</b>`
                      + '</div>'
                      + "<div class='map-balloon__footer'>"
                      + "<div class='map-balloon__footer-item'>"
                      + `<div class='txt-val'>${data.area} <small>м²</small></div>`
                      + '</div>'
                      + "<div class='map-balloon__footer-item'>"
                      + `<div class='txt-val'>${formatCurrency(
                        data.base_rate,
                      )} <small>₽/мес</small></div>`
                      + '</div>'
                      + '</div>'
                      + '</div>',
                  }}
                  options={{
                    iconLayout: 'default#image',
                    iconImageHref: mapPin,
                    iconImageSize: [26, 35],
                    iconImageOffset: [-13, -35],
                    iconImageClipRect: [[0, 15], [26, 50]],
                    hideIconOnBalloonOpen: false,
                    balloonOffset: [0, -5],
                    balloonMaxWidth: 500,
                    balloonMinWidth: 480,
                  }}
                  modules={['geoObject.addon.balloon']}
                />
              </Clusterer>
            </Map>
          </YMaps>
        </div>
        <div className="map-controls">
          <div className="map-zoom">
            <div
              onClick={() => this.setState(prevState => ({
                mapState: { ...prevState.mapState, zoom: prevState.mapState.zoom + 1 },
              }))
              }
              className="map-zoom__in"
              id="mapZoomIn"
            >
              <img src={mapZoomIn} alt="" />
            </div>
            <div
              onClick={() => this.setState(prevState => ({
                mapState: { ...prevState.mapState, zoom: prevState.mapState.zoom - 1 },
              }))
              }
              className="map-zoom__out"
              id="mapZoomOut"
            >
              <img src={mapZoomOut} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OnMap;
