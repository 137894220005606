import React, {
  useReducer, useRef, useState,
} from 'react';
import cx from 'classnames';

import Input from '../../ui/Form/Input';
import PhoneInput from '../../ui/Form/PhoneInput';
import Textarea from '../../ui/Form/Textarea';
import Checkbox from '../../ui/Form/Checkbox';
import FormField from '../../ui/Form/FormField';

import PagesAPI from '../../../services/pagesAPI';
import CustomCaptcha from '../../ui/CustomCaptcha/CustomCaptcha';
import { PolicyLink } from './PolicyLink';
import useEscapeListener from '../../../shared/hooks/useEscapeListener';
import { useClickOutside } from '../../../shared/hooks';
import { getInvalidFormFields } from '../../../shared/Question/validation';
import { FormFields } from '../../../shared/constants';

const reducer = (state, action) => {
  switch (action.field) {
    case FormFields.Email:
      return { ...state, [FormFields.Email]: action.payload };
    case FormFields.Phone:
      return { ...state, [FormFields.Phone]: action.payload };
    case FormFields.Question:
      return { ...state, [FormFields.Question]: action.payload };
    case FormFields.Agreement:
      return { ...state, [FormFields.Agreement]: action.payload };
    default:
      throw new Error(`Unknown field: ${action.field}`);
  }
};

const pagesAPI = new PagesAPI();

export const Question = ({ close }) => {
  const [state, dispatch] = useReducer(reducer, {
    [FormFields.Email]: '',
    [FormFields.Phone]: '',
    [FormFields.Question]: '',
    [FormFields.Agreement]: true,
  });

  const [invalidFormFields, setInvalidFormFields] = useState([]);
  const [responseStatus, setResponseStatus] = useState(false);

  const captchaRef = useRef();
  const wrapperRef = useRef();

  useClickOutside(wrapperRef, close);
  useEscapeListener(close);

  const updateState = ({ target }) => {
    dispatch({ field: target.name, payload: target.value });
  };

  const toggleAgreement = () => {
    dispatch({ field: FormFields.Agreement, payload: !state[FormFields.Agreement] });
  };

  const submit = async () => {
    await captchaRef.current.verify();
  };

  const validateAgreement = agreement => new Promise((resolve, reject) => {
    if (agreement) {
      resolve();
    } else {
      reject();
    }
  });

  const showAgreementAlert = () => {
    alert('Необходимо согласиться с политикой в отношении обработки персональных данных');
  };

  const validateFormFields = (formState) => {
    const currentInvalidFormFields = getInvalidFormFields(formState);

    setInvalidFormFields(currentInvalidFormFields);

    return new Promise((resolve, reject) => {
      if (currentInvalidFormFields.length === 0) {
        resolve();
      } else {
        reject();
      }
    });
  };

  const sendForm = (captchaToken) => {
    pagesAPI
      .feedback({ ...state, captchaToken })
      .then(() => setResponseStatus(true));
  };

  const handleSuccessfulCaptchaValidation = (captchaToken) => {
    validateAgreement(state[FormFields.Agreement])
      .catch(showAgreementAlert)
      .then(() => validateFormFields(state))
      .then(() => sendForm(captchaToken))
      .catch(() => undefined);
  };

  if (responseStatus) {
    return (
      <div className="popup--question">
        <div className="popup__inner popup__inner--40">
          <form className="popup__form text-center">
            <h3 className="h3 form-h3">Вопрос отправлен!</h3>
            <p className="popup__text">
              Благодарим за обращение. С вами свяжутся в ближайшее
              <br />
              время.
            </p>
            <div className="form-line-bottom form-line-bottom--center">
              <button
                type="button"
                onClick={close}
                className="i-button i-button--200 i-button--white"
              >
                закрыть
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div ref={wrapperRef} className="popup--question">
      <div className="popup__inner">
        <h2 className="h2 popup__h2">Задать вопрос</h2>
        <form className="popup__form">
          <div className="form-group">
            <FormField>
              <Input
                value={state[FormFields.Email]}
                type="text"
                className={cx({ 'vf-error': invalidFormFields.includes(FormFields.Email) })}
                placeholder="E-mail"
                onChange={updateState}
                name={FormFields.Email}
              />
            </FormField>

            <FormField>
              <PhoneInput
                value={state[FormFields.Phone]}
                type="text"
                className={cx({ 'vf-error': invalidFormFields.includes(FormFields.Phone) })}
                placeholder="Телефон"
                onChange={updateState}
                name={FormFields.Phone}
              />
            </FormField>

            <FormField>
              <Textarea
                value={state[FormFields.Question]}
                className={cx('i-input popup__question', {
                  'vf-error': invalidFormFields.includes(FormFields.Question),
                })}
                placeholder="Ваш вопрос"
                onChange={updateState}
                name={FormFields.Question}
              />
            </FormField>

            <div className="form-line">
              <CustomCaptcha
                ref={captchaRef}
                onSuccess={handleSuccessfulCaptchaValidation}
              />
            </div>

            <FormField>
              <Checkbox
                required
                small
                toggle={toggleAgreement}
                checked={state[FormFields.Agreement]}
              >
                Согласие с
                &nbsp;
                <PolicyLink>
                  Политикой в отношении обработки персональных данных
                </PolicyLink>
              </Checkbox>
            </FormField>
          </div>

          <div className="form-line-bottom">
            <button type="button" onClick={submit} className="i-button i-button--200">
              Отправить
            </button>
            <button
              type="button"
              onClick={close}
              className="i-button i-button--140 i-button--white"
            >
              Отменить
            </button>
          </div>
        </form>
      </div>
      <button onClick={close} type="button" className="popup-close" title="Закрыть">
        <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24">
          <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
        </svg>
      </button>
    </div>
  );
};
