import React, {
  useCallback,
  useEffect, useReducer, useRef, useState,
} from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';


import * as registerActions from '../../store/Register/Register.actions';
import { getInvalidFormFields, isFieldValid } from '../../shared/Question/validation';
import FormField from '../../components/ui/Form/FormField';
import Input from '../../components/ui/Form/Input';
import Dropdown from '../../components/ui/Layout/Dropdown';
import { validateInn, validateKpp } from '../../shared/utils/validate';
import INNInput from '../../components/ui/Form/INNInput';
import KPPInput from '../../components/ui/Form/KPPInput';
import Select from '../../components/common/Register/Select';
import PhoneInput from '../../components/ui/Form/PhoneInput';
import NameInput from '../../components/ui/Form/NameInput';
import CustomCaptcha from '../../components/ui/CustomCaptcha/CustomCaptcha';
import Checkbox from '../../components/ui/Form/Checkbox';
import { privacyPolicyLink } from '../../shared/constants';

const reducer = (state, action) => {
  switch (action.field) {
    case 'email':
      return { ...state, email: action.payload };
    case 'password1':
      return { ...state, password1: action.payload };
    case 'password2':
      return { ...state, password2: action.payload };
    case 'middle_name':
      return { ...state, middle_name: action.payload };
    case 'first_name':
      return { ...state, first_name: action.payload };
    case 'last_name':
      return { ...state, last_name: action.payload };
    case 'full_name':
      return { ...state, full_name: action.payload };
    case 'INN':
      return { ...state, INN: action.payload };
    case 'KPP':
      return { ...state, KPP: action.payload };
    case 'legal_form':
      return { ...state, legal_form: action.payload };
    case 'website':
      return { ...state, website: action.payload };
    case 'phone':
      return { ...state, phone: action.payload };
    case 'organization_phone':
      return { ...state, organization_phone: action.payload };
    case 'region':
      return { ...state, region: action.payload };
    case 'captcha_token':
      return { ...state, captcha_token: action.payload };
    case 'agreement':
      return { ...state, agreement: action.payload };
    default:
      throw new Error(`Unknown field: ${action.field}`);
  }
};

const RegisterPage = ({
  dispatch,
  history,
  registerData,
  isLogged,
  dropdownData,
  dropdownDataRequested,
  ...props
}) => {
  const captchaRef = useRef();
  const [state, dispatchState] = useReducer(reducer, {
    email: '',
    password1: '',
    password2: '',
    middle_name: '',
    first_name: '',
    last_name: '',
    full_name: '',
    INN: '',
    KPP: '',
    legal_form: undefined,
    website: '',
    phone: '',
    organization_phone: '',
    region: undefined,
    captcha_token: '',
    agreement: false,
  });

  const [invalidFormFields, setInvalidFormFields] = useState([]);
  const setDefaultRegion = useCallback(() => {
    const { currentRegion, requestedRegion } = props;
    if (requestedRegion) return;
    dispatchState({ field: 'region', region: currentRegion.id });
  }, [props]);

  useEffect(() => {
    dispatch(registerActions.getDropdownData());
    setDefaultRegion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { currentRegion } = props;
    if (currentRegion) {
      setDefaultRegion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateState = ({ target }) => {
    const field = target.name;
    const { value } = target;

    if (value.length > 2) {
      if (!isFieldValid(field, value)
        && !invalidFormFields.includes(field)) {
        setInvalidFormFields([...invalidFormFields, field]);
      }
      if (isFieldValid(field, value)) {
        setInvalidFormFields(invalidFormFields.filter(name => name !== field));
      }
    }
    dispatchState({ field: target.name, payload: target.value });
  };

  const validateFormFields = () => {
    const {
      captcha_token, website, middle_name, ...fields
    } = state;
    const currentInvalidFormFields = getInvalidFormFields(fields);
    setInvalidFormFields(currentInvalidFormFields);

    return new Promise((resolve, reject) => {
      if (currentInvalidFormFields.length === 0) {
        resolve();
      } else {
        reject(Error(`Invalid form fields: ${currentInvalidFormFields}`));
      }
    });
  };

  const submit = async () => {
    await captchaRef.current.verify();
  };

  const sendForm = (captchaToken) => {
    dispatch(registerActions.registerRequest({ ...state, captcha_token: captchaToken }));
  };

  const onChangeRegion = value => {
    dispatchState({ field: 'region', payload: regionId(value) });
  };

  const onChangeLegalForm = value => {
    dispatchState({ field: 'legal_form', payload: typeId(value) });
  };

  const parseType = id => {
    if (dropdownData?.types) {
      const filteredArray = dropdownData?.types.filter(el => +el.id === +id);
      if (filteredArray.length < 1) return id;
      return filteredArray[0].name;
    }
    return id;
  };

  const parseRegion = id => {
    if (dropdownData?.regions) {
      const filteredArray = dropdownData?.regions.filter(el => +el.id === +id);
      if (filteredArray.length < 1) return id;
      return filteredArray[0].name;
    }
    return id;
  };

  const typeId = value => dropdownData?.types.find(el => el.name === value).id;

  const regionId = value => dropdownData?.regions.find(el => el.alias === value).id;

  const toggleAgreement = () => {
    dispatchState({ field: 'agreement', payload: !state.agreement });
  };

  const validateAgreement = isAgreement => new Promise((resolve, reject) => {
    if (isAgreement) {
      resolve();
    } else {
      reject();
    }
  });

  const showAgreementAlert = () => {
    alert('Необходимо согласиться с политикой в отношении обработки персональных данных');
  };

  const handleSuccessfulCaptchaValidation = (captchaToken) => {
    validateAgreement(state.agreement)
      .catch(showAgreementAlert)
      .then(() => validateFormFields())
      .then(() => sendForm(captchaToken))
      .catch(() => undefined);
  };

  const getFieldValidStatus = field => {
    if (field === 'kpp') return validateKpp(state.KPP, {});
    if (field === 'inn') return validateInn(state.INN, {});
    if (field === 'region' || field === 'legal_form') return state[field] > 0;
    return !invalidFormFields.includes(field) && state[field]?.length > 2;
  };

  const isFieldInvalid = field => {
    if (field === 'kpp') return (!validateKpp(state.KPP, {}) && state.KPP.length > 2) || invalidFormFields.includes('KPP');
    if (field === 'inn') return (!validateInn(state.INN, {}) && state.INN.length > 2) || invalidFormFields.includes('INN');
    if (field === 'region') return invalidFormFields.includes('region') && typeof state.region === 'undefined';
    if (field === 'legal_form') return state.legal_form === undefined && invalidFormFields.includes('region');
    return invalidFormFields.includes(field);
  };

  if (isLogged) {
    return <Redirect to="/cabinet" />;
  }

  return (
    <>
      <Helmet title="Регистрация" />
      <div className="w-content">
        <div className="inner inner--pb registration">
          <div className="container">
            <h1 className="h1">Регистрация</h1>
            <hr className="hr hr--light hr--big" />
            {registerData.registerSuccess && (
              <>
                <h3 className="h3 form-h3">
                  Регистрация пройдена успешно. Проверьте ваш почтовый ящик.
                </h3>
                <button
                  type="button"
                  onClick={() => history.push('/')}
                  style={{ marginBottom: 200 }}
                  className="i-button i-button--140 i-button--white"
                >
                  Назад
                </button>
              </>
            )}
            {!registerData.registerSuccess && (
            <form className="registration__form js-form">
              <div className="form-group">
                <h3 className="h3 form-h3">Данные организации</h3>
                <FormField className={cx({ 'form-field--ok': getFieldValidStatus('full_name') })}>
                  <Input
                    type="text"
                    className={cx('i-input', {
                      'vf-error': invalidFormFields.includes('full_name'),
                    })}
                    name="full_name"
                    placeholder="Полное наименование организации"
                    required
                    value={state.full_name}
                    onChange={updateState}
                  />
                </FormField>

                <FormField
                  className={cx({ 'form-field--ok': getFieldValidStatus('legal_form') })}
                  label="Организационно-правовая форма"
                >
                  {!dropdownDataRequested && dropdownData && (
                    <Dropdown
                      placeholder="Выберите из списка"
                      options={dropdownData?.types}
                      value={parseType(state.legal_form)}
                      onChange={onChangeLegalForm}
                      optionsWithId
                      error={isFieldInvalid('legal_form')}
                    />
                  )}
                </FormField>

                <FormField className={cx({ 'form-field--ok': getFieldValidStatus('inn') })}>
                  <INNInput
                    className={cx('i-input', {
                      'vf-error': isFieldInvalid('inn'),
                    })}
                    required
                    name="INN"
                    value={state.INN}
                    onChange={updateState}
                  />
                </FormField>

                {!(state.legal_form === 'ИП' || state.legal_form === 'Самозанятый') && (
                  <FormField
                    className={cx({ 'form-field--ok': getFieldValidStatus('kpp') })}
                  >
                    <KPPInput
                      name="KPP"
                      className={cx({
                        'vf-error': isFieldInvalid('kpp'),
                      })}
                      required
                      value={state.KPP}
                      onChange={updateState}
                    />
                  </FormField>
                )}

                {!dropdownDataRequested && dropdownData && (
                  <FormField
                    className={cx({ 'form-field--ok': state.region > 0 })}
                    label="Регион деятельности компании"
                  >
                    <Select
                      placeholder="Выберите из списка"
                      items={dropdownData?.regions}
                      value={{ label: parseRegion(state.region), value: state.region }}
                      onChange={onChangeRegion}
                      error={isFieldInvalid('region')}
                    />
                  </FormField>
                )}
                <FormField className={cx({ 'form-field--ok': getFieldValidStatus('website') })}>
                  <Input
                    type="text"
                    name="website"
                    placeholder="Сайт компании (не обязательно)"
                    required
                    value={state.website}
                    onChange={updateState}
                  />
                </FormField>
                <FormField
                  className={cx({ 'form-field--ok': getFieldValidStatus('organization_phone') })}
                >
                  <PhoneInput
                    placeholder="Телефон организации"
                    className={cx('i-input', {
                      'vf-error': isFieldInvalid('organization_phone'),
                    })}
                    value={state.organization_phone}
                    onChange={updateState}
                    name="organization_phone"
                  />
                </FormField>
              </div>

              <div className="form-group">
                <h3 className="h3 form-h3">Данные контактного лица</h3>
                <FormField className={cx({ 'form-field--ok': getFieldValidStatus('last_name') })}>
                  <NameInput
                    type="text"
                    className={cx('i-input', {
                      'vf-error': isFieldInvalid('last_name'),
                    })}
                    placeholder="Фамилия"
                    value={state.last_name}
                    onChange={updateState}
                    name="last_name"
                  />
                </FormField>

                <FormField className={cx({ 'form-field--ok': getFieldValidStatus('first_name') })}>
                  <NameInput
                    type="text"
                    className={cx('i-input', {
                      'vf-error': isFieldInvalid('first_name'),
                    })}
                    placeholder="Имя"
                    value={state.first_name}
                    onChange={updateState}
                    name="first_name"
                  />
                </FormField>

                <FormField className={cx({ 'form-field--ok': getFieldValidStatus('middle_name') })}>
                  <NameInput
                    type="text"
                    placeholder="Отчество (не обязательно)"
                    value={state.middle_name}
                    onChange={updateState}
                    name="middle_name"
                  />
                </FormField>

                <FormField className={cx({ 'form-field--ok': getFieldValidStatus('phone') })}>
                  <PhoneInput
                    type="text"
                    className={cx('i-input', { 'vf-error': isFieldInvalid('phone') })}
                    placeholder="Телефон"
                    value={state.phone}
                    onChange={updateState}
                    name="phone"
                  />
                </FormField>

                <FormField className={cx({ 'form-field--ok': getFieldValidStatus('email') })}>
                  <Input
                    type="email"
                    className={cx({ 'vf-error': isFieldInvalid('email') })}
                    placeholder="E-mail ( логин для входа в личный кабинет)"
                    value={state.email}
                    onChange={updateState}
                    name="email"
                  />
                </FormField>
              </div>

              <div className="form-group">
                <h3 className="h3 form-h3">Создание пароля</h3>
                <FormField
                  className={cx({ 'form-field--ok': getFieldValidStatus('password1') })}
                  tip="Пароль должен содержать не менее 8 символов: не менее 1 цифры, не менее 1 буквы
                        в верхнем регистре, не менее 1 буквы в нижнем регистре, не менее 1 спецсимвола (!@#$%^&*)"
                >
                  <Input
                    className={cx({ 'vf-error': isFieldInvalid('password1') })}
                    type="password"
                    placeholder="Пароль"
                    value={state.password1}
                    onChange={updateState}
                    name="password1"
                  />
                </FormField>

                <FormField className={cx({ 'form-field--ok': state.password1 === state.password2 && state.password2.length > 2 })}>
                  <Input
                    type="password"
                    className={cx({ 'vf-error': state.password1 !== state.password2 })}
                    placeholder="Подтверждение пароля"
                    value={state.password2}
                    onChange={updateState}
                    name="password2"
                  />
                </FormField>

                <FormField className={cx({ 'form-field--ok': state.captcha_token.length })}>
                  <CustomCaptcha
                    ref={captchaRef}
                    onSuccess={handleSuccessfulCaptchaValidation}
                  />
                </FormField>

                <FormField>
                  <Checkbox required toggle={toggleAgreement} checked={state.agreement}>
                    Cогласие с{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={privacyPolicyLink}
                    >
                      Политикой в отношении обработки персональных данных
                    </a>
                  </Checkbox>
                </FormField>
              </div>

              <div className="form-line-bottom">
                <button
                  onClick={submit}
                  type="button"
                  className="i-button i-button--200"
                >
                  {registerData.registerRequested ? 'load...' : 'Зарегистрироваться'}
                </button>
                <button
                  type="button"
                  onClick={() => history.goBack()}
                  className="i-button i-button--140 i-button--white"
                >
                  Отменить
                </button>
              </div>
              {registerData.registerError && registerData.errorMessage}
            </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};


const mapStateToProps = state => ({
  registerData: state.register,
  dropdownData: state.register.dropdownData,
  dropdownDataRequested: state.register.dropdownDataRequested,
  isLogged: state.login.loginSuccess,
  currentRegion: state.login.geolocationCurrent,
  requestedRegion: state.login.geolocationRequested,
});

export default connect(mapStateToProps)(withRouter(RegisterPage));
