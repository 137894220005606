import React from 'react';
import cx from 'classnames';

const Textarea = ({
  name, placeholder, className, value, onChange, required, type, disabled, id,
}) => (
  <textarea
    id={id}
    value={value}
    onChange={onChange}
    className={cx('i-input', className)}
    placeholder={placeholder}
    required={required}
    name={name}
    type={type}
    disabled={disabled}
    maxLength="2000"
  />
);

export default Textarea;
