import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';

import AuthAPI from '../../services/authAPI';

const authApi = new AuthAPI();

const Verify = ({ match }) => {
  const [verified, setVerified] = useState(false);

  const { hash } = match.params;

  useEffect(() => {
    authApi
      .verify(hash)
      .then(res => setVerified(true))
      .catch(() => setVerified(false));
  }, [hash]);

  return (
    <>
      <Helmet title="Подтверждение регистрации" />
      <div className="w-content">
        <div className="inner terms">
          <div className="container">
            <h1 className="h1 h1--mb30">Подтверждение регистрации</h1>
          </div>
          <div style={{ marginBottom: 200 }} className="container">
            <p className="terms__content">
              {verified
                ? 'Ваш email-успешно подтвержден, и вы можете войти в систему.'
                : 'Произошла ошибка. Пожалуйста, попробуйте позже, либо обратитесь в поддержку.'}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verify;
