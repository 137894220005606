import React, { useState } from 'react';
import cx from 'classnames';

const FilterWrapper = ({
  title,
  children,
  closed,
}) => {
  const [innerClosed, setInnerClosed] = useState(closed);

  const toggle = () => setInnerClosed(!innerClosed);

  return (
    <div className="filter">
      <div
        onClick={toggle}
        className={cx('filter__head js-filterTitle', { 'is-open': !innerClosed })}
      >
        <div className="filter__head-title">{title}</div>
      </div>

      <div style={{ display: innerClosed ? 'none' : 'block' }} className="filter__body js-filterBody">
        <div className="filter__body-inner">{children}</div>
      </div>
    </div>
  );
};

export default FilterWrapper;
