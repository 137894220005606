import React from 'react';

const Plus = () => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 6.99948C0 6.41037 0.477563 5.93281 1.06667 5.93281H10.9333C11.5224 5.93281 12 6.41037 12 6.99948C12 7.58858 11.5224 8.06614 10.9333 8.06614H1.06667C0.477562 8.06614 0 7.58858 0 6.99948Z"
      fill="black"
    />
    <path
      d="M6 13.3996C5.44772 13.3996 5 12.9519 5 12.3996V1.59961C5 1.04733 5.44772 0.599609 6 0.599609C6.55228 0.599609 7 1.04733 7 1.59961V12.3996C7 12.9519 6.55228 13.3996 6 13.3996Z"
      fill="black"
    />
  </svg>
);

export default Plus;
