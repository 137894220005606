export const defaultStyles = {
  option: (provided, state) => ({
    color: state.isSelected || state.isFocused ? '#E61801' : '#000',
    padding: '8px 15px',
    background: !state.isFocused ? 'white' : '#f9f9f9',
    cursor: 'pointer',
    borderBottom: '1px solid #f5f5f5',
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: '2px',
    color: '#000',
    cursor: 'text',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    cursor: 'pointer',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

export const errorStyles = {
  option: (provided, state) => ({
    color: state.isSelected || state.isFocused ? '#E61801' : '#000',
    padding: '8px 15px',
    background: !state.isFocused ? 'white' : '#f9f9f9',
    cursor: 'pointer',
    borderBottom: '1px solid #f5f5f5',
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: '2px',
    color: '#000',
    borderColor: !state.menuIsOpen ? '#E61801' : 'transparent',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};
