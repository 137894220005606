import React from 'react';

export const RequestHeader = ({ group, data }) => (
  <div className="popup__header">

    <h2 className="h3 popup__header-title">
      Заявка
      <span className="popup__header-order">на объект № {data.asko_id}</span>
    </h2>

    <h3 className="h3 h3--mb20">{data.name}</h3>

    <div className="popup__header-row">
      <div className="popup__header-item">
        <div className="popup__header-caption">Площадь:</div>
        <div className="txt-val popup__header-val">
          {data.total_area} <small>м²</small>
        </div>
      </div>

      {group && (
        <div className="popup__header-item">
          <div className="popup__header-caption"> Площадей:</div>
          <div className="txt-val popup__header-val">{data.space_amount} </div>
          {' '}
        </div>
      )}

      <div className="popup__header-item">
        <div className="popup__header-caption">Аренда в месяц:</div>
        <div className="txt-val popup__header-val">
          <small>от </small>
          {data.base_rate}
          <small> ₽/мес</small>
        </div>
      </div>

      <div className="popup__header-item">
        <div className="popup__header-caption">Срок аренды:</div>
        <div className="txt-val popup__header-val">
          {data.lease_term}
          <small> мес</small>
        </div>
      </div>
    </div>
  </div>
);
