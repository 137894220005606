import React from 'react';
import InputMask from 'react-input-mask';

import cx from 'classnames';

const INNInput = ({
  value, className, required, name, onChange, disabled, id,
}) => (
  <InputMask
    id={id}
    value={value}
    onChange={onChange}
    className={cx('i-input', className)}
    mask="999999999999"
    placeholder="ИНН"
    name={name}
    autoComplete="off"
    required={required}
    maskChar=""
    disabled={disabled}
  />
);
export default INNInput;
