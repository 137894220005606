import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { connect } from 'react-redux';
import { loginActions } from '../../../store';
import Login from '../../modals/Login/Login';
import Portal from '../../../shared/utils/Portal';

import Regions from './Regions';

import getBreadCrumbs from '../../../shared/utils/getBreadCrumbs';

const Header = ({
  location,
  loginData,
  dispatch,
}) => {
  const [modalOpened, setModalOpened] = useState(false);
  const [regionsOpened, setRegionsOpened] = useState(false);
  const { pathname } = location;

  const toggleModal = () => {
    setModalOpened(!modalOpened);
  };

  const toggleRegions = () => {
    setRegionsOpened(!regionsOpened);
  };

  if (pathname.includes('barcode')) {
    return (
      <header className="header">
        <div className="header__top">
          <div className="container">
            <div className="header__top-wrap">
              <div className="header__top-left">
                <Link to="/" className="header__logo" />
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
  return (
    <>
      <header className={cx('header', { 'header--index': pathname === '/' })}>
        <div className="header__top">
          <div className="container">
            <div className="header__top-wrap">
              <div className="header__top-left">
                <Link to="/" className="header__logo" />
                <div
                  role="button"
                  tabIndex="0"
                  onKeyDown={toggleRegions}
                  onClick={toggleRegions}
                  className="header__location"
                >
                  <div className="header__location-current" id="location">
                    {!loginData.geolocationRequested
                      ? loginData.geolocationCurrent.name
                      : 'Загрузка...'}
                  </div>
                </div>
                <a href="tel:88005555505" className="header__phone">
                  <div className="header__phone-num">8 800 555-55-05</div>
                  <div className="header__phone-title">Горячая линия</div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="header__menu">
          <div className="container">
            <div className="header__menu-wrap">
              <div className="header__menu-left">
                <nav className="header__nav">
                  <Link to="/find?applyCurrentLocation=1" className="header__nav-item">
                    Свободные площади
                  </Link>
                  <Link to="/offer" className="header__nav-item">
                    Сезонные предложения
                  </Link>
                  <Link to="/terms" className="header__nav-item">
                    Условия сотрудничества
                  </Link>
                </nav>
              </div>
              <div className="header__menu-right">
                {loginData.loginSuccess ? (
                  <>
                    <Link to="/cabinet" className="i-button i-button--outline header__email">
                      {loginData.userData.email}
                    </Link>
                    <span
                      role="button"
                      tabIndex="0"
                      onKeyDown={() => dispatch(loginActions.logout())}
                      onClick={() => dispatch(loginActions.logout())}
                      className="i-button i-button--white header__signon"
                    >
                      Выйти
                    </span>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      onSubmit={toggleModal}
                      onClick={toggleModal}
                      onKeyDown={toggleModal}
                      tabIndex="0"
                      className="i-button i-button--outline header__signin"
                    >
                      личный кабинет партнёра
                    </button>
                    <Link to="/register" className="i-button i-button--white header__signon">
                      Регистрация
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="header__breadcrumbs">
          <div className="container">
            <div className="header__breadcrumbs-wrap">
              {getBreadCrumbs(pathname).map(el => (
                <Link key={el.name} to={el.link} className="header__breadcrumbs-item">
                  {el.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
        {modalOpened && (
          <Portal>
            <Login close={toggleModal} />
          </Portal>
        )}
      </header>
      {regionsOpened && <Regions close={toggleRegions} />}
    </>
  );
};

const mapStateToProps = state => ({
  loginData: state.login,
});

export default connect(mapStateToProps)(Header);
