import React from 'react';
import cx from 'classnames';

const Input = ({
  name,
  placeholder,
  className,
  value,
  onChange,
  required,
  type,
  disabled,
  maxLength,
  id,
}) => (
  <input
    id={id}
    value={value}
    onChange={onChange}
    className={cx('i-input', className)}
    placeholder={placeholder}
    required={required}
    name={name}
    type={type}
    disabled={disabled}
    maxLength={maxLength}
  />
);

export default Input;
