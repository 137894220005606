import React, {
  useEffect, useMemo, useReducer, useState,
} from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';

import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import _ from 'lodash';
import Plus from '../../../components/ui/svg/plus';
import FormField from '../../../components/ui/Form/FormField';
import Dropdown from '../../../components/ui/Layout/Dropdown';
import Input from '../../../components/ui/Form/Input';
import PhoneInput from '../../../components/ui/Form/PhoneInput';
import NameInput from '../../../components/ui/Form/NameInput';
import INNInput from '../../../components/ui/Form/INNInput';
import KPPInput from '../../../components/ui/Form/KPPInput';

import OrganizationAPI from '../../../services/organizationAPI';
import { getInvalidFormFields } from '../../../shared/Question/validation';
import { registerActions } from '../../../store';
import { formatPhoneNumber } from '../../../shared/utils/formatPhoneNumber';

const reducer = (state, action) => {
  switch (action.type) {
    case 'state':
      return { ..._.omitBy(action.payload, _.isNull) };
    case 'email':
      return { ...state, email: action.payload };
    case 'middle_name':
      return { ...state, middle_name: action.payload };
    case 'first_name':
      return { ...state, first_name: action.payload };
    case 'last_name':
      return { ...state, last_name: action.payload };
    case 'full_name':
      return { ...state, full_name: action.payload };
    case 'INN':
      return { ...state, INN: action.payload };
    case 'KPP':
      return { ...state, KPP: action.payload };
    case 'legal_form':
      return { ...state, legal_form: action.payload };
    case 'phone':
      return { ...state, phone: action.payload };
    case 'add_organization_interests':
      return {
        ...state,
        organization_interests: [...state.organization_interests, action.payload],
      };
    case 'organization_interest':
      return { ...state, organization_interests: action.payload };
    case 'activities':
      return { ...state, activities: action.payload };
    case 'add_activities':
      return {
        ...state,
        activities: [...state.activities, action.payload],
      };
    default:
      throw new Error('Error');
  }
};

const organizationApi = new OrganizationAPI();

const Data = ({
  dispatch,
  dropdownData,
  history,
}) => {
  const [state, dispatchState] = useReducer(reducer, {
    email: '',
    middle_name: '',
    first_name: '',
    last_name: '',
    full_name: '',
    INN: '',
    KPP: '',
    legal_form: '',
    phone: '',
    organization_interests: [],
    activities: [],
  });
  const [invalidFormFields, setInvalidFormFields] = useState([]);
  const [resultMessage, setResultMessage] = useState('');

  const checkActivities = (activities) => {
    if (!activities.length) {
      dispatchState({
        type: 'activities',
        payload: [{
          main_activity: true,
          activity_type: 0,
        }],
      });
    }
  };

  useEffect(() => {
    organizationApi
      .getOrganization()
      .then(({ data }) => {
        const { contact, ...res } = data;
        const activities = [...data.activities]
          .sort((a, b) => b.main_activity - a.main_activity);
        dispatchState({
          type: 'state',
          payload: {
            ...contact,
            ...res,
            phone: formatPhoneNumber(contact.phone),
            activities,
          },
        });
        checkActivities(res.activities);
      });
    dispatch(registerActions.getDropdownData());
  }, [dispatch]);

  const setData = ({ target }) => {
    dispatchState({
      ...state,
      [target.name]: target.value,
    });
    setInvalidFormFields({
      ...invalidFormFields,
      [target.name]: validateData(target),
    });
    setResultMessage(undefined);
  };

  const validateData = ({ name, value }) => {
    const phone = /(.*\d.*){11}/;
    if (name === 'phone') {
      return value.search(phone) !== -1;
    } if (name === 'first_name') {
      return value.length >= 2;
    } if (name === 'last_name') {
      return value.length >= 2;
    }
    return true;
  };

  const addActivity = () => {
    dispatchState({ type: 'add_activities', payload: { activity_type: 0, main_activity: false } });
  };

  const addInterest = () => {
    dispatchState({ type: 'add_organization_interests', payload: 0 });
  };

  const activityId = value => {
    const { activities } = dropdownData;
    return activities.find(el => el.name === value).id;
  };

  const onChangeActivity = (value, id) => {
    const newActivities = state.activities;
    newActivities[id].activity_type = activityId(value);
    dispatchState({
      type: 'activities',
      payload: newActivities,
    });
    setResultMessage(undefined);
  };

  const onChangeInterest = (value, id) => {
    const newInterests = state.organization_interests;
    newInterests[id] = interestId(value);
    dispatchState({
      type: 'organization_interest',
      payload: newInterests,
    });
    setResultMessage(undefined);
  };

  const parseType = id => {
    const { types } = dropdownData;
    const filteredArray = types.filter(el => +el.id === +id);
    if (filteredArray.length < 1) return id;
    return filteredArray[0].name;
  };

  const parseActivity = id => {
    const { activities } = dropdownData;
    const filteredArray = activities.filter(el => +el.id === +id);
    if (filteredArray.length < 1) return id;
    return filteredArray[0].name;
  };

  const parseInterest = id => {
    const { interests } = dropdownData;
    const filteredArray = interests.filter(el => +el.id === +id);
    if (filteredArray.length < 1) return id;
    return filteredArray[0].name;
  };


  const typeId = value => {
    const { types } = dropdownData;
    return types.find(el => el.name === value).id;
  };

  const onChangeLegalForm = value => {
    dispatchState({ type: 'legal_form', payload: typeId(value) });
  };


  const interestId = value => {
    const { interests } = dropdownData;
    return interests.find(el => el.name === value).id;
  };

  const updateState = ({ target }) => {
    dispatchState({ type: target.name, payload: target.value });
  };

  const sendData = () => {
    const data = {
      ...state,
      contact: {
        middle_name: state.middle_name,
        first_name: state.first_name,
        last_name: state.last_name,
        phone: state.phone,
        receive_notifications: state.receive_notifications,
      },
    };
    const { phone, first_name, last_name } = state;
    const currentInvalidFormFields = getInvalidFormFields({ phone, first_name, last_name });
    setInvalidFormFields(currentInvalidFormFields);
    if (currentInvalidFormFields.length > 0) {
      setResultMessage('Ошибка ввода данных');
    } else {
      organizationApi
        .patchOrganization(data)
        .then(() => {
          setResultMessage('Успешно');
        })
        .catch(() => {
          setResultMessage('Ошибка ввода данных');
        });
    }
  };

  const currentOrganizationType = useMemo(() => {
    try {
      const { types: organizationTypes } = dropdownData;
      const { organization_type: organizationTypeId } = state;

      const organizationType = organizationTypes.find(type => type.id === organizationTypeId);

      if (organizationType === undefined) {
        throw new Error(`Cannot find organization with type id: ${organizationTypeId}`);
      }

      return organizationType;
    } catch {
      return null;
    }
  }, [dropdownData, state]);

  const showKPPField = useMemo(() => {
    if (currentOrganizationType === null) return false;

    return !['ИП', 'Самозанятый'].includes(currentOrganizationType.name);
  }, [currentOrganizationType]);

  if (!dropdownData) return 'Load....';

  return (
    <>
      <Helmet title="Редактирование данных" />
      <div className="lk-content lk-data">
        <h1 className="h2 lk-data__title">Редактирование данных</h1>

        <form>
          <div className="form-wrap">
            <div className="form-group">
              <FormField className={cx({ 'form-field--ok': !invalidFormFields.includes('last_name') })}>
                <NameInput
                  type="text"
                  className={cx('i-input', {
                    'vf-error': invalidFormFields.includes('last_name'),
                  })}
                  placeholder="Фамилия"
                  value={state.last_name}
                  onChange={updateState}
                  name="last_name"
                />
              </FormField>
              <FormField className={cx({ 'form-field--ok': !invalidFormFields.includes('first_name') })}>
                <NameInput
                  type="text"
                  className={cx('i-input', {
                    'vf-error': invalidFormFields.includes('first_name'),
                  })}
                  placeholder="Имя"
                  value={state.first_name}
                  onChange={updateState}
                  name="first_name"
                />
              </FormField>
              <FormField>
                <NameInput
                  type="text"
                  placeholder="Отчество (не обязательно)"
                  value={state.middle_name}
                  onChange={updateState}
                  name="middle_name"
                />
              </FormField>
              <FormField
                tip={(
                  <>
                    Для изменения e-mail обратитесь в службу <span>технической поддержки</span>
                  </>
                )}
              >
                <Input
                  type="text"
                  className="i-input"
                  placeholder="E-mail"
                  value={state.email}
                  disabled
                />
              </FormField>

              <FormField className={cx({ 'form-field--ok': !invalidFormFields.includes('phone') })}>
                <PhoneInput
                  type="text"
                  className={cx('i-input', { 'vf-error': invalidFormFields.includes('phone') })}
                  placeholder="+7 (912) 125-11-88"
                  value={state.phone}
                  onChange={updateState}
                  name="phone"
                />
              </FormField>

              <FormField>
                <Input
                  type="text"
                  className="i-input"
                  placeholder="Организация"
                  value={state.full_name}
                  disabled
                />
              </FormField>

              <FormField label="Организационно-правовая форма">
                <Dropdown
                  disabled
                  value={parseType(state?.organization_type)}
                  onChange={onChangeLegalForm}
                />
              </FormField>

              <FormField>
                <INNInput
                  disabled
                  required
                  name="INN"
                  value={state.INN}
                  onChange={setData}
                />
              </FormField>

              {showKPPField && (
                <FormField>
                  <KPPInput
                    name="KPP"
                    disabled
                    required
                    value={state.KPP}
                    onChange={setData}
                  />
                </FormField>
              )}
            </div>
            <div className="form-group">
              <h3 className="h3 form-h3">Вид деятельности</h3>
              {dropdownData && state.activities
                && state.activities.map((el, idx) => {
                  if (el.main_activity) {
                    return (
                      <FormField key={idx} label="Основной">
                        {dropdownData && (
                          <Dropdown
                            options={dropdownData?.activities}
                            placeholder="Выберите из списка"
                            value={parseActivity(state?.activities[idx].activity_type)}
                            onChange={dropdownValue => onChangeActivity(dropdownValue, idx)}
                            optionsWithId
                          />
                        )}
                      </FormField>
                    );
                  }
                  return (
                    <FormField key={idx} label={idx <= 1 ? 'Дополнительный' : ''}>
                      {dropdownData && (
                        <Dropdown
                          options={dropdownData.activities}
                          placeholder="Выберите из списка"
                          value={parseActivity(state.activities[idx].activity_type)}
                          onChange={dropdownValue => onChangeActivity(dropdownValue, idx)}
                          optionsWithId
                        />
                      )}
                    </FormField>
                  );
                })}
              {state.activities.length < 6 && (
                <div className="form-add">
                  <div className="form-add__row">
                    <div className="form-add__item">
                      <button
                        className="i-button i-button--outline i-button--small form-add__btn"
                        id="tmpBtnDVD"
                        onClick={addActivity}
                        type="button"
                      >
                        <i>
                          <Plus />
                        </i>
                        Добавить еще
                      </button>
                      <div className="form-add__title">
                        (можно добавить до 5 дополнительных видов деятельности)
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="form-group">
              <h3 className="h3 form-h3">Интересующие категории площадей</h3>
              <div className="form-line__group">
                {state.organization_interests.map((el, idx) => (
                  <FormField key={idx}>
                    {dropdownData && (
                      <Dropdown
                        options={dropdownData.interests}
                        placeholder="Выберите из списка"
                        value={parseInterest(el)}
                        onChange={dropdownValue => onChangeInterest(dropdownValue, idx)}
                        optionsWithId
                      />
                    )}
                  </FormField>
                ))}
              </div>
              {state.organization_interests.length < 5 && (
                <div className="form-add">
                  <div className="form-add__row">
                    <div className="form-add__item">
                      <button
                        type="button"
                        onClick={addInterest}
                        className="i-button i-button--outline i-button--small form-add__btn"
                      >
                        <i>
                          <Plus />
                        </i>
                        Добавить еще
                      </button>
                      <div className="form-add__title">
                        (Можно добавить до 5 дополнительных интересующих категорий площадей)
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="form-line-bottom form-line-bottom--justify">
              <button onClick={sendData} type="button" className="i-button i-button--270">
                {resultMessage || 'Сохранить'}
              </button>
              <button type="button" onClick={history.goBack} className="i-button i-button--white">
                Отменить
              </button>
            </div>
          </div>
        </form>
        <div className="lk-data__partner">
          <h3 className="h3 h3--mb20">Анкета партнёра</h3>
          <Link
            to="/cabinet/data/profile"
            className="i-button i-button--270 i-button--outline lk-data__partner-btn"
          >
            Заполнить анкету партнёра
          </Link>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  dropdownData: state.register.dropdownData,
});

export default connect(mapStateToProps)(Data);
