import React, { useCallback, useMemo, useRef } from 'react';
import Select, { components } from 'react-select';
import defaultSelectStyles from './DefaultSelect.module.scss';
import { defaultStyles, errorStyles } from './DefaultSelect.styles';

function CustomIndicatorsContainer({ isClearable, onClear, ...props }) {
  return (
    <div className={defaultSelectStyles.indicatorsContainer}>
      {isClearable && (
        <div
          className={defaultSelectStyles.clear}
          role="button"
          tabIndex={0}
          onClick={onClear}
          onKeyDown={event => event.key === 'Enter' && onClear()}
        >
          <svg
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            className="css-tj5bde-Svg"
          >
            <path
              d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
            />
          </svg>
        </div>
      )}
      <components.IndicatorsContainer {...props} />
    </div>
  );
}

export default function DefaultSelect({
  items,
  value,
  searchValue,
  placeholder,
  error,
  noOptionsMessage,
  isClearable,
  isSearchable,
  menuIsOpen,
  onClear = () => undefined,
  onChange,
  onSearch = () => undefined,
  onMenuOpen,
  onMenuClose,
  onFocus,
  onBlur,
}) {
  const selectRef = useRef();
  const styles = useMemo(() => (error ? errorStyles : defaultStyles), [error]);
  const noOptionsMessageFn = useCallback(() => (
    noOptionsMessage || 'Не найдено результатов'
  ), [noOptionsMessage]);

  const customFilter = option => option;

  return (
    <Select
      ref={selectRef}
      className={defaultSelectStyles.container}
      components={{
        IndicatorsContainer: (props) => (
          <CustomIndicatorsContainer
            {...props}
            isClearable={isClearable}
            onClear={onClear}
          />
        ),
      }}
      options={items}
      filterOption={customFilter}
      value={value}
      inputValue={searchValue}
      placeholder={placeholder}
      noOptionsMessage={noOptionsMessageFn}
      isSearchable={isSearchable}
      isClearable={false}
      styles={styles}
      menuIsOpen={menuIsOpen}
      onInputChange={(newValue, actionMeta) => {
        if (actionMeta.action === 'input-change' || actionMeta.action === 'set-value') {
          onSearch(newValue);
        }
      }}
      onChange={onChange}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
}
