import React, {
  useCallback,
  useEffect, useRef, useState,
} from 'react';
import { Link } from 'react-router-dom';
import {
  format,
  parse,
  addMonths,
  endOfMonth,
  startOfMonth,
  differenceInCalendarMonths,
} from 'date-fns';
import ru from 'date-fns/locale/ru';
import Dropdown from '../../components/ui/Layout/Dropdown';
import getDate from '../../shared/utils/getDate';

const Barcode = ({
  boundDates,
  close,
  id,
}) => {
  const [noDates, setNoDates] = useState(true);
  const [dates, setDates] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [error, setError] = useState('');
  const wrapperRef = useRef();

  const closeOnEsc = useCallback(e => {
    if (e.keyCode === 27) {
      close();
    }
  }, [close]);

  const generateDates = useCallback((data) => {
    if (!data.from_date || !data.to_date) {
      setNoDates(true);
      return;
    }
    setNoDates(false);
    const [startDate_, endDate_] = [startOfMonth(data.from_date), endOfMonth(data.to_date)];
    const numMonths = differenceInCalendarMonths(endDate_, startDate_) + 2;
    const dates_ = [];
    for (let i = 0; i < numMonths; i += 1) {
      const date = format(addMonths(startDate_, i), 'MMMM YYYY', { locale: ru });
      dates_.push(date[0].toUpperCase() + date.slice(1));
    }
    setDates(dates_);
    window.format = format;
    window.parse = parse;
  }, []);

  const handleClickOutside = useCallback(event => {
    if (wrapperRef && !wrapperRef.current.contains(event.target)) {
      close();
    }
  }, [close]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', closeOnEsc);
    generateDates(boundDates);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', closeOnEsc);
    };
  }, [boundDates, closeOnEsc, generateDates, handleClickOutside]);

  const parseDate = value => {
    const parsed = getDate(value).split('.');
    parsed.reverse();
    return new Date(parsed.join('-'));
  };

  const checkDates = () => {
    if (startDate && endDate && (parseDate(startDate) > parseDate(endDate))) {
      setError('"Дата с" позже "Дата по"');
    } else {
      setError('');
    }
  };

  const setStartValue = value => {
    setStartDate(value);
    checkDates();
  };

  const setEndValue = value => {
    setEndDate(value);
    checkDates();
  };

  if (noDates) {
    return (
      <div ref={wrapperRef} className="popup--barcode">
        <div className="popup__header">
          <div className="popup__header-mute">Нет данных</div>
        </div>
        <button
          onClick={close}
          type="button"
          className="popup-close"
          title="Закрыть"
        >
          <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24">
            <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
          </svg>
        </button>
      </div>
    );
  }
  return (
    <div ref={wrapperRef} className="popup--barcode">
      <div className="popup__header">
        <h2 className="h3 h3--mb20 popup__header-title">Формирование штрих-кода</h2>
        <div className="popup__header-mute">
          Укажите период, для которого нужно сформировать штрих-код.
        </div>
      </div>
      <div className="popup__inner popup__inner--40">
        <form className="popup__form">
          <div className="form-group">
            <div className="form-line">
              <span className="form-label">Дата с</span>
              {dates && (
              <Dropdown
                onChange={setStartValue}
                options={dates}
                placeholder="Выберите дату"
                value={startDate}
              />
              )}
            </div>
            <div className="form-line">
              <span className="form-label">Дата по</span>
              {dates && (
              <Dropdown
                onChange={setEndValue}
                options={dates}
                placeholder="Выберите дату"
                value={endDate}
              />
              )}
            </div>
          </div>
          {error && <div className="form-line-bottom">{error}</div>}
          <div className="form-line-bottom">
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to={`/barcode/${id}?date_from=${getDate(startDate)}&date_to=${getDate(
                endDate,
              )}`}
            >
              <button disabled={!!error} type="button" className="i-button i-button--200">
                сформировать
              </button>
            </Link>

            <button
              type="button"
              onClick={close}
              className="i-button i-button--200 i-button--white"
            >
              Отменить
            </button>
          </div>
        </form>
      </div>

      <button onClick={close} type="button" className="popup-close" title="Закрыть">
        <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24">
          <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
        </svg>
      </button>
    </div>
  );
};

export default Barcode;
