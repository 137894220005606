import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import Tooltip from '../../../components/ui/Layout/Tooltip';
import OrganizationAPI from '../../../services/organizationAPI';

const organizationApi = new OrganizationAPI();

const Index = () => {
  const [loading, setLoading] = useState(true);
  const [cabinetData, setCabinetData] = useState({});

  useEffect(() => {
    organizationApi
      .getOrganization()
      .then(({ data, status }) => {
        if (status > 202) {
          setLoading(true);
          return;
        }
        setLoading(false);
        setCabinetData(data);
      });
  }, []);

  return (
    <>
      <Helmet title="Личный кабинет" />
      <div className="lk-content lk-index">
        <h1 className="h2 lk-index__title">Личный кабинет партнёра</h1>
        <p className="lk-index__text">
          Через личный кабинет вы можете управлять своими заявками на аренду площадей,
          редактировать свои персональные данные и данные компании, просматривать заключённые
          договоры и получать информацию для оплаты.
        </p>
        <div className="lk-index__info">
          <div className="lk-index__info-item">
            <div className="lk-index__key">Компания</div>
            <div className="lk-index__val">{loading ? '...' : cabinetData.full_name}</div>
          </div>
          <div className="lk-index__info-item">
            <div className="lk-index__key">ИНН</div>
            <div className="lk-index__val">{loading ? '...' : cabinetData.INN}</div>
          </div>
          <div className="lk-index__info-item">
            <div className="lk-index__key">Статус</div>
            <div className="lk-index__val">
              <div className="lk-index__val-item">
                {loading ? '...' : cabinetData.partner_status}
                <Tooltip right>
                  Статус <b>«Зарегистрированный»</b> присваивается сразу после регистрации и
                  позволяет просматривать все свободные площади. При таком статусе заявка на
                  нужную площадь требует подтверждения менеджером.
                  <br />
                  <br /> Статус
                  <b> «Подтверждённый»</b> присваивается пользователю при первичном оформлении
                  заявки на аренду и позволяет направлять заявки без подтверждения от менеджера.
                  <br />
                  <br /> Статус
                  <b> «Авторизированный»</b> присваивается при заключении договора субаренды и
                  позволяет просматривать информацию по заключенным договорам.{' '}
                </Tooltip>
              </div>
              <div className="lk-index__val-item">{loading ? '...' : cabinetData.partner_type || ''}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
