import React from 'react';
import cx from 'classnames';

import { loginActions } from '../../../store';

import LK6 from '../../ui/svg/lk6';
import NavigationLink from './NavigationLink';

const Navigation = ({ dispatch }) => (
  <div className="lk-aside">
    <div className="lk-aside__nav">
      <NavigationLink
        to="/cabinet/"
        title="Личный кабинет партнера"
        icon="cabinet"
      />
      <NavigationLink
        to="/cabinet/requests/"
        title="Заявки"
        icon="requests"
      />
      <NavigationLink
        to="/cabinet/contracts/"
        title="Договоры"
        icon="contracts"
      />
      <NavigationLink
        to="/cabinet/data/"
        title="Редактирование данных"
        icon="data"
      />
      <NavigationLink
        to="/cabinet/settings/"
        title="Настройки уведомлений"
        icon="settings"
      />
      <button
        type="button"
        style={{ border: 'none', background: 'none', cursor: 'pointer' }}
        onClick={() => dispatch(loginActions.logout())}
        className={cx('lk-aside__item')}
      >
        <i>
          <LK6 />
        </i>
        <span>Выйти</span>
      </button>
    </div>
  </div>
);

export default Navigation;
