import React from 'react';
import Helmet from 'react-helmet';
import RequestOperationStatus from '../../../components/common/Request/Operation/Status';
import requestOperationTypes from '../../../components/common/Request/Operation/Types';

const RequestDecline = ({ match }) => {
  const { organizationId, uuid } = match.params;

  return (
    <>
      <Helmet title="Отклонение заявки" />
      <RequestOperationStatus
        header="Отклонение заявки"
        requestOperationType={requestOperationTypes.Decline}
        organizationId={organizationId}
        uuid={uuid}
      />
    </>
  );
};

export default RequestDecline;
