import React, { useState, forwardRef, useImperativeHandle } from 'react';
import cx from 'classnames';
import useCustomCaptcha from './useCustomCaptcha';
import './CustomCaptcha.scss';
import Input from '../Form/Input';

export default forwardRef(({ onSuccess }, ref) => {
  const [imageURL, verifyCaptcha] = useCustomCaptcha();
  const [answer, setAnswer] = useState('');
  const [isIncorrect, setIsIncorrect] = useState(false);
  const [token, setToken] = useState('');

  async function verify() {
    if (token.length) {
      onSuccess(token);
      return;
    }

    try {
      setIsIncorrect(false);

      const captchaToken = await verifyCaptcha(answer);

      setToken(captchaToken);
      onSuccess(captchaToken);
    } catch (error) {
      setIsIncorrect(true);

      setAnswer('');
    }
  }

  useImperativeHandle(ref, () => ({ verify }));

  function setAnswerFromInputEvent(event) {
    setAnswer(event.target.value);
  }

  return (
    <div>
      {imageURL && <img className="custom-captcha__image" src={imageURL} alt="captcha" />}

      <Input
        className={cx({ 'vf-error': isIncorrect })}
        type="text"
        placeholder="Введите текст с изображения"
        value={answer}
        disabled={token.length > 0}
        onChange={setAnswerFromInputEvent}
      />
    </div>
  );
});
