import React, { useCallback, useState } from 'react';
import RegionSelect from '../../../../ui/Select/Region/RegionSelect';

export default function FiltersRegionSelection({
  onSearchByRegion,
  onSearchByGeolocationName,
}) {
  const [region, setRegion] = useState(null);
  const [geolocationName, setGeolocationName] = useState('');

  const search = useCallback(async () => {
    if (region !== null) {
      onSearchByRegion(region);
      setRegion(null);
    } else if (geolocationName.length > 0) {
      onSearchByGeolocationName(geolocationName);
      setGeolocationName('');
    }
  }, [region, onSearchByRegion, geolocationName, onSearchByGeolocationName]);

  const enterKeyDownHandler = (handler) => (event) => {
    if (event.key === 'Enter') {
      handler();
    }
  };

  const handleSearchKeyDown = enterKeyDownHandler(search);

  return (
    <div className="find__addarea" id="addArea">
      <div className="find__addarea-row">
        <div className="find__addarea-title">Территория поиска</div>
        <div className="find__addarea-input">
          <RegionSelect
            selectedRegion={region}
            inputValue={geolocationName}
            onChange={setRegion}
            onChangeInputValue={(value) => {
              setRegion(null);
              setGeolocationName(value);
            }}
          />
        </div>
        <div className="find__addarea-buttons">
          <span
            className="i-button i-button--small i-button--135"
            role="button"
            tabIndex="0"
            onClick={search}
            onKeyDown={handleSearchKeyDown}
          >
            найти
          </span>
        </div>
      </div>
    </div>
  );
}
