import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';

import MyLocation from '../../MyLocation';
import PagesAPI from '../../services/pagesAPI';
import Acordion from '../../components/ui/Form/Acordion';
import Portal from '../../shared/utils/Portal';
import { Question } from '../../components/modals/Question';

const pagesApi = new PagesAPI();

const Terms = () => {
  const [questionOpened, setQuestionOpened] = useState(false);
  const [faqLoading, setFaqLoading] = useState(true);
  const [termsLoading, setTermsLoading] = useState(true);
  const [faq, setFaq] = useState([]);
  const [termsBlocks, setTermsBlocks] = useState([]);

  useEffect(() => {
    pagesApi
      .getFAQ()
      .then(res => {
        setFaq(res.data.results);
        setFaqLoading(false);
      })
      .catch(() => {
        setFaqLoading(true);
      });

    pagesApi
      .getTerms()
      .then(res => {
        setTermsBlocks(res.data.content.blocks);
        setTermsLoading(false);
      })
      .catch(() => {
        setTermsLoading(true);
      });
  }, []);

  const toggleModal = () => {
    setQuestionOpened(!questionOpened);
  };

  if (termsLoading) {
    return (
      <div className="w-content">
        <div className="inner terms">
          <div className="container">
            <h1 className="h1 h1--mb30">Условия сотрудничества</h1>
          </div>
          <div className="container">
            <p className="terms__content">Загружаю...</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet title="Условия сотрудничества" />
      <div className="w-content">
        <div className="inner terms">
          <div className="container">
            <h1 className="h1 h1--mb30">Условия сотрудничества</h1>
          </div>
          <div className="terms__hero">
            <div className="terms__cover" style={{ backgroundImage: 'url(img/media/terms.jpg)' }} />
            <div className="terms__text">
              <div className="terms__text-wrap">
                <h2>{termsBlocks[0].title}</h2>
                {termsBlocks[0].text.map((el, idx) => (
                  <p key={idx}>{el}</p>
                ))}
              </div>
            </div>
          </div>
          <div className="terms__content">
            <div className="container">
              <h2 className="h2">{termsBlocks[1].title}</h2>
              <div className="terms__steps">
                {termsBlocks[1].text.map((el, idx) => (
                  <div key={idx} className="terms__step">
                    <div className="terms__step-num">{idx + 1}</div>
                    <div className="terms__step-text">{el}</div>
                  </div>
                ))}
              </div>

              <hr className="hr" />
              <div className="container">
                {termsBlocks[2].text.map((el, idx) => (
                  <p key={idx} dangerouslySetInnerHTML={{ __html: el }} />
                ))}
              </div>
              <hr className="hr" />
              <div className="container">
                <h3 className="h3">{termsBlocks[3].title}</h3>
                <div className="terms__content-row">
                  {termsBlocks[3].text.map((el, idx) => {
                    if (idx === 0) {
                      return (
                        <div key={idx} className="terms__content-col">
                          <ul className="t-ul">
                            {el[0].map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                        </div>
                      );
                    }
                    return (
                      <div key={idx} className="terms__content-col">
                        {el}
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr className="hr" />
              <div className="container">
                <h3 className="h3">{termsBlocks[4].title}</h3>
                <div className="terms__content-row">
                  <div className="terms__content-col">
                    <p>{termsBlocks[4].text[0][0]}</p>
                    <ul className="t-ul">
                      {termsBlocks[4].text[0][1].map((el, idx) => (
                        <li key={idx} dangerouslySetInnerHTML={{ __html: el }} />
                      ))}
                    </ul>
                  </div>
                  <div
                    className="terms__content-col"
                    dangerouslySetInnerHTML={{ __html: termsBlocks[4].text[1][0] }}
                  />
                </div>
              </div>
            </div>
            <hr className="hr" />
            <div className="container">
              <h3 className="h3">{termsBlocks[5].title}</h3>
              <div className="terms__content-row">
                <div className="terms__content-col">
                  <p>{termsBlocks[5].text[0][0]}</p>
                  <ul className="t-ul">
                    <li>{termsBlocks[5].text[0][1][0]}</li>
                    <li>{termsBlocks[5].text[0][1][1]}</li>
                  </ul>
                </div>
                <div className="terms__content-col">
                  <ul className="t-ul">
                    <li>{termsBlocks[5].text[0][1][2]}</li>
                    <li>{termsBlocks[5].text[0][1][3]}</li>
                  </ul>
                </div>
              </div>
            </div>
            <hr className="hr" />
            <div className="container">
              <h3 className="h3">Часто задаваемые вопросы</h3>
              <div className="faq">
                {faqLoading && 'Загружаю...'}
                {!faqLoading
                    && faq.map(el => (
                      <Acordion key={el.id} title={el.question}>
                        <div dangerouslySetInnerHTML={{ __html: el.answer }} />
                      </Acordion>
                    ))}
              </div>
            </div>

            <div className="container">
              <span
                role="button"
                tabIndex="0"
                onKeyDown={toggleModal}
                onClick={toggleModal}
                className="i-button i-button--270"
              >
                Задать вопрос
              </span>
              {questionOpened && (
              <Portal>
                <Question close={toggleModal} />
              </Portal>
              )}
              <hr id="managers" className="hr" />
            </div>
          </div>
        </div>
      </div>
      <MyLocation />
    </>
  );
};

export default Terms;
