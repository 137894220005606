import React from 'react';
import cx from 'classnames';

const FormField = ({
  children, label, tip, className,
}) => (
  <div className="form-line">
    {label && <label className="form-label">{label}</label>}
    <div className={cx('form-field', className)}>{children}</div>
    {tip && <div className="form-tip">{tip}</div>}
  </div>
);

export default FormField;
