export default function getBreadCrumbs(path) {
  const defaultArray = [
    { name: 'Главная', link: '/' },
    { name: 'Партнерам', link: '/' },
    { name: 'Управление недвижимостью', link: '/' },
    { name: 'Субаренда', link: '/' },
  ];
  if (path.includes('/register')) defaultArray.push({ name: 'Регистрация', link: '/register/' });
  if (path.includes('/cabinet')) defaultArray.push({ name: 'Личный кабинет', link: '/cabinet/' });
  if (path.includes('/group')) defaultArray.push({ name: 'Группы площадей', link: '/find/group/' });
  if (path.includes('/find') && !path.includes('/group')) defaultArray.push({ name: 'Свободные площади', link: '/find/' });
  if (path.includes('/find') && path.replace(/\D+/g, '') !== '') defaultArray.push({ name: 'Просмотр площади', link: path });
  if (path.includes('/offer')) defaultArray.push({ name: 'Сезонные предложения', link: '/offer/' });
  if (path.includes('/terms')) defaultArray.push({ name: 'Условия сотрудничества', link: '/terms/' });
  return defaultArray;
}
