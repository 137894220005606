import {
  DEFAULT_BANK, DEFAULT_INDIVIDUAL_BENEFICIARY, DEFAULT_LEGAL_BENEFICIARY,
  DEFAULT_MAIN_INFORMATION,
  DEFAULT_MEMBER,
  DEFAULT_ORGANIZATION,
  DEFAULT_REGISTRATION,
} from '../../../../../shared/constants/Profile';

const getPassportAction = (action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_MEMBERS_PASSPORT_SERIES':
    case 'SET_INDIVIDUAL_BENEFICIARIES_PASSPORT_SERIES':
      return { type: 'SET_PASSPORT_SERIES', payload };
    case 'SET_MEMBERS_PASSPORT_NUMBER':
    case 'SET_INDIVIDUAL_BENEFICIARIES_PASSPORT_NUMBER':
      return { type: 'SET_PASSPORT_NUMBER', payload };
    case 'SET_MEMBERS_PASSPORT_ISSUER':
    case 'SET_INDIVIDUAL_BENEFICIARIES_PASSPORT_ISSUER':
      return { type: 'SET_PASSPORT_ISSUER', payload };
    case 'SET_MEMBERS_PASSPORT_ISSUE_DATE':
    case 'SET_INDIVIDUAL_BENEFICIARIES_PASSPORT_ISSUE_DATE':
      return { type: 'SET_PASSPORT_ISSUE_DATE', payload };
    case 'SET_MEMBERS_PASSPORT_REGISTRATION_ADDRESS':
    case 'SET_INDIVIDUAL_BENEFICIARIES_PASSPORT_REGISTRATION_ADDRESS':
      return { type: 'SET_PASSPORT_REGISTRATION_ADDRESS', payload };
    default:
      return action;
  }
};

const getOrganizationAction = ({ type, payload }) => {
  if (type === 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_TYPE') {
    return { type: 'SET_ORGANIZATION_TYPE', payload };
  }
  if (type === 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_OGRN') {
    return { type: 'SET_ORGANIZATION_OGRN', payload };
  }
  if (type === 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_INN') {
    return { type: 'SET_ORGANIZATION_INN', payload };
  }
  if (type === 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_KPP') {
    return { type: 'SET_ORGANIZATION_KPP', payload };
  }
  if (type === 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_OKPO') {
    return { type: 'SET_ORGANIZATION_OKPO', payload };
  }
  if (type === 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_OKVED') {
    return { type: 'SET_ORGANIZATION_OKVED', payload };
  }
  if (type === 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_OKONX') {
    return { type: 'SET_ORGANIZATION_OKONX', payload };
  }
  if (type === 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_LEGAL_ADDRESS') {
    return { type: 'SET_ORGANIZATION_LEGAL_ADDRESS', payload };
  }
  if (type === 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_PHYSICAL_ADDRESS') {
    return { type: 'SET_ORGANIZATION_PHYSICAL_ADDRESS', payload };
  }
  if (type === 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_AUTHORITY_NAME') {
    return { type: 'SET_ORGANIZATION_AUTHORITY_NAME', payload };
  }
  return null;
};

const getRegistrationAction = ({ type, payload }) => {
  if (type === 'SET_LEGAL_BENEFICIARIES_REGISTRATION_DATE') {
    return { type: 'SET_REGISTRATION_DATE', payload };
  }
  if (type === 'SET_LEGAL_BENEFICIARIES_REGISTRATION_AUTHORITY_NAME') {
    return { type: 'SET_REGISTRATION_AUTHORITY_NAME', payload };
  }
  return null;
};

export default (state, action) => {
  const { type, payload } = action;
  const { filter, value } = payload || {};

  const beneficiaryAction = { type, payload: value };

  switch (type) {
    case 'SET_INITIAL_STATE':
      return payload;
    case 'SET_ENGLISH_NAME':
    case 'SET_FULL_NAME':
    case 'SET_SHORT_NAME':
    case 'SET_PHONE':
    case 'SET_EMAIL':
    case 'SET_SIGN':
    case 'TOGGLE_HAS_CHIEF_ACCOUNT':
    case 'TOGGLE_HAS_TRUST_PERSON':
      return { ...state, ...mainReducer(state, action) };
    case 'SET_REGISTRATION_DATE':
    case 'SET_REGISTRATION_AUTHORITY_NAME':
      return { ...state, registration: registrationReducer(state.registration, action) };
    case 'SET_ORGANIZATION_TYPE':
    case 'SET_ORGANIZATION_OGRN':
    case 'SET_ORGANIZATION_INN':
    case 'SET_ORGANIZATION_KPP':
    case 'SET_ORGANIZATION_OKPO':
    case 'SET_ORGANIZATION_OKVED':
    case 'SET_ORGANIZATION_OKONX':
    case 'SET_ORGANIZATION_LEGAL_ADDRESS':
    case 'SET_ORGANIZATION_PHYSICAL_ADDRESS':
      return { ...state, organization: organizationReducer(state.organization, action) };
    case 'SET_MEMBERS_FIRST_NAME':
    case 'SET_MEMBERS_LAST_NAME':
    case 'SET_MEMBERS_MIDDLE_NAME':
    case 'SET_MEMBERS_BIRTH_DATE':
    case 'SET_MEMBERS_PASSPORT_SERIES':
    case 'SET_MEMBERS_PASSPORT_NUMBER':
    case 'SET_MEMBERS_PASSPORT_ISSUER':
    case 'SET_MEMBERS_PASSPORT_ISSUE_DATE':
    case 'SET_MEMBERS_PASSPORT_REGISTRATION_ADDRESS':
      return {
        ...state,
        members: state.members.map((member) => {
          if (member.type === +filter) return membersReducer(member, beneficiaryAction);
          return member;
        }),
      };
    case 'SET_BANK_COUNTRY':
    case 'SET_BANK_NAME':
    case 'SET_BANK_ADDRESS':
    case 'SET_BANK_BIK':
    case 'SET_BANK_CHECKING_ACCOUNT':
    case 'SET_BANK_CORRESPONDENT_ACCOUNT':
    case 'SET_BANK_SWIFT':
    case 'SET_BANK_IBAN':
    case 'SET_BANK_ABA':
      return { ...state, bank: bankReducer(state.bank, action) };
    case 'ADD_LEGAL_BENEFICIARY':
      return { ...state, legalBeneficiaries: [...state.legalBeneficiaries, payload] };
    case 'REMOVE_LEGAL_BENEFICIARY':
      return { ...state, legalBeneficiaries: state.legalBeneficiaries.filter(({ id }) => id === filter) };
    case 'SET_LEGAL_BENEFICIARIES_REGISTRATION':
    case 'SET_LEGAL_BENEFICIARIES_FULL_NAME':
    case 'SET_LEGAL_BENEFICIARIES_SHORT_NAME':
    case 'SET_LEGAL_BENEFICIARIES_PHONE':
    case 'SET_LEGAL_BENEFICIARIES_EMAIL':
    case 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_TYPE':
    case 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_OGRN':
    case 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_INN':
    case 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_KPP':
    case 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_OKPO':
    case 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_OKVED':
    case 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_OKONX':
    case 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_LEGAL_ADDRESS':
    case 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_PHYSICAL_ADDRESS':
    case 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_AUTHORITY_NAME':
    case 'SET_LEGAL_BENEFICIARIES_REGISTRATION_DATE':
    case 'SET_LEGAL_BENEFICIARIES_REGISTRATION_AUTHORITY_NAME':
      return {
        ...state,
        legalBeneficiaries: state.legalBeneficiaries.map(beneficiary => {
          if (beneficiary.id === filter) return legalBeneficiariesReducer(beneficiary, beneficiaryAction);
          return beneficiary;
        }),
      };
    case 'ADD_INDIVIDUAL_BENEFICIARY':
      return { ...state, individualBeneficiaries: [...state.individualBeneficiaries, payload] };
    case 'REMOVE_INDIVIDUAL_BENEFICIARY':
      return { ...state, individualBeneficiaries: state.individualBeneficiaries.filter(({ id }) => id === filter) };
    case 'SET_INDIVIDUAL_BENEFICIARIES_FIRST_NAME':
    case 'SET_INDIVIDUAL_BENEFICIARIES_MIDDLE_NAME':
    case 'SET_INDIVIDUAL_BENEFICIARIES_LAST_NAME':
    case 'SET_INDIVIDUAL_BENEFICIARIES_BIRTH_DATE':
    case 'SET_INDIVIDUAL_BENEFICIARIES_PASSPORT_SERIES':
    case 'SET_INDIVIDUAL_BENEFICIARIES_PASSPORT_NUMBER':
    case 'SET_INDIVIDUAL_BENEFICIARIES_PASSPORT_ISSUER':
    case 'SET_INDIVIDUAL_BENEFICIARIES_PASSPORT_ISSUE_DATE':
    case 'SET_INDIVIDUAL_BENEFICIARIES_PASSPORT_REGISTRATION_ADDRESS':
      return {
        ...state,
        individualBeneficiaries: state.individualBeneficiaries.map(beneficiary => {
          if (beneficiary.id === filter) return individualBeneficiariesReducer(beneficiary, { type, payload: value });
          return beneficiary;
        }),
      };
    default:
      throw new Error(`No current type on reducer:  ${type}`);
  }
};

const mainReducer = (state = DEFAULT_MAIN_INFORMATION, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_ENGLISH_NAME':
      return { englishName: payload };
    case 'SET_FULL_NAME':
      return { fullName: payload };
    case 'SET_SHORT_NAME':
      return { shortName: payload };
    case 'SET_PHONE':
      return { phone: payload };
    case 'SET_EMAIL':
      return { email: payload };
    case 'SET_SIGN':
      return { companySign: payload };
    case 'TOGGLE_HAS_CHIEF_ACCOUNT':
      return { hasChiefAccountant: payload };
    case 'TOGGLE_HAS_TRUST_PERSON':
      return { hasTrustPerson: payload };
    default:
      throw new Error(`No current type on reducer:  ${type}`);
  }
};

function registrationReducer(state = DEFAULT_REGISTRATION, action) {
  const { type, payload } = action;

  switch (type) {
    case 'SET_REGISTRATION_DATE':
      return { ...state, date: payload };
    case 'SET_REGISTRATION_AUTHORITY_NAME':
      return { ...state, authorityName: payload };
    default:
      throw new Error(`No current type on reducer: ${type}`);
  }
}

function organizationReducer(state = DEFAULT_ORGANIZATION, action) {
  const { type, payload } = action;
  switch (type) {
    case 'SET_ORGANIZATION_TYPE':
      return { ...state, type: payload };
    case 'SET_ORGANIZATION_OGRN':
      return { ...state, OGRN: payload };
    case 'SET_ORGANIZATION_INN':
      return { ...state, INN: payload };
    case 'SET_ORGANIZATION_KPP':
      return { ...state, KPP: payload };
    case 'SET_ORGANIZATION_OKPO':
      return { ...state, OKPO: payload };
    case 'SET_ORGANIZATION_OKVED':
      return { ...state, OKVED: payload };
    case 'SET_ORGANIZATION_OKONX':
      return { ...state, OKONX: payload };
    case 'SET_ORGANIZATION_LEGAL_ADDRESS':
      return { ...state, legalAddress: payload };
    case 'SET_ORGANIZATION_PHYSICAL_ADDRESS':
      return { ...state, physicalAddress: payload };
    default:
      throw new Error(`No current type on reducer: ${type}`);
  }
}

function membersReducer(state = DEFAULT_MEMBER, action) {
  const { type, payload } = action;
  switch (type) {
    case 'SET_MEMBERS_FIRST_NAME':
      return { ...state, firstName: payload };
    case 'SET_MEMBERS_LAST_NAME':
      return { ...state, lastName: payload };
    case 'SET_MEMBERS_MIDDLE_NAME':
      return { ...state, middleName: payload };
    case 'SET_MEMBERS_BIRTH_DATE':
      return { ...state, birthDate: payload };
    case 'SET_MEMBERS_PASSPORT_SERIES':
    case 'SET_MEMBERS_PASSPORT_NUMBER':
    case 'SET_MEMBERS_PASSPORT_ISSUER':
    case 'SET_MEMBERS_PASSPORT_ISSUE_DATE':
    case 'SET_MEMBERS_PASSPORT_REGISTRATION_ADDRESS':
      return { ...state, passport: passportReducer(state.passport, getPassportAction(action)) };
    default:
      throw new Error(`No current type on reducer: ${type}`);
  }
}

function passportReducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case 'SET_PASSPORT_SERIES':
      return { ...state, series: payload };
    case 'SET_PASSPORT_NUMBER':
      return { ...state, number: payload };
    case 'SET_PASSPORT_ISSUER':
      return { ...state, issuer: payload };
    case 'SET_PASSPORT_ISSUE_DATE':
      return { ...state, issueDate: payload };
    case 'SET_PASSPORT_REGISTRATION_ADDRESS':
      return { ...state, registrationAddress: payload };
    default:
      throw new Error(`No current type on reducer: ${type}`);
  }
}

function bankReducer(state = DEFAULT_BANK, action) {
  const { type, payload } = action;
  switch (type) {
    case 'SET_BANK_COUNTRY':
      return { ...state, country: payload };
    case 'SET_BANK_NAME':
      return { ...state, name: payload };
    case 'SET_BANK_ADDRESS':
      return { ...state, address: payload };
    case 'SET_BANK_BIK':
      return { ...state, BIK: payload };
    case 'SET_BANK_CHECKING_ACCOUNT':
      return { ...state, checkingAccount: payload };
    case 'SET_BANK_CORRESPONDENT_ACCOUNT':
      return { ...state, correspondentAccount: payload };
    case 'SET_BANK_SWIFT':
      return { ...state, SWIFT: payload };
    case 'SET_BANK_IBAN':
      return { ...state, IBAN: payload };
    case 'SET_BANK_ABA':
      return { ...state, ABA: payload };
    default:
      throw new Error(`No current type on reducer: ${type}`);
  }
}

function legalBeneficiariesReducer(state = DEFAULT_LEGAL_BENEFICIARY, action) {
  const { type, payload } = action;
  switch (type) {
    case 'SET_LEGAL_BENEFICIARIES_FULL_NAME':
      return { ...state, fullName: payload };
    case 'SET_LEGAL_BENEFICIARIES_SHORT_NAME':
      return { ...state, shortName: payload };
    case 'SET_LEGAL_BENEFICIARIES_PHONE':
      return { ...state, phone: payload };
    case 'SET_LEGAL_BENEFICIARIES_EMAIL':
      return { ...state, email: payload };
    case 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_TYPE':
    case 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_OGRN':
    case 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_INN':
    case 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_KPP':
    case 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_OKPO':
    case 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_OKVED':
    case 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_OKONX':
    case 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_LEGAL_ADDRESS':
    case 'SET_LEGAL_BENEFICIARIES_ORGANIZATION_PHYSICAL_ADDRESS':
      return { ...state, organization: organizationReducer(state.organization, getOrganizationAction(action)) };
    case 'SET_LEGAL_BENEFICIARIES_REGISTRATION_DATE':
    case 'SET_LEGAL_BENEFICIARIES_REGISTRATION_AUTHORITY_NAME':
      return { ...state, registration: registrationReducer(state.registration, getRegistrationAction(action)) };
    default:
      throw new Error(`No current type on reducer: ${type}`);
  }
}

function individualBeneficiariesReducer(state = DEFAULT_INDIVIDUAL_BENEFICIARY, action) {
  const { type, payload } = action;
  switch (type) {
    case 'SET_INDIVIDUAL_BENEFICIARIES_FIRST_NAME':
      return { ...state, firstName: payload };
    case 'SET_INDIVIDUAL_BENEFICIARIES_MIDDLE_NAME':
      return { ...state, middleName: payload };
    case 'SET_INDIVIDUAL_BENEFICIARIES_LAST_NAME':
      return { ...state, lastName: payload };
    case 'SET_INDIVIDUAL_BENEFICIARIES_BIRTH_DATE':
      return { ...state, birthDate: payload };
    case 'SET_INDIVIDUAL_BENEFICIARIES_PASSPORT_SERIES':
    case 'SET_INDIVIDUAL_BENEFICIARIES_PASSPORT_NUMBER':
    case 'SET_INDIVIDUAL_BENEFICIARIES_PASSPORT_ISSUER':
    case 'SET_INDIVIDUAL_BENEFICIARIES_PASSPORT_ISSUE_DATE':
    case 'SET_INDIVIDUAL_BENEFICIARIES_PASSPORT_REGISTRATION_ADDRESS':
      return { ...state, passport: passportReducer(state.passport, getPassportAction(action)) };
    default:
      throw new Error(`No current type on reducer: ${type}`);
  }
}
