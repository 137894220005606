import axios from 'axios';
import queryString from 'query-string';

export default class SpacesAPI {
  _apiBase = `${process.env.REACT_APP_API_BASE}/spaces`;

  async getSpacesByFilters(filters) {
    const response = await axios.get(this._apiBase, {
      params: filters,
      paramsSerializer: params => queryString.stringify(params, {
        arrayFormat: 'comma',
      }),
    });

    return response.data;
  }

  async getGroupsByFilters(filters) {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE}/groups`, {
      params: filters,
      paramsSerializer: params => queryString.stringify(params, {
        arrayFormat: 'comma',
      }),
    });

    return response.data;
  }

  getSpaces = async data => {
    try {
      const res = await axios.get(
        `${this._apiBase}/?page=${data.page}&shop=${data.shop}&page_size=${
          data.pageSize
        }&base_rate_min=${data.baseRateMin}&base_rate_max=${data.baseRateMax}&area_min=${
          data.areaMin
        }&area_max=${data.areaMax}&category=${data.category}&shop_type=${
          data.shopType
        }&occupancy_status=${data.occupancyStatus}&location=${data.location}&ordering=${
          data.ordering
        }`,
      );
      return res;
    } catch (e) {
      return e;
    }
  };

  async getAddresses(name) {
    try {
      const response = await axios.get(`${this._apiBase}/address/search`, {
        params: { name },
      });

      return response.data;
    } catch {
      return [];
    }
  }

  getSpace = async id => {
    try {
      const res = await axios.get(`${this._apiBase}/${id}/`);
      return res;
    } catch (e) {
      return e;
    }
  };

  getSuggestions = async id => {
    try {
      const res = await axios.get(`${this._apiBase}/${id}/suggestions`);
      return res;
    } catch (e) {
      return e;
    }
  };

  getGroups = async data => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE}/groups?page=${data.page}&page_size=${
          data.pageSize
        }&base_rate_min=${data.baseRateMin}&base_rate_max=${data.baseRateMax}&area_min=${
          data.areaMin
        }&area_max=${data.areaMax}&amount_max=${data.amountMax}&amount_min=${
          data.amountMin
        }&ordering=${data.ordering}`,
      );
      return res;
    } catch (e) {
      return e;
    }
  };

  getSpacesMap = async data => {
    try {
      const res = await axios.get(
        `${this._apiBase}/map/?in_bbox=${data.inBbox}&base_rate_min=${
          data.baseRateMin
        }&base_rate_max=${data.baseRateMax}&area_min=${data.areaMin}&area_max=${
          data.areaMax
        }&category=${data.category}&shop_type=${data.shopType}&occupancy_status=${
          data.occupancyStatus
        }&location=${data.location}`,
      );
      return res;
    } catch (e) {
      return e;
    }
  };

  getCategories = async () => {
    try {
      const res = await axios.get(`${this._apiBase}/categories/`, {});
      return res;
    } catch (e) {
      return e;
    }
  };

  getFilters = async () => {
    try {
      const res = await axios.get(`${this._apiBase}/filters/`, {});
      return res;
    } catch (e) {
      return e;
    }
  };

  spaceRequest = async (id, data) => {
    // eslint-disable-next-line no-useless-escape
    const Token = document.cookie.replace(/(?:(?:^|.*;\s*)Token\s*\=\s*([^;]*).*$)|^.*$/, '$1');
    const { captchaToken, ...newData } = data;

    newData.captcha_token = captchaToken;

    for (const key in newData) {
      if (newData[key] === '') newData[key] = null;
    }
    try {
      const res = await axios.post(
        `${this._apiBase}/${id}/send_request/`,
        {
          ...newData,
        },
        {
          headers: {
            Authorization: `Token ${Token}`,
          },
        },
      );
      return res;
    } catch (e) {
      return e;
    }
  };

  groupRequest = async (id, data) => {
    // eslint-disable-next-line no-useless-escape
    const Token = document.cookie.replace(/(?:(?:^|.*;\s*)Token\s*\=\s*([^;]*).*$)|^.*$/, '$1');
    const { captchaToken, ...newData } = data;

    newData.captcha_token = captchaToken;

    for (const key in newData) {
      if (newData[key] === '') newData[key] = null;
    }
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE}/groups/${id}/send_request/`,
        {
          ...newData,
        },
        {
          headers: {
            Authorization: `Token ${Token}`,
          },
        },
      );
      return res;
    } catch (e) {
      return e;
    }
  };
}
