import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import ContractCard from '../../../components/common/Cabinet/ContractCard';

import DocumentsAPI from '../../../services/documentsAPI';

const documentsAPI = new DocumentsAPI();

const Contracts = () => {
  const [contractsData, setContractsData] = useState([]);

  useEffect(() => {
    documentsAPI
      .getContracts()
      .then(({ data }) => {
        setContractsData(data);
      });
  }, []);

  return (
    <>
      <Helmet title="Договоры" />
      <div className="lk-content lk-contracts">
        <h1 className="h2 lk-contracts__title">Договоры</h1>
        {!contractsData.length && <p>Договоров не найдено.</p>}
        {contractsData.length > 0 && (
        <div className="table">
          <div className="table__row table__row--head">
            <div className="table__th">ID договора</div>
            <div className="table__th">№ договора</div>
            <div className="table__th">Вид деятельности</div>
            <div className="table__th">Сумма к оплате</div>
            <div className="table__th" />
          </div>
          {contractsData.map(el => (
            <ContractCard
              key={el.id}
              id={el.id}
              askoId={el.asko_id}
              name={el.name}
              legacyId={el.legacy_id}
              startDate={el.start_date}
              expirationDate={el.expiration_date}
              dayOfMonth={el.day_of_month}
              rate={el.rate_per_month}
              serviceClass={el.service_class}
              paymentDates={el.payment_dates}
            />
          ))}
        </div>
        )}
      </div>
    </>
  );
};

export default Contracts;
