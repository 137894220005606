/* eslint-disable jsx-a11y/anchor-has-content */

import React from 'react';
import { format } from 'date-fns';
import { withRouter } from 'react-router-dom';
import { privacyPolicyLink } from '../../shared/constants';

const Footer = ({ location }) => {
  if (!location.pathname.includes('barcode')) {
    return (
      <footer className="footer">
        <div className="footer__bottom">
          <div className="container">
            <div className="footer__bottom-wrap">
              <div className="footer__bottom-left">
                <div className="footer__bottom-logo">
                  <a href="https://5ka.ru/" className="footer__logo" />
                  <a
                    className="footer__policy"
                    href={privacyPolicyLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Политика в отношении обработки
                    <br /> персональных данных
                  </a>
                </div>
                <div className="footer__app">
                  <a
                    href="https://itunes.apple.com/ru/app/%D0%BF%D1%8F%D1%82%D1%91%D1%80%D0%BE%D1%87%D0%BA%D0%B0/id1174271758?mt=8#"
                    className="footer__app-ios"
                  />
                  <a
                    href="https://play.google.com/store/apps/details?id=pyaterochka.app&hl=ru"
                    className="footer__app-android"
                  />
                </div>
              </div>
              <div className="footer__bottom-right">
                <a href="tel:8-800-555-55-05" className="footer__phone">
                  <div className="footer__phone-num">8 800 555-55-05</div>
                  <div className="footer__phone-title">Горячая линия</div>
                </a>
                <div className="footer__social">
                  <a
                    href="https://ok.ru/pyaterochka"
                    className="footer__social-ico footer__social-ico--ok"
                  />
                  <a
                    href="http://vk.com/club19098821"
                    className="footer__social-ico footer__social-ico--vk"
                  />
                </div>
              </div>
            </div>
            <div className="footer__copyright">
              © 2012–{format(new Date(), 'YYYY')} ООО «Агроторг». Любое использование контента без
              письменного разрешения ООО «Агроторг» запрещено.
            </div>
          </div>
        </div>
      </footer>
    );
  }
  return null;
};

export default withRouter(Footer);
