import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import requestOperationTypes from '../../components/common/Request/Operation/Types';
import DocumentsAPI from '../../services/documentsAPI';

export default function useRequestOperationStatus(requestOperationType, organizationId, uuid) {
  const documentsApi = useMemo(() => new DocumentsAPI(), []);
  const [message, setMessage] = useState(null);

  const getMessagePromise = useCallback(() => {
    if (requestOperationType === requestOperationTypes.Confirm) {
      return documentsApi.confirmRequest(organizationId, uuid);
    }

    if (requestOperationType === requestOperationTypes.Decline) {
      return documentsApi.declineRequest(organizationId, uuid);
    }

    return new Promise((resolve, reject) => {
      reject(new Error('Неверный тип операции'));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getMessagePromise()
      .then(setMessage)
      .catch(() => setMessage('Произошла ошибка'));

    return () => {
      setMessage(null);
    };
  }, [getMessagePromise]);

  return message;
}
