import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Helmet from 'react-helmet';
import Portal from '../../shared/utils/Portal';
import Login from '../../components/modals/Login/Login';

const Home = () => {
  const formatDate = (date) => date.toLocaleDateString('ru-RU');

  const [loginOpened, setLoginOpened] = useState(false);
  const currentDate = formatDate(new Date());

  const openLogin = () => {
    setLoginOpened(true);
  };

  const closeLogin = () => {
    setLoginOpened(false);
  };

  return (
    <div>
      <Helmet title="Субаренда. Главная" />
      <div className="w-content">
        <div className="hero">
          <div className="hero__cover" style={{ backgroundImage: 'url(img/media/hero.jpg)' }} />
          <div className="hero__text">
            <div className="hero__text-wrap">
              <h1>Аренда площадей в магазинах «Пятёрочка»</h1>
              <p>
                Возможность малому и среднему бизнесу получить новый канал коммуникации с
                потребителями
              </p>
            </div>
          </div>
        </div>

        <div className="features">
          <div className="container">
            <div className="features__row">
              <div className="features__item">
                <div className="features__icon">
                  <img src={`${process.env.PUBLIC_URL}img/index-ico1.svg`} alt="" />
                </div>
                <h3 className="features__title">
                  Поиск свободных <br /> площадей.
                </h3>
                <p className="features__text">
                  Воспользуйтесь удобным поиском свободных площадей. Помещения могут быть сданы по
                  одному или сразу группой.
                </p>
                <Link to="/find" className="features__link">
                  Посмотреть предложения
                </Link>
              </div>
              <div className="features__item">
                <div className="features__icon">
                  <img src={`${process.env.PUBLIC_URL}img/index-ico2.svg`} alt="" />
                </div>
                <h3 className="features__title">
                  Сезонные предложения <br />
                  по аренде.
                </h3>
                <p className="features__text">
                  Праздники или другие важные события (например, Новый год, Пасха и т.д.)
                  сопровождаются повышенным спросом на тематические товары и услуги.
                </p>
                <Link to="/offer" className="features__link">
                  Подробнее
                </Link>
              </div>
              <div className="features__item">
                <div className="features__icon">
                  <img src={`${process.env.PUBLIC_URL}img/index-ico3.svg`} alt="" />
                </div>
                <h3 className="features__title">
                  Условия <br />
                  сотрудничества.
                </h3>
                <p className="features__text">
                  Предлагаем ознакомиться с условиями сотрудничества и порядком
                  заключения&nbsp;договоров аренды.
                </p>
                <Link to="/terms" className="features__link">
                  Подробнее
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="develop">
          <div className="develop__inner">
            <div className="container">
              <div className="develop__pic-wrap">
                <div className="develop__left">
                  <h2>Развивайте бизнес вместе с нами</h2>
                  <p>
                    Если у&nbsp;вас есть вопросы, свяжитесь с&nbsp;вашим региональным менеджером
                    по&nbsp;аренде. Контактную информацию смотрите на&nbsp;странице &laquo;Условия
                    сотрудничества&raquo;.
                  </p>
                  <div className="develop__btn">
                    <Link to="/register" className="i-button">
                      Регистрация для новых партнёров
                    </Link>
                  </div>
                  <div className="develop__singin">
                    <span
                      className="features__link"
                      tabIndex={-1}
                      onKeyDown={openLogin}
                      role="button"
                      onClick={openLogin}
                    >Войти в кабинет
                    </span>, если у вас уже есть доступ к
                    нему
                    {loginOpened && (
                    <Portal>
                      <Login close={closeLogin} />
                    </Portal>
                    )}
                  </div>
                </div>
                <div className="develop__pic">
                  <img src={`${process.env.PUBLIC_URL}img/media/develop.jpg`} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="develop__group">
            <div className="container">
              <div className="develop__blocks">
                <div className="develop__blocks-white" />
                <div className="develop__blocks-time">Данные актуальны на {currentDate}</div>
                <div className="develop__blocks-wrap">
                  <div className="develop__item">
                    <div className="develop__item-photo">
                      <img src={`${process.env.PUBLIC_URL}img/media/g1.jpg`} alt="" />
                    </div>
                    <div className="develop__item-text">
                      <h3>1 миллион</h3>
                      <p>посетителей в сутки во всех магазинах «Пятёрочка»</p>
                    </div>
                  </div>
                  <div className="develop__item">
                    <div className="develop__item-photo">
                      <img src={`${process.env.PUBLIC_URL}img/media/g2.jpg`} alt="" />
                    </div>
                    <div className="develop__item-text">
                      <h3>Более 21 тысячи</h3>
                      <p>магазинов по всей России</p>
                    </div>
                  </div>
                  <div className="develop__item">
                    <div className="develop__item-photo">
                      <img src={`${process.env.PUBLIC_URL}img/media/g3.jpg`} alt="" />
                    </div>
                    <div className="develop__item-text">
                      <h3>Один из лидеров</h3>
                      <p>на рынке продуктовых розничных компаний</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
