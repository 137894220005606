import React from 'react';
import Select from 'react-select';

export default ({
  items, onChange, placeholder, error, value,
}) => {
  const displayedItems = items.map(el => ({
    value: el.id,
    label: el.alias,
  }));

  return (
    <Select
      noOptionsMessage={() => ('Не найдено результатов')}
      styles={error ? customStylesError : customStyles}
      placeholder={placeholder}
      isClearable={false}
      isSearchable
      options={displayedItems}
      onChange={(opt) => {
        onChange(opt.label);
      }}
      value={value}
    />
  );
};

const customStyles = {
  option: (provided, state) => ({
    color: state.isSelected || state.isFocused ? '#E61801' : '#000',
    padding: '8px 15px',
    background: !state.isFocused ? 'white' : '#f9f9f9',
    cursor: 'pointer',
    borderBottom: '1px solid #f5f5f5',
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: '2px',
    color: '#000',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorContainer: () => ({
    svg: {
      fill: '#000',
    },
  }),
};

const customStylesError = {
  option: (provided, state) => ({
    color: state.isSelected || state.isFocused ? '#E61801' : '#000',
    padding: '8px 15px',
    background: !state.isFocused ? 'white' : '#f9f9f9',
    cursor: 'pointer',
    borderBottom: '1px solid #f5f5f5',
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: '2px',
    color: '#000',
    borderColor: !state.menuIsOpen ? '#E61801' : 'transparent',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorContainer: () => ({
    svg: {
      fill: '#000',
    },
  }),
};
