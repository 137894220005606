import React, { useState } from 'react';
import cx from 'classnames';

import select from '../svg/select.svg';

const Acordion = ({
  title,
  children,
}) => {
  const [opened, setOpened] = useState(false);

  const toggle = () => setOpened(!opened);

  return (
    <div className="faq__item">
      <div
        tabIndex="0"
        role="button"
        onKeyDown={toggle}
        onClick={toggle}
        className={cx({ 'faq__head js-faqHead': true, 'is-open': opened })}
      >
        <i>
          <img src={select} alt="" />
        </i>
        {title}
      </div>
      {opened && (
        <div className="faq__body">
          <div className="faq__body-content">{children}</div>
        </div>
      )}
    </div>
  );
};

export default Acordion;
