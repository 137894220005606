const getDate = date => {
  // @date — строка вида 'MMMM YYYY'
  if (!date) return;
  const year = date.slice(-4);
  let month = date.slice(0, -4);
  switch (month) {
    default: {
      month = '01';
      break;
    }
    case 'Январь ': {
      month = '01';
      break;
    }
    case 'Февраль ': {
      month = '02';
      break;
    }
    case 'Март ': {
      month = '03';
      break;
    }
    case 'Апрель ': {
      month = '04';
      break;
    }
    case 'Май ': {
      month = '05';
      break;
    }
    case 'Июнь ': {
      month = '06';
      break;
    }
    case 'Июль ': {
      month = '07';
      break;
    }
    case 'Август ': {
      month = '08';
      break;
    }
    case 'Сентябрь ': {
      month = '09';
      break;
    }
    case 'Октябрь ': {
      month = '10';
      break;
    }
    case 'Ноябрь ': {
      month = '11';
      break;
    }
    case 'Декабрь ': {
      month = '12';
      break;
    }
  }
  return `01.${month}.${year}`;
};

export default getDate;
