import React from 'react';

const Svg = () => (
  <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.125 0V1.80952H7.875V0H6.125ZM7.875 1.80952V3.61905H6.125V1.80952H1.75C0.784875 1.80952 0 2.6211 0 3.61905V17.1905C0 18.1884 0.784875 19 1.75 19H12.25C13.2151 19 14 18.1884 14 17.1905V3.61905C14 2.6211 13.2151 1.80952 12.25 1.80952H7.875ZM6.125 6.33333H7.875V11.7619H6.125V6.33333ZM6.125 13.5714H7.875V15.381H6.125V13.5714Z"
      fill="#E0E0E0"
    />
  </svg>
);

export default Svg;
